// source: satys/usertaskmanager/usertaskmanager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var satys_domain_domain_pb = require('../../satys/domain/domain_pb.js');
goog.object.extend(proto, satys_domain_domain_pb);
goog.exportSymbol('proto.satys.usertaskmanager.CancelProcessInstanceRequest', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.CancelProcessInstanceResponse', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.ClaimTaskInstanceRequest', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.ClaimTaskInstanceResponse', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.CompleteTaskInstanceRequest', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.CompleteTaskInstanceResponse', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.GetTaskInstancesRequest', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.GetTaskInstancesResponse', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.StartEventRequest', null, global);
goog.exportSymbol('proto.satys.usertaskmanager.StartEventResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.GetTaskInstancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.GetTaskInstancesRequest.displayName = 'proto.satys.usertaskmanager.GetTaskInstancesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.usertaskmanager.GetTaskInstancesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.usertaskmanager.GetTaskInstancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.GetTaskInstancesResponse.displayName = 'proto.satys.usertaskmanager.GetTaskInstancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.ClaimTaskInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.ClaimTaskInstanceRequest.displayName = 'proto.satys.usertaskmanager.ClaimTaskInstanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.ClaimTaskInstanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.ClaimTaskInstanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.ClaimTaskInstanceResponse.displayName = 'proto.satys.usertaskmanager.ClaimTaskInstanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.CompleteTaskInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.CompleteTaskInstanceRequest.displayName = 'proto.satys.usertaskmanager.CompleteTaskInstanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.CompleteTaskInstanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.CompleteTaskInstanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.CompleteTaskInstanceResponse.displayName = 'proto.satys.usertaskmanager.CompleteTaskInstanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.StartEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.StartEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.StartEventRequest.displayName = 'proto.satys.usertaskmanager.StartEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.StartEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.StartEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.StartEventResponse.displayName = 'proto.satys.usertaskmanager.StartEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.CancelProcessInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.CancelProcessInstanceRequest.displayName = 'proto.satys.usertaskmanager.CancelProcessInstanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.usertaskmanager.CancelProcessInstanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.usertaskmanager.CancelProcessInstanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.usertaskmanager.CancelProcessInstanceResponse.displayName = 'proto.satys.usertaskmanager.CancelProcessInstanceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.GetTaskInstancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesRequest}
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.GetTaskInstancesRequest;
  return proto.satys.usertaskmanager.GetTaskInstancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesRequest}
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.GetTaskInstancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesRequest} returns this
*/
proto.satys.usertaskmanager.GetTaskInstancesRequest.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesRequest} returns this
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.usertaskmanager.GetTaskInstancesRequest.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.GetTaskInstancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskInstancesList: jspb.Message.toObjectList(msg.getTaskInstancesList(),
    satys_domain_domain_pb.TaskInstance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesResponse}
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.GetTaskInstancesResponse;
  return proto.satys.usertaskmanager.GetTaskInstancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesResponse}
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.TaskInstance;
      reader.readMessage(value,satys_domain_domain_pb.TaskInstance.deserializeBinaryFromReader);
      msg.addTaskInstances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.GetTaskInstancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.TaskInstance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.TaskInstance task_instances = 1;
 * @return {!Array<!proto.satys.domain.TaskInstance>}
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.prototype.getTaskInstancesList = function() {
  return /** @type{!Array<!proto.satys.domain.TaskInstance>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.TaskInstance, 1));
};


/**
 * @param {!Array<!proto.satys.domain.TaskInstance>} value
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesResponse} returns this
*/
proto.satys.usertaskmanager.GetTaskInstancesResponse.prototype.setTaskInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.TaskInstance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.TaskInstance}
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.prototype.addTaskInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.TaskInstance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.usertaskmanager.GetTaskInstancesResponse} returns this
 */
proto.satys.usertaskmanager.GetTaskInstancesResponse.prototype.clearTaskInstancesList = function() {
  return this.setTaskInstancesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.ClaimTaskInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskInstance: (f = msg.getTaskInstance()) && satys_domain_domain_pb.TaskInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.ClaimTaskInstanceRequest;
  return proto.satys.usertaskmanager.ClaimTaskInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.TaskInstance;
      reader.readMessage(value,satys_domain_domain_pb.TaskInstance.deserializeBinaryFromReader);
      msg.setTaskInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.ClaimTaskInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.TaskInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.TaskInstance task_instance = 1;
 * @return {?proto.satys.domain.TaskInstance}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.prototype.getTaskInstance = function() {
  return /** @type{?proto.satys.domain.TaskInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.TaskInstance, 1));
};


/**
 * @param {?proto.satys.domain.TaskInstance|undefined} value
 * @return {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} returns this
*/
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.prototype.setTaskInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} returns this
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.prototype.clearTaskInstance = function() {
  return this.setTaskInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceRequest.prototype.hasTaskInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.ClaimTaskInstanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.ClaimTaskInstanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.ClaimTaskInstanceResponse}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.ClaimTaskInstanceResponse;
  return proto.satys.usertaskmanager.ClaimTaskInstanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.ClaimTaskInstanceResponse}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.ClaimTaskInstanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.ClaimTaskInstanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.ClaimTaskInstanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.CompleteTaskInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskInstance: (f = msg.getTaskInstance()) && satys_domain_domain_pb.TaskInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.CompleteTaskInstanceRequest;
  return proto.satys.usertaskmanager.CompleteTaskInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.TaskInstance;
      reader.readMessage(value,satys_domain_domain_pb.TaskInstance.deserializeBinaryFromReader);
      msg.setTaskInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.CompleteTaskInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.TaskInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.TaskInstance task_instance = 1;
 * @return {?proto.satys.domain.TaskInstance}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.prototype.getTaskInstance = function() {
  return /** @type{?proto.satys.domain.TaskInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.TaskInstance, 1));
};


/**
 * @param {?proto.satys.domain.TaskInstance|undefined} value
 * @return {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} returns this
*/
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.prototype.setTaskInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} returns this
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.prototype.clearTaskInstance = function() {
  return this.setTaskInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceRequest.prototype.hasTaskInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.CompleteTaskInstanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CompleteTaskInstanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.CompleteTaskInstanceResponse}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.CompleteTaskInstanceResponse;
  return proto.satys.usertaskmanager.CompleteTaskInstanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.CompleteTaskInstanceResponse}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.CompleteTaskInstanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.CompleteTaskInstanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CompleteTaskInstanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.StartEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.StartEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.StartEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.StartEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f),
    variables: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.StartEventRequest}
 */
proto.satys.usertaskmanager.StartEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.StartEventRequest;
  return proto.satys.usertaskmanager.StartEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.StartEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.StartEventRequest}
 */
proto.satys.usertaskmanager.StartEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.StartEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.StartEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.StartEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.StartEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
  f = message.getVariables();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.usertaskmanager.StartEventRequest.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.usertaskmanager.StartEventRequest} returns this
*/
proto.satys.usertaskmanager.StartEventRequest.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.usertaskmanager.StartEventRequest} returns this
 */
proto.satys.usertaskmanager.StartEventRequest.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.usertaskmanager.StartEventRequest.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string variables = 2;
 * @return {string}
 */
proto.satys.usertaskmanager.StartEventRequest.prototype.getVariables = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.usertaskmanager.StartEventRequest} returns this
 */
proto.satys.usertaskmanager.StartEventRequest.prototype.setVariables = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.StartEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.StartEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.StartEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.StartEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processInstance: (f = msg.getProcessInstance()) && satys_domain_domain_pb.ProcessInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.StartEventResponse}
 */
proto.satys.usertaskmanager.StartEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.StartEventResponse;
  return proto.satys.usertaskmanager.StartEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.StartEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.StartEventResponse}
 */
proto.satys.usertaskmanager.StartEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ProcessInstance;
      reader.readMessage(value,satys_domain_domain_pb.ProcessInstance.deserializeBinaryFromReader);
      msg.setProcessInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.StartEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.StartEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.StartEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.StartEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ProcessInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ProcessInstance process_instance = 1;
 * @return {?proto.satys.domain.ProcessInstance}
 */
proto.satys.usertaskmanager.StartEventResponse.prototype.getProcessInstance = function() {
  return /** @type{?proto.satys.domain.ProcessInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ProcessInstance, 1));
};


/**
 * @param {?proto.satys.domain.ProcessInstance|undefined} value
 * @return {!proto.satys.usertaskmanager.StartEventResponse} returns this
*/
proto.satys.usertaskmanager.StartEventResponse.prototype.setProcessInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.usertaskmanager.StartEventResponse} returns this
 */
proto.satys.usertaskmanager.StartEventResponse.prototype.clearProcessInstance = function() {
  return this.setProcessInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.usertaskmanager.StartEventResponse.prototype.hasProcessInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.CancelProcessInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    processInstance: (f = msg.getProcessInstance()) && satys_domain_domain_pb.ProcessInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.CancelProcessInstanceRequest}
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.CancelProcessInstanceRequest;
  return proto.satys.usertaskmanager.CancelProcessInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.CancelProcessInstanceRequest}
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ProcessInstance;
      reader.readMessage(value,satys_domain_domain_pb.ProcessInstance.deserializeBinaryFromReader);
      msg.setProcessInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.CancelProcessInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ProcessInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ProcessInstance process_instance = 1;
 * @return {?proto.satys.domain.ProcessInstance}
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.prototype.getProcessInstance = function() {
  return /** @type{?proto.satys.domain.ProcessInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ProcessInstance, 1));
};


/**
 * @param {?proto.satys.domain.ProcessInstance|undefined} value
 * @return {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} returns this
*/
proto.satys.usertaskmanager.CancelProcessInstanceRequest.prototype.setProcessInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} returns this
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.prototype.clearProcessInstance = function() {
  return this.setProcessInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.usertaskmanager.CancelProcessInstanceRequest.prototype.hasProcessInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.usertaskmanager.CancelProcessInstanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.usertaskmanager.CancelProcessInstanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CancelProcessInstanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.usertaskmanager.CancelProcessInstanceResponse}
 */
proto.satys.usertaskmanager.CancelProcessInstanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.usertaskmanager.CancelProcessInstanceResponse;
  return proto.satys.usertaskmanager.CancelProcessInstanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.usertaskmanager.CancelProcessInstanceResponse}
 */
proto.satys.usertaskmanager.CancelProcessInstanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.usertaskmanager.CancelProcessInstanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.usertaskmanager.CancelProcessInstanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.usertaskmanager.CancelProcessInstanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.satys.usertaskmanager);
