meta =
    title:
        base: 'SatysApp'
        separator: '-'
        page:~ (str) ->
            if not str
                document.title = meta.title.base
            else
                document.title = "#{meta.title.base} #{meta.title.separator} #{str}"

module.exports = meta
