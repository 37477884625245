import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"

interface Attrs {
    href: string
    icon: string
    text: string
    active?: boolean
}

export default class SidebarLink extends MithrilTsx<Attrs> {
    view(vnode: this["Vnode"]) {
        const active = vnode.attrs.active || m.route.get()?.includes(vnode.attrs.href)

        return (
            <a
                href={m.route.prefix + vnode.attrs.href}
                class={`sidebar__link ${active ? "active" : ""}`}
            >
                <span>
                    { m.trust(vnode.attrs.icon) }
                    { vnode.attrs.text }
                </span>
            </a>
        )
    }
}
