import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import * as rive from "@rive-app/canvas"
import satysIcon from "url:/src/assets/satys_icon.riv"
import * as styles from "./index.module.sass"

interface Attrs {
    width?: number,
    height?: number,
    absoluteCenter?: boolean,
}

class Bumper extends MithrilTsx<Attrs> {
    riveInstance: rive.Rive
    waitForPlaying: Promise<void>

    oncreate(vnode: this["VnodeDOM"]) {
        this.waitForPlaying = new Promise((resolve) => {
            this.riveInstance = new rive.Rive({
                src: satysIcon,
                canvas: vnode.dom,
                autoplay: true,
                animations: ["Draw"],
                onStop: () => resolve(),
            })
        })
    }

    onbeforeremove() {
        return this.waitForPlaying
    }

    onremove() {
        this.riveInstance.cleanup()
    }

    view(vnode: this["Vnode"]) {
        const { width = 250, height = 250, absoluteCenter = false } = vnode.attrs
        const classes = [styles["bumper"]]
        if (absoluteCenter) {
            classes.push(styles["absolute-bumper"])
        }
        return (
            <canvas class={classes.join(" ")} width={width} height={height} />
        )
    }
}

export default Bumper
