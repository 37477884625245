import m from "mithril"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [elementName: string]: any;
        }
        interface ElementAttributesProperty {
            __tsx_attrs: any;
        }
    }
}

abstract class MithrilTsx<A> implements m.ClassComponent<A> {
    private __tsx_attrs: A & { key?: string | number }

    Vnode: m.Vnode<A, this>
    VnodeDOM: m.VnodeDOM<A, this>

    abstract view(vnode: this["Vnode"]): JSX.Element | m.Children | null | void
}

export default MithrilTsx
