m = require 'mithril'
{Dialog, Snackbar} = require '/src/components/polythene'
{datanalysis_pb, domain_pb} = require 'datanalysis-client-js'
{
    GetOrganisationRequest,
    GetProfileRequest,
    SetProfileRequest,
} = datanalysis_pb
{ Profile, User, Role, Organisation } = domain_pb
{ datanalysis_promise_stub, datanalysis_stub } = require '/src/extensions'
state = require '/src/state' .default
{ translate: t } = require '/src/i18n'
{
    get_namespace,
    grpc,
    status,
} = require '/src/utils'
{ taskFromString, taskToString } = require "/src/auth"

ns = get_namespace __filename

module.exports = (state=state) ->

    aio_get_current_organisation: ->>
        resp = await datanalysis_promise_stub.get_organisation new GetOrganisationRequest
        state.role.setOrganisation resp.getOrganisation!
        return resp.getOrganisation!

    get_profile: ({success_cb = ->} = {}) ->>
        if state.profile_status.get is status.SUCCEEDED
            success_cb state.profile
            m.redraw!
            return
        else if state.profile_status.get is status.LOADING
            state.profile_request.then (resp) ~>
                success_cb resp.getProfile!
                m.redraw!
            return
        state.profile_status.get = status.LOADING
        m.redraw!

        state.profile_request = datanalysis_promise_stub.get_profile new GetProfileRequest
        try
            resp = await state.profile_request
            state.profile = resp.getProfile!
            state.profile_status.get = status.SUCCEEDED
            success_cb resp.getProfile!
            m.redraw!
        catch err
            if err.code isnt grpc.status_code.NOT_FOUND
                state.profile_status.get = status.FAILED
                grpc.handle_err err
            else
                state.profile_status.get = status.IDLE
            m.redraw!

    set_profile: (profile) ->
        if state.profile_status.set is status.LOADING then return
        state.profile_status.set = status.LOADING
        m.redraw!

        req = new SetProfileRequest
        req.setProfile profile

        datanalysis_stub.set_profile req, {},
            (err, resp) ~>
                if err
                    state.profile_status.set = status.FAILED
                    grpc.handle_err err
                else
                    state.profile_status.set = status.SUCCEEDED
                    state.profile = profile
                    Snackbar.show do
                        title: t 'profile_set_success', ns
                m.redraw!
