state = require '/src/state' .default

dashboard = require './dashboard'
probac = require './probac'
auth = require './auth'

Actions = (state=state) ->

    dashboard: dashboard state
    probac: probac state
    auth: auth state

module.exports = Actions
