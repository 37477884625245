m = require 'mithril'
sha1 = require 'sha1'
{ Obj, keys, first } = require 'prelude-ls'
{ Timestamp } = require 'google-protobuf/google/protobuf/timestamp_pb'
{ TimeOfDay } = require "@satys/contracts/satys/generic/time_of_day_pb"
{ datanalysis_pb, domain_pb } = require 'datanalysis-client-js'
{
    GetResponsesRangeRequest,
    GetResponsesCountRequest,
    GetAnswersCountRequest,
    GetPriorityStatsRequest,
    GetRatingStatsRequest,
    GetRatingStatsTimeseriesRequest,
    GetQuestionsRequest,
    GetQuestionnaireRequest,
    GetQuestionnaireUrlsRequest,
    GetQuestionnairesRequest,
    GetMyDashboardsRequest,
    GetSuborganisationRankingsRequest,
    GetLabelRankingRequest,
    GetChannelRankingRequest,
    GetMeasurementsRequest,
    GetAnswersRequest,
    GetAnswersWithSentimentRequest,
    GetUserRolesRequest,
    GetParentRatingStatsTimeseriesRequest,
    GetOrganisationParentsRequest,
    GetAnalysesRequest,
    GetLabelsRequest,
    GetPercentileRankRequest,
    GetHourlyPatternRequest,
    GetHourlyPatternResponse,
    GetActiveChannelsRequest,
    GetActiveChannelsResponse,
    GetRoleTasksRequest,
    GetMyPerformanceIndicatorsRequest,
    GetNotificationSettingsRequest,
    GetMultipleOrganisationQuestionComparisonRequest,
    GetCohortStatsRequest,
    GetOrganisationWithDescendantsRequest,
} = datanalysis_pb
{ Answer, Question, Questionnaire, Measurement, Organisation } = domain_pb
state = require '/src/state' .default
Dashboard = require '/src/state/dashboard' .default
{ datanalysis_promise_stub, datanalysis_stub } = require '/src/extensions'
{ grpc, status } = require '/src/utils'
{ getRole } = require '/src/auth'


Actions = (state=state) ->

    redo_requests_for_endpoint: (endpoint) ->
        # Loop over all requests for given endpoint and redo them
        # The given endpoint is prefixed with `get_` for executing the actual request
        {dashboard} = state
        requests = dashboard["#{endpoint}_requests"]

        if not Obj.empty dashboard[endpoint]
            for req_id in keys dashboard[endpoint]
                requests[req_id].status = status.IDLE
                # If a promise is saved, we can safely call the async request
                if requests[req_id].promise
                    @["aio_get_#{endpoint}"] do
                        req: requests[req_id].request
                        daterange: dashboard.daterange
                # Otherwise, make the callback request
                else
                    @["get_#{endpoint}"] do
                        req: requests[req_id].request
                        daterange: dashboard.daterange

    hash_request: (req, metadata) ->
        sha1("#{JSON.stringify(metadata)}-#{JSON.stringify(req.toObject())}")

    /**
     * Callback which is used when the daterange is updated
     * Re-executes all requests for a predefined set of endpoints
     */
    updated_date_range: ->
        endpoints =
            'answers'
            'analyses'
            'answers_count'
            'priority_stats'
            'rating_stats'
            'rating_stats_timeseries'
            'parent_rating_stats_timeseries'
            'responses_count'
            'suborganisation_rankings'

        for endpoint in endpoints
            @redo_requests_for_endpoint endpoint

    /**
     * Executes a request for a given endpoint if needed
     * Async version of `request_for_endpoint`, it doesn't accept callbacks since they can be
     * implemented using `then` or `await`.
     *
     * @returns Promise which resolves to the response
     */
    aio_request_for_endpoint: ({
        endpoint,
        stub,
        request,
        daterange,
        throw_if_err = false,
        skip_endpoint_prefix = false,
        forceRefresh = false,
        extraMetadata = {}
    } = {}) ->
        { dashboard } = state

        if (daterange?start and daterange?end and request.setDateFrom and request.setDateUntil)
            daterange.start.setHours(0, 0, 0, 0)
            daterange.end.setHours(23, 59, 59, 59)
            request.setDateFrom Timestamp.fromDate daterange.start
            request.setDateUntil Timestamp.fromDate daterange.end

        if state.dashboard.filter_labels?length and request.setLabelsList
            request.setLabelsList state.dashboard.filter_labels

        metadata = Object.assign({}, state.call_metadata, extraMetadata)
        req_id = @hash_request request, metadata

        requests = dashboard["#{endpoint}_requests"]

        if not requests[req_id] or forceRefresh
            requests[req_id] =
                requestId: req_id
                status: status.IDLE
                request: request
                promise: void

        if requests[req_id].status isnt status.IDLE and requests[req_id].promise and not forceRefresh
            return requests[req_id]

        requests[req_id].status = status.LOADING
        stub_method = if skip_endpoint_prefix then endpoint else "get_#{endpoint}"

        abortController = new AbortController()
        requests[req_id].abortController = abortController
        metadata = Object.assign({}, metadata, {signal: abortController.signal})

        promise_or_stream =  stub[stub_method] request, metadata

        # is stream
        if promise_or_stream.on
            requests[req_id].stream = promise_or_stream
            requests[req_id].promise = new Promise (resolve, reject) ->
                promise_or_stream.on "data", (resp) ->
                    try
                        dashboard[endpoint][req_id].push resp
                    catch
                        dashboard[endpoint][req_id] = [resp]
                promise_or_stream.on "error", (error) ->
                    requests[req_id].status = status.FAILED
                    promise_or_stream.cancel()
                    reject error
                    m.redraw!
                promise_or_stream.on "end", ->
                    if requests[req_id].status is status.LOADING
                        requests[req_id].status = status.SUCCEEDED
                    resolve dashboard[endpoint][req_id]
                    m.redraw!
        # is promise
        else
            requests[req_id].promise = new Promise (resolve, reject) ->
                    promise_or_stream.then (resp) ->
                        requests[req_id].status = status.SUCCEEDED
                        dashboard[endpoint][req_id] = resp
                        resolve resp
                    .catch (error) ->
                        requests[req_id].status = status.FAILED
                        if throw_if_err
                            reject error
                        else
                            grpc.handle_err error
                    .finally ->
                        m.redraw!

        return requests[req_id]

    /**
     * Executes a request for given endpoint if needed
     * Will not execute the request if request is complete or busy
     * The response is stored into state.dashboard[endpoint][request_id], which can later be
     * retrieved asynchronously.
     *
     * NOTE: The success_cb is only executed when the request is actually executed, so when
     * the same request is active in another place, the success_cb won't fire.
     *
     * @returns request_id, which can be used to fetch the requested data from the state
     */
    request_for_endpoint: ({endpoint, stub, request, daterange, success_cb, error_cb} = {}) ->
        {dashboard} = state

        if daterange and daterange.start and daterange.end \
                     and request.setDateFrom and request.setDateUntil
            request.setDateFrom Timestamp.fromDate daterange.start
            request.setDateUntil Timestamp.fromDate daterange.end

        if state.dashboard.filter_labels?length and request.setLabelsList
            request.setLabelsList state.dashboard.filter_labels

        req_id = @hash_request request, state.call_metadata
        requests = dashboard["#{endpoint}_requests"]

        if not requests[req_id]
            requests[req_id] =
                requestId: req_id
                status: status.IDLE
                request: request

        if requests[req_id].status is status.SUCCEEDED
            if success_cb then success_cb dashboard[endpoint][req_id]
            return req_id

        if requests[req_id].status isnt status.IDLE
            return req_id

        requests[req_id].status = status.LOADING

        # Reset data in state
        dashboard[endpoint][req_id] = void
        # Cache the request in state
        requests[req_id].request = request

        stub["get_#{endpoint}"] request, {},
            (err, resp) ~>
                if err
                    requests[req_id].status = status.FAILED
                    if error_cb
                        error_cb err
                    else
                        grpc.handle_err err
                else
                    requests[req_id].status = status.SUCCEEDED
                    dashboard[endpoint][req_id] = resp
                    if success_cb
                        try
                            success_cb resp
                        catch
                            console.error e, e.stack
                m.redraw!
        return req_id

    aio_get_responses_range: ({
        questionnaires = []
        measurements = []
        labels = []
        channels = []
    } = {}) ->>
        req = new GetResponsesRangeRequest
        req.setQuestionnairesList questionnaires
        req.setMeasurementsList measurements
        req.setLabelsList labels
        req.setChannelsList channels
        req.setOrganisationsList [state.role.getOrganisation!]

        { promise } = @aio_request_for_endpoint do
            endpoint: 'responses_range'
            stub: datanalysis_promise_stub
            request: req

        resp = await promise

        return
            from_: resp.getDateFrom!toDate!
            until_: resp.getDateUntil!toDate!

    aio_get_responses_count: ({req, daterange = state.dashboard.daterange, throw_if_err} = {}) ->
        if not req
            req = new GetResponsesCountRequest
            req.setOrganisationsList [state.role.getOrganisation!]

        @aio_request_for_endpoint do
            endpoint: 'responses_count'
            stub: datanalysis_promise_stub
            request: req
            daterange: daterange
            throw_if_err: throw_if_err

    get_responses_count: ({req} = {}) ->
        if not req
            req = new GetResponsesCountRequest
            req.setOrganisationsList [state.role.getOrganisation!]

        @request_for_endpoint do
            endpoint: 'responses_count'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange

    aio_get_answers_count: ({req, throw_if_err} = {}) ->
        if not req
            req = new GetAnswersCountRequest
            req.setMetric Question.Metric.METRIC_MAIN
            req.setOrganisationsList [state.role.getOrganisation!]

        @aio_request_for_endpoint do
            endpoint: 'answers_count'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange
            throw_if_err: throw_if_err

    get_answers_count: ({req} = {}) ->
        if not req
            req = new GetAnswersCountRequest
            req.setMetric Question.Metric.METRIC_MAIN
            req.setOrganisationsList [state.role.getOrganisation!]

        @request_for_endpoint do
            endpoint: 'answers_count'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange

    aio_get_answers: ({req} = {}) ->
        if not req
            req = new GetAnswersRequest

        @aio_request_for_endpoint do
            endpoint: 'answers'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    get_answers: ({req, success_cb} = {}) ->
        if not req
            req = new GetAnswersRequest

        @request_for_endpoint do
            endpoint: 'answers'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange
            success_cb: success_cb

    aio_get_answers_with_sentiment: ({ req, questions } = {}) ->
        if not req
            req = new GetAnswersWithSentimentRequest
            req.setQuestionsList questions

        @aio_request_for_endpoint do
            endpoint: 'answers_with_sentiment'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    get_answers_with_sentiment: ({req, success_cb} = {}) ->
        if not req
            req = new GetAnswersWithSentimentRequest

        @request_for_endpoint do
            endpoint: 'answers_with_sentiment'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange
            success_cb: success_cb

    aio_get_rating_stats: ({
        answers = [],
        questions = [],
        measurements = [],
        labels = [],
        metric = Question.Metric.METRIC_MAIN,
        performance_indicator,
        daterange = state.dashboard.daterange,
        throw_if_err
    } = {}) ->
        req = new GetRatingStatsRequest
        req.setMetric metric
        req.setAnswersList answers
        req.setQuestionsList questions
        req.setMeasurementsList measurements
        req.setOrganisationsList [state.role.getOrganisation!]
        req.setPerformanceIndicator performance_indicator
        req.setLabelsList labels

        return @aio_request_for_endpoint do
            endpoint: 'rating_stats'
            stub: datanalysis_promise_stub
            request: req
            daterange: daterange
            throw_if_err: throw_if_err

    get_rating_stats: ({
        req = void,
        answers = [],
        questions = [],
        measurements = [],
        metric = Question.Metric.METRIC_MAIN,
        performance_indicator,
        success_cb,
    } = {}) ->
        if not req
            req = new GetRatingStatsRequest
            req.setMetric metric
            req.setAnswersList answers
            req.setQuestionsList questions
            req.setMeasurementsList measurements
            req.setOrganisationsList [state.role.getOrganisation!]
            req.setPerformanceIndicator performance_indicator

        @request_for_endpoint do
            endpoint: 'rating_stats'
            stub: datanalysis_stub
            request: req
            success_cb: success_cb
            daterange: state.dashboard.daterange

    aio_get_parent_rating_stats_timeseries: ({
        performance_indicator,
        window_size = 7
    } = {}) ->
        req = new GetParentRatingStatsTimeseriesRequest
        req.setPerformanceIndicator performance_indicator

        start = state.dashboard.daterange.start
        try
            return @aio_request_for_endpoint do
                endpoint: 'parent_rating_stats_timeseries'
                stub: datanalysis_promise_stub
                request: req
                daterange:
                    start: new Date(new Date start |> (.setDate start.getDate! - window_size))
                    end: state.dashboard.daterange.end
                throw_if_err: true
        catch
            if e.code isnt grpc.status_code.NOT_FOUND
                grpc.handle_err error

    aio_get_rating_stats_timeseries: ({
        answers = [],
        questions = [],
        measurements = [],
        metric = Question.Metric.METRIC_MAIN,
        performance_indicator,
        window_size = 7
    } = {}) ->
        req = new GetRatingStatsTimeseriesRequest
        req.setAnswersList answers
        req.setQuestionsList questions
        req.setMeasurementsList measurements
        req.setMetric metric
        req.setOrganisationsList [state.role.getOrganisation!]
        req.setPerformanceIndicator performance_indicator

        start = state.dashboard.daterange.start
        return @aio_request_for_endpoint do
            endpoint: 'rating_stats_timeseries'
            stub: datanalysis_promise_stub
            request: req
            daterange:
                start: new Date(new Date start |> (.setDate start.getDate! - window_size))
                end: state.dashboard.daterange.end


    get_rating_stats_timeseries: ({
        answers = [],
        questions = [],
        measurements = [],
        metric = Question.Metric.METRIC_MAIN,
        performance_indicator,
        window_size = 7
    } = {}) ->
        req = new GetRatingStatsTimeseriesRequest
        req.setAnswersList answers
        req.setQuestionsList questions
        req.setMeasurementsList measurements
        req.setMetric metric
        req.setOrganisationsList [state.role.getOrganisation!]
        req.setPerformanceIndicator performance_indicator

        start = state.dashboard.daterange.start
        @request_for_endpoint do
            endpoint: 'rating_stats_timeseries'
            stub: datanalysis_stub
            request: req
            daterange:
                start: new Date(new Date start |> (.setDate start.getDate! - window_size))
                end: state.dashboard.daterange.end

    aio_get_priority_stats: ({
        req = void,
        answers = [],
        questions = [],
        measurements = [],
        subjects = [],
        performance_indicator,
        strategy,
        throw_if_err,
        daterange = state.dashboard.daterange,
    } = {}) ->
        if not req
            req = new GetPriorityStatsRequest
            req.setAnswersList answers
            req.setQuestionsList questions
            req.setMeasurementsList measurements
            req.setPerformanceIndicator performance_indicator
            req.setSubjectsList subjects
            req.setOrganisationsList [state.role.getOrganisation!]
            req.setStrategy strategy

        @aio_request_for_endpoint do
            endpoint: 'priority_stats'
            stub: datanalysis_promise_stub
            request: req
            daterange: daterange
            throw_if_err: throw_if_err

    get_priority_stats: ({
        req = void,
        answers = [],
        questions = [],
        measurements = [],
        subjects = [],
        performance_indicator,
        error_cb,
    } = {}) ->
        if not req
            req = new GetPriorityStatsRequest
            req.setAnswersList answers
            req.setQuestionsList questions
            req.setMeasurementsList measurements
            req.setPerformanceIndicator performance_indicator
            req.setSubjectsList subjects
            req.setOrganisationsList [state.role.getOrganisation!]

        if not error_cb
            error_cb = (err) ~>
                # Silence the error if invalid question types are set
                if err.code is grpc.status_code.FAILED_PRECONDITION
                    console.warn err
                else
                    grpc.handle_err err

        @request_for_endpoint do
            endpoint: 'priority_stats'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange
            error_cb: error_cb

    aio_get_measurements: ({req} = {}) ->
        if not req
            req = new GetMeasurementsRequest
            req.setOrganisation state.role.getOrganisation!

        return @aio_request_for_endpoint do
            endpoint: 'measurements'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    get_measurements: ({req, success_cb} = {}) ->
        if not req
            req = new GetMeasurementsRequest
            req.setOrganisation state.role.getOrganisation!

        return @request_for_endpoint do
            endpoint: 'measurements'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange
            success_cb: success_cb

    get_analyses: ({req, success_cb} = {}) ->
        if not req
            req = new GetAnalysesRequest
            req.setOrganisationsList [state.role.getOrganisation!]

        return @request_for_endpoint do
            endpoint: 'analyses'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange
            success_cb: success_cb

    aio_get_analyses: ({ req = void } = {}) ->
        if not req
            req = new GetAnalysesRequest
            req.setOrganisationsList [state.role.getOrganisation!]

        return @aio_request_for_endpoint do
            endpoint: 'analyses'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    get_questions: ({
        req = void,
        questionnaires = [],
        measurements = [],
        success_cb,
    } = {}) ->
        if not req
            req = new GetQuestionsRequest
            req.setQuestionnairesList questionnaires
            req.setMeasurementsList measurements

        return @request_for_endpoint do
            endpoint: 'questions'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange
            success_cb: success_cb

    aio_get_questions: ({
        req = void,
        questionnaires = [],
        measurements = [],
    } = {}) ->
        if not req
            req = new GetQuestionsRequest
            req.setQuestionnairesList questionnaires
            req.setMeasurementsList measurements

        return @aio_request_for_endpoint do
            endpoint: 'questions'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    get_questionnaires: ({req, success_cb} = {}) ->
        if not req
            req = new GetQuestionnairesRequest
            req.setOrganisationsList [state.role.getOrganisation!]

        @request_for_endpoint do
            endpoint: 'questionnaires'
            stub: datanalysis_stub
            request: req
            daterange: state.dashboard.daterange
            success_cb: success_cb

    aio_get_questionnaires: ({req} = {}) ->
        if not req
            req = new GetQuestionnairesRequest
            req.setOrganisationsList [state.role.getOrganisation!]

        return @aio_request_for_endpoint do
            endpoint: 'questionnaires'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    get_questionnaire: ({
        req = void,
        questionnaire = void,
        success_cb,
    } = {}) ->
        if not req
            req = new GetQuestionnaireRequest
            req.setQuestionnaire questionnaire

        @request_for_endpoint do
            endpoint: 'questionnaire'
            stub: datanalysis_stub
            request: req
            success_cb: success_cb

    aio_get_questionnaire: ({
        req = void,
        questionnaire = void,
    } = {}) ->
        if not req
            req = new GetQuestionnaireRequest
            req.setQuestionnaire questionnaire

        return @aio_request_for_endpoint do
            endpoint: 'questionnaire'
            stub: datanalysis_promise_stub
            request: req

    aio_get_questionnaire_urls: ({req, throw_if_err} = {}) ->
        if not req
            req = new GetQuestionnaireUrlsRequest

        return @aio_request_for_endpoint do
            endpoint: 'questionnaire_urls'
            stub: datanalysis_promise_stub
            request: req
            throw_if_err: throw_if_err

    aio_get_organisation_parents: ({req} = {}) ->
        if not req
            req = new GetOrganisationParentsRequest
            req.setOrganisation state.role.getOrganisation!

        return @aio_request_for_endpoint do
            endpoint: 'organisation_parents'
            stub: datanalysis_promise_stub
            request: req

    aio_get_my_dashboards: ->
        return @aio_request_for_endpoint do
            endpoint: "my_dashboards"
            stub: datanalysis_promise_stub
            request: new GetMyDashboardsRequest

    get_my_dashboards: ({success_cb, error_cb} = {}) ->
        state.dashboard.dashboards_status = status.LOADING
        datanalysis_stub.get_my_dashboards do
            new GetMyDashboardsRequest
            {}
            (err, resp) ~>
                if err
                    state.dashboard.dashboards_status = status.FAILED
                    if error_cb then error_cb err
                    else grpc.handle_err err
                else
                    state.dashboard.dashboards_status = status.SUCCEEDED
                    state.dashboard.dashboards = resp
                    if success_cb then success_cb resp
                m.redraw!

    aio_get_suborganisation_rankings: ({performance_indicator, throw_if_err} = {}) ->
        req = new GetSuborganisationRankingsRequest
        req.setPerformanceIndicator performance_indicator

        return @aio_request_for_endpoint do
            endpoint: 'suborganisation_rankings'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange
            throw_if_err: throw_if_err

    get_suborganisation_rankings: ({performance_indicator, success_cb, error_cb} = {}) ->
        req = new GetSuborganisationRankingsRequest
        req.setPerformanceIndicator performance_indicator

        @request_for_endpoint do
            endpoint: 'suborganisation_rankings'
            stub: datanalysis_stub
            request: req
            success_cb: success_cb
            daterange: state.dashboard.daterange

    aio_get_label_ranking: ({performance_indicator, label_scope} = {}) ->
        req = new GetLabelRankingRequest
        req.setPerformanceIndicator performance_indicator
        req.setLabelScope label_scope

        return @aio_request_for_endpoint do
            endpoint: 'label_ranking'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    aio_get_channel_ranking: ({performance_indicator, channel_type} = {}) ->
        req = new GetChannelRankingRequest
        req.setPerformanceIndicator performance_indicator
        req.setChannelType channel_type

        return @aio_request_for_endpoint do
            endpoint: 'channel_ranking'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    aio_get_user_roles: ({ req, depth = 0, forceRefresh = false, extraMetadata = {} } = {}) ->
        if not req
            req = new GetUserRolesRequest
            req.setDepth depth

        return @aio_request_for_endpoint do
            endpoint: 'user_roles'
            stub: datanalysis_promise_stub
            request: req
            forceRefresh: forceRefresh
            extraMetadata: extraMetadata

    aio_get_role_tasks: ->
        req = new GetRoleTasksRequest

        return @aio_request_for_endpoint do
            endpoint: 'role_tasks'
            stub: datanalysis_promise_stub
            request: req

    aio_get_labels: ({scope} = {}) ->
        req = new GetLabelsRequest
        req.setScope scope

        return @aio_request_for_endpoint do
            endpoint: 'labels'
            stub: datanalysis_promise_stub
            request: req

    get_channel_count: ({type=void, force=false} = {}) ->
        # @["get_channel_count_#{type}_active"] = true

        # req = new GetChannelCountRequest
        # req.setType type
        # req.set-organisations-list [state.role.getOrganisation!]

        # datanalysis_stub.get_channel_count req, {},
        #     @["get_channel_count_#{type}_active"] = false
        #     (err, resp) ~>
        #         if err
        #             grpc.handle_err err
        #         else
        #             state.dashboard["channel_count_#{type}"] = resp.get-channel-count!
        state.dashboard["channel_count_#{type}"] = 9876

    aio_get_percentile_rank: ->
        req = new GetPercentileRankRequest

        return @aio_request_for_endpoint do
            endpoint: "percentile_rank"
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange
            throw_if_err: true

    aio_get_hourly_pattern: ({ req = void, days, timeRange, performanceIndicator } = {}) ->
        if not req
            startTimeOfDay = new TimeOfDay
            startTimeOfDay.setHours(timeRange.start.hours)
            startTimeOfDay.setMinutes(timeRange.start.minutes)

            endTimeOfDay = new TimeOfDay
            endTimeOfDay.setHours(timeRange.end.hours)
            endTimeOfDay.setMinutes(timeRange.end.minutes)

            timeRange_ = new GetHourlyPatternRequest.TimeRange
            timeRange_.setStart(startTimeOfDay)
            timeRange_.setEnd(endTimeOfDay)
            timeRange_.setIntervalMinutes(timeRange.intervalMinutes)

            req = new GetHourlyPatternRequest()
            req.setDaysList(days)
            req.setTimeRange(timeRange_)
            req.setPerformanceIndicator(performanceIndicator)
            req.setOrganisationsList([state.role.getOrganisation!])

        return @aio_request_for_endpoint do
            endpoint: "hourly_pattern"
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    aioGetActiveChannels: ({ 
        req = void,
        answers = [],
        questions = [],
        measurements = [],
    } = {}) ->
        if not req
            req = new GetActiveChannelsRequest
            req.setAnswersList answers
            req.setQuestionsList questions
            req.setMeasurementsList measurements

        return @aio_request_for_endpoint do
            endpoint: "active_channels"
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange

    aio_get_my_performance_indicators: ->
        return @aio_request_for_endpoint do
            endpoint: 'my_performance_indicators'
            stub: datanalysis_promise_stub
            request: new GetMyPerformanceIndicatorsRequest

    aio_get_notification_settings: ({ req = void } = {}) ->
        if not req
            req = new GetNotificationSettingsRequest

        return @aio_request_for_endpoint do
            endpoint: "notification_settings"
            stub: datanalysis_promise_stub
            request: req

    aio_update_notification_settings: ({ req = void, throw_if_err = true } = {}) ->
        if not req
            throw new Error "Should provide a request object"

        return @aio_request_for_endpoint do
            endpoint: "update_notification_settings"
            skip_endpoint_prefix: true
            stub: datanalysis_promise_stub
            request: req
            throw_if_err: throw_if_err

    aio_get_multiple_organisations_question_comparison: ({
        req = void,
        question_str = void,
        question_type = void,
        throw_if_err = false
    } = {}) ->
        if not req
            question_pb = new Question
            question_pb.setStr(question_str)
            question_pb.setTypeEnum(question_type)
            req = new GetMultipleOrganisationQuestionComparisonRequest
            req.setQuestion(question_pb)

        return @aio_request_for_endpoint do
            endpoint: 'multiple_organisation_question_comparison'
            stub: datanalysis_promise_stub
            request: req
            daterange: state.dashboard.daterange
            throw_if_err: throw_if_err

    aio_get_cohort_stats: ({
        req = void,
        cohorts = [],
        answers = [],
        questions = [],
        questionnaires = [],
        measurements = [],
        organisations = [state.role.getOrganisation!],
        labels = [],
        channels = [],
        performance_indicator = state.role.getOrganisation?getPerformanceIndicator!,
        daterange = state.dashboard.daterange,
        throw_if_err
    } = {}) ->
        if not req
            req = new GetCohortStatsRequest
            req.setAnswersList answers
            req.setQuestionsList questions
            req.setQuestionnairesList questionnaires
            req.setMeasurementsList measurements
            req.setOrganisationsList organisations
            req.setLabelsList labels
            req.setChannelsList channels
            req.setCohortsList cohorts
            req.setPerformanceIndicator performance_indicator

        return @aio_request_for_endpoint do
            endpoint: 'cohort_stats'
            stub: datanalysis_promise_stub
            request: req
            daterange: daterange
            throw_if_err: throw_if_err

    aio_organisation_with_descendants: ({
        extraMetadata = {},
    }) ->
        req = new GetOrganisationWithDescendantsRequest

        return @aio_request_for_endpoint do
            endpoint: 'organisation_with_descendants'
            stub: datanalysis_promise_stub
            request: req
            extraMetadata: extraMetadata

    aio_assign_label: ({ req, throw_if_err = true } = {}) ->
        if not req
            throw new Error "Should provide an AssignLabelRequest object"

        return @aio_request_for_endpoint do
            endpoint: 'assign_label'
            skip_endpoint_prefix: true
            stub: datanalysis_promise_stub,
            request: req
            throw_if_err: throw_if_err

    aio_unassign_label: ({ req, throw_if_err = true } = {}) ->
        if not req
            throw new Error "Should provide an UnassignLabelRequest object"

        return @aio_request_for_endpoint do
            endpoint: 'unassign_label'
            skip_endpoint_prefix: true
            stub: datanalysis_promise_stub,
            request: req
            throw_if_err: throw_if_err

    init_dashboard: ->
        # Make a copy of the daterange before resetting it.
        { start, end } = state.dashboard.daterange

        state.dashboard = new Dashboard!

        # Set the daterange to the just copied one.
        state.dashboard.daterange.start = start
        state.dashboard.daterange.end = end

module.exports = Actions
