import config from "/src/config"
import { AuthRequestInterceptor, AioAuthRequestInterceptor } from "./interceptors/auth_interceptor"
import { ThrottledStreamInterceptor } from "./interceptors/throttle_interceptor"
import { EnvironmentRequestInterceptor } from "./interceptors/environment_interceptor"
import { CancelAioUnaryInterceptor } from "./interceptors/cancel_interceptor"

import { DatanalysisAPIClient, DatanalysisAPIPromiseClient } from "@satys/contracts/satys/datanalysis/datanalysis_grpc_web_pb"
import { UserTaskManagerAPIClient, UserTaskManagerAPIPromiseClient } from "@satys/contracts/satys/usertaskmanager/usertaskmanager_grpc_web_pb"

const promise_stub_opts = Object.assign({}, config.grpc_stub_opts, {
    "unaryInterceptors": [
        new CancelAioUnaryInterceptor(),
        new EnvironmentRequestInterceptor(),
        new AioAuthRequestInterceptor(),
    ],
    "streamInterceptors": [
        new EnvironmentRequestInterceptor(),
        new AioAuthRequestInterceptor(),
        new ThrottledStreamInterceptor(),
    ],
})
const stub_opts = Object.assign({}, config.grpc_stub_opts, {
    "unaryInterceptors": [
        new EnvironmentRequestInterceptor(),
        new AuthRequestInterceptor(),
    ],
    "streamInterceptors": [
        new EnvironmentRequestInterceptor(),
        new AioAuthRequestInterceptor(),
        new ThrottledStreamInterceptor(),
    ],
})

export const datanalysis_stub = new DatanalysisAPIClient(config.api_url, {}, stub_opts)
export const datanalysis_promise_stub = new DatanalysisAPIPromiseClient(config.api_url, {}, promise_stub_opts)
export const utm_stub = new UserTaskManagerAPIClient(config.api_url, {}, stub_opts)
export const utm_promise_stub = new UserTaskManagerAPIPromiseClient(config.api_url, {}, promise_stub_opts)
