import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import Snackbar from "/src/components/polythene/Snackbar"
import Spinner from "/src/components/polythene/MaterialDesignSpinner"

import { RpcError } from "grpc-web"
import { Actions } from "/src/actions"
import { getUserRoles } from "/src/auth"
import { Message } from "google-protobuf"
import state, { State } from "/src/state"
import { grpc, Status } from "/src/utils"
import { translate as t } from "/src/i18n"
import { PrimaryButton, Select } from "/src/components/polythene"
import { NotificationSetting, Profile, Role } from "@satys/contracts/satys/domain/domain_pb"
import { GetNotificationSettingsRequest, GetNotificationSettingsResponse, UpdateNotificationSettingsRequest, UpdateNotificationSettingsResponse } from "@satys/contracts/satys/datanalysis/datanalysis_pb"

const ns = "profile.notifications"

type Attrs = {
    actions: (state: State) => Actions
}

function grpcEnumToOptions(value: unknown) {
    return Object.entries(value)
        .filter(([_, value]) => value !== 0)
        .map(([key, value]) => ({
            text: t(key, ns),
            value,
            disabled: false,
        }))
}

export default class Notifications extends MithrilTsx<Attrs> {
    status: Status
    saving: Status
    actions: Actions
    notificationSettings: NotificationSetting[]
    newNotificationSetting?: NotificationSetting | null = null
    invalidSettingIndexes: number[] = []
    errorMessage: string
    profile: Profile
    roles: Role[]
    renderNewNotificationSetting = false

    async oninit(vnode: this["Vnode"]) {
        const { actions } = vnode.attrs
        this.actions = actions(state)

        this.status = Status.LOADING
        m.redraw()

        this.profile = new Profile()
        this.profile.setUser(state.user)

        const request = new GetNotificationSettingsRequest()
        request.setProfile(this.profile)

        const requestState = this.actions.dashboard.aio_get_notification_settings<GetNotificationSettingsRequest, GetNotificationSettingsResponse>({
            req: request,
        })
        const response = await requestState.promise
        this.notificationSettings = response.getNotificationSettingsList()

        if (this.notificationSettings.length === 0) {
            const notification_setting = new NotificationSetting()
            notification_setting.setOrganisation(state.role.getOrganisation())
            notification_setting.setProfile(this.profile)

            this.notificationSettings.push(notification_setting)
        }

        this.roles = await getUserRoles()

        this.status = Status.SUCCEEDED
    }

    invalidSettings() {
        const inCompleteSettings = this.notificationSettings
            .filter(setting => setting.getInterval() === NotificationSetting.Interval.INTERVAL_UNSPECIFIED
                || setting.getMethod() === NotificationSetting.Method.METHOD_UNSPECIFIED)

        return inCompleteSettings.map(x => this.notificationSettings.indexOf(x))
    }

    async save() {
        if (this.newNotificationSetting !== null) {
            this.renderNewNotificationSetting = false
            this.notificationSettings.push(this.newNotificationSetting)
        }

        this.invalidSettingIndexes = this.invalidSettings()
        if (this.invalidSettingIndexes.length) {
            const index = this.notificationSettings.findIndex(setting => Message.equals(setting, this.newNotificationSetting))
            if (index !== -1) {
                this.notificationSettings.splice(index, 1)
            }
            m.redraw()
            return
        }

        this.errorMessage = ""

        this.saving = Status.LOADING
        m.redraw()

        const request = new UpdateNotificationSettingsRequest()
        request.setNotificationSettingsList(this.notificationSettings)

        const requestState = this.actions.dashboard.aio_update_notification_settings<UpdateNotificationSettingsRequest, UpdateNotificationSettingsResponse>({
            req: request,
        })

        try {
            await requestState.promise
            Snackbar.show({ title: t("savedChanges", ns) })
            this.saving = Status.SUCCEEDED
        } catch (error) {
            if (error instanceof RpcError) {
                grpc.handleError(error)
            }
            this.errorMessage = error.message
            console.error(error)
            this.status = Status.IDLE
            const index = this.notificationSettings.findIndex(setting => Message.equals(setting, this.newNotificationSetting))
            if (index !== -1) {
                this.notificationSettings.splice(index, 1)
            }
            if (this.newNotificationSetting !== null) {
                this.renderNewNotificationSetting = true
            }
        }

        this.renderNewNotificationSetting = false
        this.newNotificationSetting = null
        m.redraw()
    }

    renderNewNotificationSettingHtml() {
        this.newNotificationSetting.setProfile(this.profile)

        return (
            <div class="pv3 ph4 mt4 bg-white br3 shadow-4">
                <Select
                    label={t("organisation", ns)}
                    options={this.roles.map(role => ({
                        text: role.getOrganisation().getName(),
                        value: role.getOrganisation(),
                        disabled: false,
                    }))}
                    onChange={({ value, added, invalid }) => {
                        if (!invalid && added) {
                            this.newNotificationSetting.setOrganisation(value.clone())
                        }
                    }}
                />

                <Select
                    label={t("periodicReportInterval", ns)}
                    options={grpcEnumToOptions(NotificationSetting.Interval)}
                    onChange={({ value, added, invalid }) => {
                        if (!invalid && added) {
                            this.newNotificationSetting.setInterval(Number(value))
                        }
                    }}
                />

                <Select
                    label={t("periodicReportMedium", ns)}
                    options={grpcEnumToOptions(NotificationSetting.Method)}
                    onChange={({ value, added, invalid }) => {
                        if (!invalid && added) {
                            this.newNotificationSetting.setMethod(Number(value))
                        }
                    }}
                />

                <div class="flex justify-end">
                    <PrimaryButton
                        label={t("save", ns)}
                        loading={this.saving === Status.LOADING}
                        events={{
                            onclick: () => this.save(),
                        }}
                    />
                </div>
            </div>
        )
    }

    view() {
        return (
            <>
                { this.status === Status.LOADING ? (
                    <div class="relative h5">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <form onsubmit={() => this.save.bind(this)}>
                            <h1 className="mt3 mb2">{ t("periodicReport", ns) }</h1>
                            <div className="group">
                                { this.notificationSettings.map((setting, index) => {
                                    return (
                                        <div key={index}>
                                            <div class="pv3 ph4 mt4 bg-white br3 shadow-4">
                                                <div class="flex justify-between items-center">
                                                    <div>
                                                        <h4 class="mt0 mb2">{ setting.getOrganisation().getName() }</h4>
                                                        <small class="db mb3">{ setting.getOrganisation().getDomain() }</small>
                                                    </div>
                                                </div>

                                                <Select
                                                    label={t("periodicReportInterval", ns)}
                                                    defaultValue={setting.getInterval() || undefined}
                                                    options={grpcEnumToOptions(NotificationSetting.Interval)}
                                                    onChange={({ value, added, invalid }) => {
                                                        if (!invalid && added) {
                                                            setting.setInterval(Number(value))
                                                        }
                                                    }}
                                                />

                                                <Select
                                                    label={t("periodicReportMedium", ns)}
                                                    defaultValue={setting.getMethod() || undefined}
                                                    options={grpcEnumToOptions(NotificationSetting.Method)}
                                                    onChange={({ value, added, invalid }) => {
                                                        if (!invalid && added) {
                                                            setting.setMethod(Number(value))
                                                        }
                                                    }}
                                                />
                                            </div>

                                            { this.invalidSettingIndexes.includes(index) ?  (
                                                <p className="mv0 f6 orange lh1">{ t("notificationSettingsError", ns) }</p>
                                            ) : <></> }
                                        </div>
                                    )
                                }) }

                                { this.renderNewNotificationSetting ? (
                                    this.renderNewNotificationSettingHtml()
                                ) : <></> }

                                <p className="mv0 f6 orange lh1">{ this.errorMessage }</p>

                                { !this.renderNewNotificationSetting ? (
                                    <PrimaryButton
                                        label={t("newNotificationSetting", ns)}
                                        events={{
                                            onclick: () => {
                                                this.newNotificationSetting = new NotificationSetting()
                                                this.renderNewNotificationSetting = true
                                            },
                                        }}
                                    />
                                ) : <></> }
                            </div>
                            <div className="group">
                                <PrimaryButton
                                    label={t("save", ns)}
                                    className="fr"
                                    loading={this.saving === Status.LOADING}
                                    events={{
                                        onclick: () => this.save(),
                                    }}
                                />
                            </div>
                        </form>
                    </>
                ) }
            </>
        )
    }
}
