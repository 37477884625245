import m from "mithril"
import {
    TextField as OriginalTextField,
    onChangeTextFieldState,
} from "polythene-mithril"
import { TextFieldCSS } from "polythene-css"
import MithrilTsx from "/src/mithril-tsx"
import { translate as t } from "/src/i18n"
import { getNamespace } from "/src/utils"
const ns = getNamespace(__filename)

interface Attrs {
    id?: string,
    type?: string,
    before?: JSX.Element,
    icon?: string | JSX.Element,
    label?: string,
    desc?: string,
    placeholder?: string,
    floatingLabel?: boolean,
    submitOnEnter?: boolean,
    dense?: boolean,
    events?: {
        onkeyup?: (...any: unknown[]) => unknown,
        [key: string]: (...any: unknown[]) => unknown,
    },
    help?: string,
    className?: string,
    disabled?: boolean,
    validateOnInput?: boolean,
    onChange?: (any: onChangeTextFieldState) => unknown,
    error?: string,
    [key: string]: unknown,
}

class TextField extends MithrilTsx<Attrs> {
    typeToggle = false
    className = "satys-textfield"
    error = ""
    iconClass = ""
    iconClick: () => void

    oninit({
        attrs: {
            type,
            icon,
            placeholder,
            before,
        },
    }: this["Vnode"]) {
        if (type === "password") {
            this.iconClass = ".pointer"
            this.iconClick = () => this.typeToggle = !this.typeToggle
        }

        if (placeholder) {
            this.className += " has-placeholder"
        }

        if (icon || type === "password") {
            this.className += " has-icon"

            if (before) {
                console.warn("Both `icon` and `before` defined, icon will take precedence.")
            }
        }
    }

    oncreate() {
        // Ensure that the labels are floating when a textfield is initialized
        // with content.
        m.redraw()
    }

    setErrorMessage(element: HTMLInputElement) {
        const validity = element.validity
        if (validity.patternMismatch) {
            this.error = t("pattern_mismatch", ns)
        } else if (validity.rangeOverflow) {
            this.error = `${t("range_overflow", ns)} ${element.max}`
        } else if (validity.rangeUnderflow) {
            this.error = `${t("range_underflow", ns)} ${element.max}`
        } else if (validity.stepMismatch) {
            this.error = t("step_mismatch", ns)
        } else if (validity.tooLong) {
            this.error = `${t("too_long", ns)} ${element.maxLength}`
        } else if (validity.tooShort) {
            this.error = `${t("too_short", ns)} ${element.minLength}`
        } else if (validity.typeMismatch) {
            this.error = `${t("type_mismatch", ns)} ${element.type}`
        } else if (validity.valueMissing) {
            this.error = t("value_missing", ns)
        } else {
            this.error = t("error", ns)
        }
        m.redraw()
    }

    view({
        children,
        attrs: {
            id,
            type = "text",
            label,
            floatingLabel = true,
            before,
            icon,
            submitOnEnter = true,
            dense = true,
            desc,
            events: {
                onkeyup,
                ...otherEvents
            } = {},
            help,
            className = "",
            disabled = false,
            validateOnInput = true,
            onChange = () => undefined,
            error = "",
            ...attrs
        },
    }: this["Vnode"]) {

        let disp_type = type
        if (type === "password") {
            if (this.typeToggle) {
                disp_type = "text"
                icon = icon || "visibility"
            } else {
                icon = "visibility_off"
            }
        }

        if (typeof icon === "string") {
            icon = <i class="before material-icons">{ icon }</i>
        }

        return m(OriginalTextField, {
            id: id,
            className: `${this.className} ${className}`,
            type: disp_type,
            error: error || this.error,
            before: icon || before,
            help: desc || help,
            dense: dense,
            validateOnInput: validateOnInput,
            floatingLabel: floatingLabel,
            label: label,
            disabled: disabled,
            onChange: (args) => {
                const element = args.el as HTMLInputElement
                if (element.validity) {
                    this.setErrorMessage(element)
                }
                onChange(args)
            },
            events: {
                onkeyup: (e: KeyboardEvent) => {
                    if (onkeyup) {
                        return onkeyup(e)
                    }
                    const target = (e.target as HTMLInputElement)
                    if (target.form) {
                        if (submitOnEnter && e.key === "Enter") {
                            if (target.form.requestSubmit) {
                                target.form.requestSubmit()
                            }
                            else if (target.form.submit) {
                                target.form.submit()
                            }
                        }
                    }
                },
                ...otherEvents,
            },
            ...attrs,
        }, children)
    }
}

TextFieldCSS.addStyle(".satys-textfield", {
    dense_floating_label_vertical_spacing_top: 20,
    dense_floating_label_vertical_spacing_bottom: 8,
    vertical_spacing_bottom: 7,
    font_size_input: 14,
    input_padding_v: 4,
    input_border_width: 2,
    color_light_input_background: "rgba(0, 0, 0, 0.06)",
})

export default TextField
