declare module "*.webp"
declare module "*.png"
declare module "*.jpg"
declare module "*.jpeg"
declare module "*.json"
declare module "*.sass"

declare namespace Intl {
    interface DateTimeFormat {
        formatRange(startName: Date, endNumber: Date): string;
    }
}
