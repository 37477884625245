state = require '/src/state' .default

{first} = require 'prelude-ls'

module.exports = (id, version) ->
    window.dataLayer = window.dataLayer or []

    window.hj = window.hj or ->
        (window.hj.q = window.hj.q or []).push arguments
    window._hjSettings =
        hjid: id
        hjsv: version

    script_ = first document.getElementsByTagName 'script'
    script = document.createElement 'script'
    script.async = true
    script.src = "https://static.hotjar.com/c/hotjar-#{id}.js?sv=#{version}"
    script_.parentNode.insertBefore script, script_

    return window.hj
