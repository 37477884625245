import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"

export default class TableCell extends MithrilTsx<Record<string, unknown>> {

    view(vnode: this["Vnode"]) {
        return (
            <td {...vnode.attrs}>
                { vnode.children }
            </td>
        )
    }
}
