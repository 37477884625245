// source: satys/domain/domain.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('proto.satys.domain.Action', null, global);
goog.exportSymbol('proto.satys.domain.Analysis', null, global);
goog.exportSymbol('proto.satys.domain.Analysis.RelationCase', null, global);
goog.exportSymbol('proto.satys.domain.Analysis.Type', null, global);
goog.exportSymbol('proto.satys.domain.Answer', null, global);
goog.exportSymbol('proto.satys.domain.Channel', null, global);
goog.exportSymbol('proto.satys.domain.ChannelPlanning', null, global);
goog.exportSymbol('proto.satys.domain.CohortMetric', null, global);
goog.exportSymbol('proto.satys.domain.Dashboard', null, global);
goog.exportSymbol('proto.satys.domain.ExternalOrganisation', null, global);
goog.exportSymbol('proto.satys.domain.Integration', null, global);
goog.exportSymbol('proto.satys.domain.Label', null, global);
goog.exportSymbol('proto.satys.domain.Measurement', null, global);
goog.exportSymbol('proto.satys.domain.MeasurementId', null, global);
goog.exportSymbol('proto.satys.domain.NotificationSetting', null, global);
goog.exportSymbol('proto.satys.domain.NotificationSetting.Interval', null, global);
goog.exportSymbol('proto.satys.domain.NotificationSetting.Method', null, global);
goog.exportSymbol('proto.satys.domain.Organisation', null, global);
goog.exportSymbol('proto.satys.domain.OrganisationId', null, global);
goog.exportSymbol('proto.satys.domain.OrganisationTree', null, global);
goog.exportSymbol('proto.satys.domain.OrganisationWithDescendants', null, global);
goog.exportSymbol('proto.satys.domain.OverallMetric', null, global);
goog.exportSymbol('proto.satys.domain.PerformanceIndicator', null, global);
goog.exportSymbol('proto.satys.domain.PerformanceIndicator.Type', null, global);
goog.exportSymbol('proto.satys.domain.PeriodReport', null, global);
goog.exportSymbol('proto.satys.domain.PeriodReport.DateTimeRange', null, global);
goog.exportSymbol('proto.satys.domain.PeriodReport.Topic', null, global);
goog.exportSymbol('proto.satys.domain.PriorityMetric', null, global);
goog.exportSymbol('proto.satys.domain.ProbacTree', null, global);
goog.exportSymbol('proto.satys.domain.Process', null, global);
goog.exportSymbol('proto.satys.domain.ProcessElement', null, global);
goog.exportSymbol('proto.satys.domain.ProcessElement.ElementType', null, global);
goog.exportSymbol('proto.satys.domain.ProcessElement.Status', null, global);
goog.exportSymbol('proto.satys.domain.ProcessIncident', null, global);
goog.exportSymbol('proto.satys.domain.ProcessIncident.Status', null, global);
goog.exportSymbol('proto.satys.domain.ProcessInstance', null, global);
goog.exportSymbol('proto.satys.domain.ProcessTree', null, global);
goog.exportSymbol('proto.satys.domain.Product', null, global);
goog.exportSymbol('proto.satys.domain.ProductTree', null, global);
goog.exportSymbol('proto.satys.domain.Profile', null, global);
goog.exportSymbol('proto.satys.domain.Question', null, global);
goog.exportSymbol('proto.satys.domain.Question.Metric', null, global);
goog.exportSymbol('proto.satys.domain.Question.Type', null, global);
goog.exportSymbol('proto.satys.domain.QuestionId', null, global);
goog.exportSymbol('proto.satys.domain.Questionnaire', null, global);
goog.exportSymbol('proto.satys.domain.QuestionnaireId', null, global);
goog.exportSymbol('proto.satys.domain.Respondent', null, global);
goog.exportSymbol('proto.satys.domain.Response', null, global);
goog.exportSymbol('proto.satys.domain.Response.Metadata', null, global);
goog.exportSymbol('proto.satys.domain.Response.Metadata.ValueCase', null, global);
goog.exportSymbol('proto.satys.domain.ResponseQuestionnaireMap', null, global);
goog.exportSymbol('proto.satys.domain.ResponseQuestionnaireMap.Condition', null, global);
goog.exportSymbol('proto.satys.domain.ResponseQuestionnaireMap.Condition.ComparisonOperator', null, global);
goog.exportSymbol('proto.satys.domain.ResponseQuestionnaireMap.Type', null, global);
goog.exportSymbol('proto.satys.domain.Role', null, global);
goog.exportSymbol('proto.satys.domain.Task', null, global);
goog.exportSymbol('proto.satys.domain.Task.TaskType', null, global);
goog.exportSymbol('proto.satys.domain.TaskInstance', null, global);
goog.exportSymbol('proto.satys.domain.TaskTree', null, global);
goog.exportSymbol('proto.satys.domain.ThirdPartyOauth', null, global);
goog.exportSymbol('proto.satys.domain.ThirdPartyQuestion', null, global);
goog.exportSymbol('proto.satys.domain.ThirdPartyQuestionnaire', null, global);
goog.exportSymbol('proto.satys.domain.ThirdPartyResponse', null, global);
goog.exportSymbol('proto.satys.domain.UniversalQueryInterface', null, global);
goog.exportSymbol('proto.satys.domain.UniversalQueryInterface.DateRange', null, global);
goog.exportSymbol('proto.satys.domain.UniversalQueryInterface.QuestionFilter', null, global);
goog.exportSymbol('proto.satys.domain.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.User.displayName = 'proto.satys.domain.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Profile.displayName = 'proto.satys.domain.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Dashboard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Dashboard.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Dashboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Dashboard.displayName = 'proto.satys.domain.Dashboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Analysis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.satys.domain.Analysis.oneofGroups_);
};
goog.inherits(proto.satys.domain.Analysis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Analysis.displayName = 'proto.satys.domain.Analysis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Role.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Role.displayName = 'proto.satys.domain.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Action.displayName = 'proto.satys.domain.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Task.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Task.displayName = 'proto.satys.domain.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.TaskTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.TaskTree.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.TaskTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.TaskTree.displayName = 'proto.satys.domain.TaskTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.TaskInstance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.TaskInstance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.TaskInstance.displayName = 'proto.satys.domain.TaskInstance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Process = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Process.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Process, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Process.displayName = 'proto.satys.domain.Process';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ProcessTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.ProcessTree.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.ProcessTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ProcessTree.displayName = 'proto.satys.domain.ProcessTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ProcessInstance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.ProcessInstance.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.ProcessInstance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ProcessInstance.displayName = 'proto.satys.domain.ProcessInstance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ProcessIncident = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ProcessIncident, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ProcessIncident.displayName = 'proto.satys.domain.ProcessIncident';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ProcessElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ProcessElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ProcessElement.displayName = 'proto.satys.domain.ProcessElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Product.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Product.displayName = 'proto.satys.domain.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ProductTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.ProductTree.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.ProductTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ProductTree.displayName = 'proto.satys.domain.ProductTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.OrganisationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.OrganisationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.OrganisationId.displayName = 'proto.satys.domain.OrganisationId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Organisation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Organisation.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Organisation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Organisation.displayName = 'proto.satys.domain.Organisation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ExternalOrganisation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ExternalOrganisation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ExternalOrganisation.displayName = 'proto.satys.domain.ExternalOrganisation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.OrganisationWithDescendants = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.OrganisationWithDescendants.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.OrganisationWithDescendants, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.OrganisationWithDescendants.displayName = 'proto.satys.domain.OrganisationWithDescendants';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.OrganisationTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.OrganisationTree.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.OrganisationTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.OrganisationTree.displayName = 'proto.satys.domain.OrganisationTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ProbacTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ProbacTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ProbacTree.displayName = 'proto.satys.domain.ProbacTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.MeasurementId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.MeasurementId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.MeasurementId.displayName = 'proto.satys.domain.MeasurementId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Measurement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Measurement.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Measurement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Measurement.displayName = 'proto.satys.domain.Measurement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.QuestionnaireId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.QuestionnaireId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.QuestionnaireId.displayName = 'proto.satys.domain.QuestionnaireId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Questionnaire = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Questionnaire.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Questionnaire, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Questionnaire.displayName = 'proto.satys.domain.Questionnaire';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.QuestionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.QuestionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.QuestionId.displayName = 'proto.satys.domain.QuestionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Question.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Question.displayName = 'proto.satys.domain.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Response.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Response.displayName = 'proto.satys.domain.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Response.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.satys.domain.Response.Metadata.oneofGroups_);
};
goog.inherits(proto.satys.domain.Response.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Response.Metadata.displayName = 'proto.satys.domain.Response.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Answer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.Answer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Answer.displayName = 'proto.satys.domain.Answer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Respondent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.Respondent.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.Respondent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Respondent.displayName = 'proto.satys.domain.Respondent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Channel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.Channel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Channel.displayName = 'proto.satys.domain.Channel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ChannelPlanning = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ChannelPlanning, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ChannelPlanning.displayName = 'proto.satys.domain.ChannelPlanning';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ThirdPartyOauth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ThirdPartyOauth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ThirdPartyOauth.displayName = 'proto.satys.domain.ThirdPartyOauth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ThirdPartyQuestionnaire = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ThirdPartyQuestionnaire, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ThirdPartyQuestionnaire.displayName = 'proto.satys.domain.ThirdPartyQuestionnaire';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ThirdPartyQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ThirdPartyQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ThirdPartyQuestion.displayName = 'proto.satys.domain.ThirdPartyQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ThirdPartyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ThirdPartyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ThirdPartyResponse.displayName = 'proto.satys.domain.ThirdPartyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Integration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.Integration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Integration.displayName = 'proto.satys.domain.Integration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.Label.displayName = 'proto.satys.domain.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.UniversalQueryInterface = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.UniversalQueryInterface.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.UniversalQueryInterface, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.UniversalQueryInterface.displayName = 'proto.satys.domain.UniversalQueryInterface';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.UniversalQueryInterface.DateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.UniversalQueryInterface.DateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.UniversalQueryInterface.DateRange.displayName = 'proto.satys.domain.UniversalQueryInterface.DateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.UniversalQueryInterface.QuestionFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.UniversalQueryInterface.QuestionFilter.displayName = 'proto.satys.domain.UniversalQueryInterface.QuestionFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.PerformanceIndicator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.PerformanceIndicator.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.PerformanceIndicator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.PerformanceIndicator.displayName = 'proto.satys.domain.PerformanceIndicator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.PeriodReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.PeriodReport.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.PeriodReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.PeriodReport.displayName = 'proto.satys.domain.PeriodReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.PeriodReport.Topic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.PeriodReport.Topic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.PeriodReport.Topic.displayName = 'proto.satys.domain.PeriodReport.Topic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.PeriodReport.DateTimeRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.PeriodReport.DateTimeRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.PeriodReport.DateTimeRange.displayName = 'proto.satys.domain.PeriodReport.DateTimeRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.NotificationSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.NotificationSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.NotificationSetting.displayName = 'proto.satys.domain.NotificationSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.OverallMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.OverallMetric.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.OverallMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.OverallMetric.displayName = 'proto.satys.domain.OverallMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.PriorityMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.PriorityMetric.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.PriorityMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.PriorityMetric.displayName = 'proto.satys.domain.PriorityMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.CohortMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.CohortMetric.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.CohortMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.CohortMetric.displayName = 'proto.satys.domain.CohortMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ResponseQuestionnaireMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.domain.ResponseQuestionnaireMap.repeatedFields_, null);
};
goog.inherits(proto.satys.domain.ResponseQuestionnaireMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ResponseQuestionnaireMap.displayName = 'proto.satys.domain.ResponseQuestionnaireMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.domain.ResponseQuestionnaireMap.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.domain.ResponseQuestionnaireMap.Condition.displayName = 'proto.satys.domain.ResponseQuestionnaireMap.Condition';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.User.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.User}
 */
proto.satys.domain.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.User;
  return proto.satys.domain.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.User}
 */
proto.satys.domain.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email_address = 1;
 * @return {string}
 */
proto.satys.domain.User.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.User} returns this
 */
proto.satys.domain.User.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.satys.domain.User.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.User} returns this
 */
proto.satys.domain.User.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.satys.domain.User.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    telephoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    locale: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Profile}
 */
proto.satys.domain.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Profile;
  return proto.satys.domain.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Profile}
 */
proto.satys.domain.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.User;
      reader.readMessage(value,proto.satys.domain.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephoneNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.User.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTelephoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.domain.Profile.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.domain.Profile} returns this
*/
proto.satys.domain.Profile.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Profile} returns this
 */
proto.satys.domain.Profile.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Profile.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.satys.domain.Profile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Profile} returns this
 */
proto.satys.domain.Profile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.satys.domain.Profile.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Profile} returns this
 */
proto.satys.domain.Profile.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string telephone_number = 4;
 * @return {string}
 */
proto.satys.domain.Profile.prototype.getTelephoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Profile} returns this
 */
proto.satys.domain.Profile.prototype.setTelephoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string locale = 5;
 * @return {string}
 */
proto.satys.domain.Profile.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Profile} returns this
 */
proto.satys.domain.Profile.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Dashboard.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Dashboard.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Dashboard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Dashboard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Dashboard.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    google_protobuf_struct_pb.Struct.toObject, includeInstance),
    tinyCardsList: jspb.Message.toObjectList(msg.getTinyCardsList(),
    google_protobuf_struct_pb.Struct.toObject, includeInstance),
    layoutList: jspb.Message.toObjectList(msg.getLayoutList(),
    google_protobuf_struct_pb.Struct.toObject, includeInstance),
    isDemo: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Dashboard}
 */
proto.satys.domain.Dashboard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Dashboard;
  return proto.satys.domain.Dashboard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Dashboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Dashboard}
 */
proto.satys.domain.Dashboard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 3:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.addTinyCards(value);
      break;
    case 4:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.addLayout(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDemo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Dashboard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Dashboard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Dashboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Dashboard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getTinyCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLayoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getIsDemo();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.Dashboard.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Dashboard} returns this
 */
proto.satys.domain.Dashboard.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated google.protobuf.Struct filters = 2;
 * @return {!Array<!proto.google.protobuf.Struct>}
 */
proto.satys.domain.Dashboard.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.google.protobuf.Struct>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_struct_pb.Struct, 2));
};


/**
 * @param {!Array<!proto.google.protobuf.Struct>} value
 * @return {!proto.satys.domain.Dashboard} returns this
*/
proto.satys.domain.Dashboard.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.protobuf.Struct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Struct}
 */
proto.satys.domain.Dashboard.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.Struct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Dashboard} returns this
 */
proto.satys.domain.Dashboard.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * repeated google.protobuf.Struct tiny_cards = 3;
 * @return {!Array<!proto.google.protobuf.Struct>}
 */
proto.satys.domain.Dashboard.prototype.getTinyCardsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Struct>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_struct_pb.Struct, 3));
};


/**
 * @param {!Array<!proto.google.protobuf.Struct>} value
 * @return {!proto.satys.domain.Dashboard} returns this
*/
proto.satys.domain.Dashboard.prototype.setTinyCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.google.protobuf.Struct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Struct}
 */
proto.satys.domain.Dashboard.prototype.addTinyCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.google.protobuf.Struct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Dashboard} returns this
 */
proto.satys.domain.Dashboard.prototype.clearTinyCardsList = function() {
  return this.setTinyCardsList([]);
};


/**
 * repeated google.protobuf.Struct layout = 4;
 * @return {!Array<!proto.google.protobuf.Struct>}
 */
proto.satys.domain.Dashboard.prototype.getLayoutList = function() {
  return /** @type{!Array<!proto.google.protobuf.Struct>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_struct_pb.Struct, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.Struct>} value
 * @return {!proto.satys.domain.Dashboard} returns this
*/
proto.satys.domain.Dashboard.prototype.setLayoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.Struct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Struct}
 */
proto.satys.domain.Dashboard.prototype.addLayout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.Struct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Dashboard} returns this
 */
proto.satys.domain.Dashboard.prototype.clearLayoutList = function() {
  return this.setLayoutList([]);
};


/**
 * optional bool is_demo = 5;
 * @return {boolean}
 */
proto.satys.domain.Dashboard.prototype.getIsDemo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.domain.Dashboard} returns this
 */
proto.satys.domain.Dashboard.prototype.setIsDemo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.satys.domain.Analysis.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.satys.domain.Analysis.RelationCase = {
  RELATION_NOT_SET: 0,
  ORGANISATION: 1,
  MEASUREMENT: 2
};

/**
 * @return {proto.satys.domain.Analysis.RelationCase}
 */
proto.satys.domain.Analysis.prototype.getRelationCase = function() {
  return /** @type {proto.satys.domain.Analysis.RelationCase} */(jspb.Message.computeOneofCase(this, proto.satys.domain.Analysis.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Analysis.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Analysis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Analysis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Analysis.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    measurement: (f = msg.getMeasurement()) && proto.satys.domain.Measurement.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateFrom: (f = msg.getDateFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dateUntil: (f = msg.getDateUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Analysis}
 */
proto.satys.domain.Analysis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Analysis;
  return proto.satys.domain.Analysis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Analysis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Analysis}
 */
proto.satys.domain.Analysis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = new proto.satys.domain.Measurement;
      reader.readMessage(value,proto.satys.domain.Measurement.deserializeBinaryFromReader);
      msg.setMeasurement(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateFrom(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateUntil(value);
      break;
    case 8:
      var value = /** @type {!proto.satys.domain.Analysis.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Analysis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Analysis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Analysis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Analysis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getMeasurement();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateFrom();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDateUntil();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.Analysis.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_ADVICE: 1,
  TYPE_CONCLUSION: 2,
  TYPE_NOTE: 3,
  TYPE_COMMENT: 4
};

/**
 * optional Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.Analysis.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.Analysis} returns this
*/
proto.satys.domain.Analysis.prototype.setOrganisation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.satys.domain.Analysis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Analysis.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Measurement measurement = 2;
 * @return {?proto.satys.domain.Measurement}
 */
proto.satys.domain.Analysis.prototype.getMeasurement = function() {
  return /** @type{?proto.satys.domain.Measurement} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Measurement, 2));
};


/**
 * @param {?proto.satys.domain.Measurement|undefined} value
 * @return {!proto.satys.domain.Analysis} returns this
*/
proto.satys.domain.Analysis.prototype.setMeasurement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.satys.domain.Analysis.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.clearMeasurement = function() {
  return this.setMeasurement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Analysis.prototype.hasMeasurement = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.satys.domain.Analysis.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.satys.domain.Analysis.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Analysis.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Analysis} returns this
*/
proto.satys.domain.Analysis.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Analysis.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp date_from = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Analysis.prototype.getDateFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Analysis} returns this
*/
proto.satys.domain.Analysis.prototype.setDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.clearDateFrom = function() {
  return this.setDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Analysis.prototype.hasDateFrom = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp date_until = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Analysis.prototype.getDateUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Analysis} returns this
*/
proto.satys.domain.Analysis.prototype.setDateUntil = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.clearDateUntil = function() {
  return this.setDateUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Analysis.prototype.hasDateUntil = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Type type = 8;
 * @return {!proto.satys.domain.Analysis.Type}
 */
proto.satys.domain.Analysis.prototype.getType = function() {
  return /** @type {!proto.satys.domain.Analysis.Type} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.satys.domain.Analysis.Type} value
 * @return {!proto.satys.domain.Analysis} returns this
 */
proto.satys.domain.Analysis.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Role.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.satys.domain.Task.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Role}
 */
proto.satys.domain.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Role;
  return proto.satys.domain.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Role}
 */
proto.satys.domain.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 3:
      var value = new proto.satys.domain.Task;
      reader.readMessage(value,proto.satys.domain.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.domain.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.Role.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Role} returns this
 */
proto.satys.domain.Role.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Organisation organisation = 2;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.Role.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 2));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.Role} returns this
*/
proto.satys.domain.Role.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Role} returns this
 */
proto.satys.domain.Role.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Role.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Task tasks = 3;
 * @return {!Array<!proto.satys.domain.Task>}
 */
proto.satys.domain.Role.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.satys.domain.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Task, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Task>} value
 * @return {!proto.satys.domain.Role} returns this
*/
proto.satys.domain.Role.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Task}
 */
proto.satys.domain.Role.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Role} returns this
 */
proto.satys.domain.Role.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    task: (f = msg.getTask()) && proto.satys.domain.Task.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Action}
 */
proto.satys.domain.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Action;
  return proto.satys.domain.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Action}
 */
proto.satys.domain.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResource(value);
      break;
    case 2:
      var value = new proto.satys.domain.Task;
      reader.readMessage(value,proto.satys.domain.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional string resource = 1;
 * @return {string}
 */
proto.satys.domain.Action.prototype.getResource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Action} returns this
 */
proto.satys.domain.Action.prototype.setResource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Task task = 2;
 * @return {?proto.satys.domain.Task}
 */
proto.satys.domain.Action.prototype.getTask = function() {
  return /** @type{?proto.satys.domain.Task} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Task, 2));
};


/**
 * @param {?proto.satys.domain.Task|undefined} value
 * @return {!proto.satys.domain.Action} returns this
*/
proto.satys.domain.Action.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Action} returns this
 */
proto.satys.domain.Action.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Action.prototype.hasTask = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Task.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    process: (f = msg.getProcess()) && proto.satys.domain.Process.toObject(includeInstance, f),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.satys.domain.Action.toObject, includeInstance),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Task}
 */
proto.satys.domain.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Task;
  return proto.satys.domain.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Task}
 */
proto.satys.domain.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.satys.domain.Process;
      reader.readMessage(value,proto.satys.domain.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 3:
      var value = new proto.satys.domain.Action;
      reader.readMessage(value,proto.satys.domain.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.satys.domain.Task.TaskType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.Process.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.domain.Action.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.Task.TaskType = {
  TASK_TYPE_UNSPECIFIED: 0,
  TASK_TYPE_SERVICE: 1,
  TASK_TYPE_SEND: 2,
  TASK_TYPE_RECEIVE: 3,
  TASK_TYPE_USER: 4,
  TASK_TYPE_MANUAL: 5,
  TASK_TYPE_BUSINESS_RULE: 6,
  TASK_TYPE_SCRIPT: 7
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.Task.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Task} returns this
 */
proto.satys.domain.Task.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Process process = 2;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.domain.Task.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Process, 2));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.domain.Task} returns this
*/
proto.satys.domain.Task.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Task} returns this
 */
proto.satys.domain.Task.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Task.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Action actions = 3;
 * @return {!Array<!proto.satys.domain.Action>}
 */
proto.satys.domain.Task.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Action, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Action>} value
 * @return {!proto.satys.domain.Task} returns this
*/
proto.satys.domain.Task.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Action}
 */
proto.satys.domain.Task.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Task} returns this
 */
proto.satys.domain.Task.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.satys.domain.Task.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Task} returns this
 */
proto.satys.domain.Task.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional TaskType type = 5;
 * @return {!proto.satys.domain.Task.TaskType}
 */
proto.satys.domain.Task.prototype.getType = function() {
  return /** @type {!proto.satys.domain.Task.TaskType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.satys.domain.Task.TaskType} value
 * @return {!proto.satys.domain.Task} returns this
 */
proto.satys.domain.Task.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.TaskTree.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.TaskTree.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.TaskTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.TaskTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.TaskTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && proto.satys.domain.Task.toObject(includeInstance, f),
    actionTreesList: jspb.Message.toObjectList(msg.getActionTreesList(),
    proto.satys.domain.Action.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.TaskTree}
 */
proto.satys.domain.TaskTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.TaskTree;
  return proto.satys.domain.TaskTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.TaskTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.TaskTree}
 */
proto.satys.domain.TaskTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Task;
      reader.readMessage(value,proto.satys.domain.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 2:
      var value = new proto.satys.domain.Action;
      reader.readMessage(value,proto.satys.domain.Action.deserializeBinaryFromReader);
      msg.addActionTrees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.TaskTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.TaskTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.TaskTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.TaskTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Task.serializeBinaryToWriter
    );
  }
  f = message.getActionTreesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional Task task = 1;
 * @return {?proto.satys.domain.Task}
 */
proto.satys.domain.TaskTree.prototype.getTask = function() {
  return /** @type{?proto.satys.domain.Task} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Task, 1));
};


/**
 * @param {?proto.satys.domain.Task|undefined} value
 * @return {!proto.satys.domain.TaskTree} returns this
*/
proto.satys.domain.TaskTree.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.TaskTree} returns this
 */
proto.satys.domain.TaskTree.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.TaskTree.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Action action_trees = 2;
 * @return {!Array<!proto.satys.domain.Action>}
 */
proto.satys.domain.TaskTree.prototype.getActionTreesList = function() {
  return /** @type{!Array<!proto.satys.domain.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Action, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Action>} value
 * @return {!proto.satys.domain.TaskTree} returns this
*/
proto.satys.domain.TaskTree.prototype.setActionTreesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Action}
 */
proto.satys.domain.TaskTree.prototype.addActionTrees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.TaskTree} returns this
 */
proto.satys.domain.TaskTree.prototype.clearActionTreesList = function() {
  return this.setActionTreesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.TaskInstance.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.TaskInstance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.TaskInstance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.TaskInstance.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && proto.satys.domain.Task.toObject(includeInstance, f),
    jobKey: jspb.Message.getFieldWithDefault(msg, 2, 0),
    taskInstanceKey: jspb.Message.getFieldWithDefault(msg, 9, ""),
    processInstance: (f = msg.getProcessInstance()) && proto.satys.domain.ProcessInstance.toObject(includeInstance, f),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deadline: (f = msg.getDeadline()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    latestIntent: jspb.Message.getFieldWithDefault(msg, 6, ""),
    customHeaders: (f = msg.getCustomHeaders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    assignee: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.TaskInstance}
 */
proto.satys.domain.TaskInstance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.TaskInstance;
  return proto.satys.domain.TaskInstance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.TaskInstance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.TaskInstance}
 */
proto.satys.domain.TaskInstance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Task;
      reader.readMessage(value,proto.satys.domain.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobKey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskInstanceKey(value);
      break;
    case 3:
      var value = new proto.satys.domain.ProcessInstance;
      reader.readMessage(value,proto.satys.domain.ProcessInstance.deserializeBinaryFromReader);
      msg.setProcessInstance(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeadline(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestIntent(value);
      break;
    case 7:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCustomHeaders(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.TaskInstance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.TaskInstance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.TaskInstance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.TaskInstance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Task.serializeBinaryToWriter
    );
  }
  f = message.getJobKey();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTaskInstanceKey();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProcessInstance();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.ProcessInstance.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeadline();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLatestIntent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustomHeaders();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getAssignee();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional Task task = 1;
 * @return {?proto.satys.domain.Task}
 */
proto.satys.domain.TaskInstance.prototype.getTask = function() {
  return /** @type{?proto.satys.domain.Task} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Task, 1));
};


/**
 * @param {?proto.satys.domain.Task|undefined} value
 * @return {!proto.satys.domain.TaskInstance} returns this
*/
proto.satys.domain.TaskInstance.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.TaskInstance.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 job_key = 2;
 * @return {number}
 */
proto.satys.domain.TaskInstance.prototype.getJobKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.setJobKey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string task_instance_key = 9;
 * @return {string}
 */
proto.satys.domain.TaskInstance.prototype.getTaskInstanceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.setTaskInstanceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional ProcessInstance process_instance = 3;
 * @return {?proto.satys.domain.ProcessInstance}
 */
proto.satys.domain.TaskInstance.prototype.getProcessInstance = function() {
  return /** @type{?proto.satys.domain.ProcessInstance} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.ProcessInstance, 3));
};


/**
 * @param {?proto.satys.domain.ProcessInstance|undefined} value
 * @return {!proto.satys.domain.TaskInstance} returns this
*/
proto.satys.domain.TaskInstance.prototype.setProcessInstance = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.clearProcessInstance = function() {
  return this.setProcessInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.TaskInstance.prototype.hasProcessInstance = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp start = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.TaskInstance.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.TaskInstance} returns this
*/
proto.satys.domain.TaskInstance.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.TaskInstance.prototype.hasStart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp deadline = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.TaskInstance.prototype.getDeadline = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.TaskInstance} returns this
*/
proto.satys.domain.TaskInstance.prototype.setDeadline = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.clearDeadline = function() {
  return this.setDeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.TaskInstance.prototype.hasDeadline = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string latest_intent = 6;
 * @return {string}
 */
proto.satys.domain.TaskInstance.prototype.getLatestIntent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.setLatestIntent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Struct custom_headers = 7;
 * @return {?proto.google.protobuf.Struct}
 */
proto.satys.domain.TaskInstance.prototype.getCustomHeaders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 7));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.satys.domain.TaskInstance} returns this
*/
proto.satys.domain.TaskInstance.prototype.setCustomHeaders = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.clearCustomHeaders = function() {
  return this.setCustomHeaders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.TaskInstance.prototype.hasCustomHeaders = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string assignee = 8;
 * @return {string}
 */
proto.satys.domain.TaskInstance.prototype.getAssignee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.TaskInstance} returns this
 */
proto.satys.domain.TaskInstance.prototype.setAssignee = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Process.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Process.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Process.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Process} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Process.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    product: (f = msg.getProduct()) && proto.satys.domain.Product.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.satys.domain.Task.toObject, includeInstance),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    version: jspb.Message.getFieldWithDefault(msg, 5, 0),
    zeebeKey: jspb.Message.getFieldWithDefault(msg, 6, 0),
    bpmn: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Process}
 */
proto.satys.domain.Process.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Process;
  return proto.satys.domain.Process.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Process} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Process}
 */
proto.satys.domain.Process.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.satys.domain.Product;
      reader.readMessage(value,proto.satys.domain.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 3:
      var value = new proto.satys.domain.Task;
      reader.readMessage(value,proto.satys.domain.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZeebeKey(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBpmn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Process.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Process.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Process} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Process.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.Product.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.domain.Task.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getZeebeKey();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBpmn();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.Process.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Process} returns this
 */
proto.satys.domain.Process.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Product product = 2;
 * @return {?proto.satys.domain.Product}
 */
proto.satys.domain.Process.prototype.getProduct = function() {
  return /** @type{?proto.satys.domain.Product} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Product, 2));
};


/**
 * @param {?proto.satys.domain.Product|undefined} value
 * @return {!proto.satys.domain.Process} returns this
*/
proto.satys.domain.Process.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Process} returns this
 */
proto.satys.domain.Process.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Process.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Task tasks = 3;
 * @return {!Array<!proto.satys.domain.Task>}
 */
proto.satys.domain.Process.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.satys.domain.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Task, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Task>} value
 * @return {!proto.satys.domain.Process} returns this
*/
proto.satys.domain.Process.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Task}
 */
proto.satys.domain.Process.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Process} returns this
 */
proto.satys.domain.Process.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.satys.domain.Process.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Process} returns this
 */
proto.satys.domain.Process.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 version = 5;
 * @return {number}
 */
proto.satys.domain.Process.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Process} returns this
 */
proto.satys.domain.Process.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 zeebe_key = 6;
 * @return {number}
 */
proto.satys.domain.Process.prototype.getZeebeKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Process} returns this
 */
proto.satys.domain.Process.prototype.setZeebeKey = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string bpmn = 7;
 * @return {string}
 */
proto.satys.domain.Process.prototype.getBpmn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Process} returns this
 */
proto.satys.domain.Process.prototype.setBpmn = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.ProcessTree.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ProcessTree.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ProcessTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ProcessTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && proto.satys.domain.Process.toObject(includeInstance, f),
    taskTreesList: jspb.Message.toObjectList(msg.getTaskTreesList(),
    proto.satys.domain.TaskTree.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ProcessTree}
 */
proto.satys.domain.ProcessTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ProcessTree;
  return proto.satys.domain.ProcessTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ProcessTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ProcessTree}
 */
proto.satys.domain.ProcessTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Process;
      reader.readMessage(value,proto.satys.domain.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 2:
      var value = new proto.satys.domain.TaskTree;
      reader.readMessage(value,proto.satys.domain.TaskTree.deserializeBinaryFromReader);
      msg.addTaskTrees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ProcessTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ProcessTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ProcessTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Process.serializeBinaryToWriter
    );
  }
  f = message.getTaskTreesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.TaskTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.domain.ProcessTree.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.domain.ProcessTree} returns this
*/
proto.satys.domain.ProcessTree.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessTree} returns this
 */
proto.satys.domain.ProcessTree.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessTree.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TaskTree task_trees = 2;
 * @return {!Array<!proto.satys.domain.TaskTree>}
 */
proto.satys.domain.ProcessTree.prototype.getTaskTreesList = function() {
  return /** @type{!Array<!proto.satys.domain.TaskTree>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.TaskTree, 2));
};


/**
 * @param {!Array<!proto.satys.domain.TaskTree>} value
 * @return {!proto.satys.domain.ProcessTree} returns this
*/
proto.satys.domain.ProcessTree.prototype.setTaskTreesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.TaskTree=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.TaskTree}
 */
proto.satys.domain.ProcessTree.prototype.addTaskTrees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.TaskTree, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.ProcessTree} returns this
 */
proto.satys.domain.ProcessTree.prototype.clearTaskTreesList = function() {
  return this.setTaskTreesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.ProcessInstance.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ProcessInstance.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ProcessInstance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ProcessInstance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessInstance.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && proto.satys.domain.Process.toObject(includeInstance, f),
    workflowInstanceKey: jspb.Message.getFieldWithDefault(msg, 2, 0),
    processInstanceKey: jspb.Message.getFieldWithDefault(msg, 11, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    variables: (f = msg.getVariables()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.satys.domain.ProcessElement.toObject, includeInstance),
    incidentsList: jspb.Message.toObjectList(msg.getIncidentsList(),
    proto.satys.domain.ProcessIncident.toObject, includeInstance),
    parent: (f = msg.getParent()) && proto.satys.domain.ProcessInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ProcessInstance}
 */
proto.satys.domain.ProcessInstance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ProcessInstance;
  return proto.satys.domain.ProcessInstance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ProcessInstance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ProcessInstance}
 */
proto.satys.domain.ProcessInstance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Process;
      reader.readMessage(value,proto.satys.domain.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkflowInstanceKey(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceKey(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 7:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setVariables(value);
      break;
    case 8:
      var value = new proto.satys.domain.ProcessElement;
      reader.readMessage(value,proto.satys.domain.ProcessElement.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    case 9:
      var value = new proto.satys.domain.ProcessIncident;
      reader.readMessage(value,proto.satys.domain.ProcessIncident.deserializeBinaryFromReader);
      msg.addIncidents(value);
      break;
    case 10:
      var value = new proto.satys.domain.ProcessInstance;
      reader.readMessage(value,proto.satys.domain.ProcessInstance.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ProcessInstance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ProcessInstance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ProcessInstance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessInstance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Process.serializeBinaryToWriter
    );
  }
  f = message.getWorkflowInstanceKey();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProcessInstanceKey();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVariables();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.satys.domain.ProcessElement.serializeBinaryToWriter
    );
  }
  f = message.getIncidentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.satys.domain.ProcessIncident.serializeBinaryToWriter
    );
  }
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.satys.domain.ProcessInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.domain.ProcessInstance.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
*/
proto.satys.domain.ProcessInstance.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessInstance.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 workflow_instance_key = 2;
 * @return {number}
 */
proto.satys.domain.ProcessInstance.prototype.getWorkflowInstanceKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.setWorkflowInstanceKey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string process_instance_key = 11;
 * @return {string}
 */
proto.satys.domain.ProcessInstance.prototype.getProcessInstanceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.setProcessInstanceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp start = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ProcessInstance.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
*/
proto.satys.domain.ProcessInstance.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessInstance.prototype.hasStart = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ProcessInstance.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
*/
proto.satys.domain.ProcessInstance.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessInstance.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Struct variables = 7;
 * @return {?proto.google.protobuf.Struct}
 */
proto.satys.domain.ProcessInstance.prototype.getVariables = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 7));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
*/
proto.satys.domain.ProcessInstance.prototype.setVariables = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.clearVariables = function() {
  return this.setVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessInstance.prototype.hasVariables = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated ProcessElement elements = 8;
 * @return {!Array<!proto.satys.domain.ProcessElement>}
 */
proto.satys.domain.ProcessInstance.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.satys.domain.ProcessElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.ProcessElement, 8));
};


/**
 * @param {!Array<!proto.satys.domain.ProcessElement>} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
*/
proto.satys.domain.ProcessInstance.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.satys.domain.ProcessElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.ProcessElement}
 */
proto.satys.domain.ProcessInstance.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.satys.domain.ProcessElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};


/**
 * repeated ProcessIncident incidents = 9;
 * @return {!Array<!proto.satys.domain.ProcessIncident>}
 */
proto.satys.domain.ProcessInstance.prototype.getIncidentsList = function() {
  return /** @type{!Array<!proto.satys.domain.ProcessIncident>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.ProcessIncident, 9));
};


/**
 * @param {!Array<!proto.satys.domain.ProcessIncident>} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
*/
proto.satys.domain.ProcessInstance.prototype.setIncidentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.satys.domain.ProcessIncident=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.ProcessIncident}
 */
proto.satys.domain.ProcessInstance.prototype.addIncidents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.satys.domain.ProcessIncident, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.clearIncidentsList = function() {
  return this.setIncidentsList([]);
};


/**
 * optional ProcessInstance parent = 10;
 * @return {?proto.satys.domain.ProcessInstance}
 */
proto.satys.domain.ProcessInstance.prototype.getParent = function() {
  return /** @type{?proto.satys.domain.ProcessInstance} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.ProcessInstance, 10));
};


/**
 * @param {?proto.satys.domain.ProcessInstance|undefined} value
 * @return {!proto.satys.domain.ProcessInstance} returns this
*/
proto.satys.domain.ProcessInstance.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessInstance} returns this
 */
proto.satys.domain.ProcessInstance.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessInstance.prototype.hasParent = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ProcessIncident.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ProcessIncident.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ProcessIncident} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessIncident.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    raisedAt: (f = msg.getRaisedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    resolvedAt: (f = msg.getResolvedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ProcessIncident}
 */
proto.satys.domain.ProcessIncident.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ProcessIncident;
  return proto.satys.domain.ProcessIncident.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ProcessIncident} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ProcessIncident}
 */
proto.satys.domain.ProcessIncident.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRaisedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResolvedAt(value);
      break;
    case 5:
      var value = /** @type {!proto.satys.domain.ProcessIncident.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ProcessIncident.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ProcessIncident.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ProcessIncident} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessIncident.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRaisedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getResolvedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.ProcessIncident.Status = {
  STATUS_UNSPECIFIED: 0,
  STATUS_ACTIVE: 1,
  STATUS_RESOLVED: 2
};

/**
 * optional int64 key = 1;
 * @return {number}
 */
proto.satys.domain.ProcessIncident.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.ProcessIncident} returns this
 */
proto.satys.domain.ProcessIncident.prototype.setKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.satys.domain.ProcessIncident.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ProcessIncident} returns this
 */
proto.satys.domain.ProcessIncident.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp raised_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ProcessIncident.prototype.getRaisedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ProcessIncident} returns this
*/
proto.satys.domain.ProcessIncident.prototype.setRaisedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessIncident} returns this
 */
proto.satys.domain.ProcessIncident.prototype.clearRaisedAt = function() {
  return this.setRaisedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessIncident.prototype.hasRaisedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp resolved_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ProcessIncident.prototype.getResolvedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ProcessIncident} returns this
*/
proto.satys.domain.ProcessIncident.prototype.setResolvedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessIncident} returns this
 */
proto.satys.domain.ProcessIncident.prototype.clearResolvedAt = function() {
  return this.setResolvedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessIncident.prototype.hasResolvedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Status status = 5;
 * @return {!proto.satys.domain.ProcessIncident.Status}
 */
proto.satys.domain.ProcessIncident.prototype.getStatus = function() {
  return /** @type {!proto.satys.domain.ProcessIncident.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.satys.domain.ProcessIncident.Status} value
 * @return {!proto.satys.domain.ProcessIncident} returns this
 */
proto.satys.domain.ProcessIncident.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ProcessElement.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ProcessElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ProcessElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    taskInstance: (f = msg.getTaskInstance()) && proto.satys.domain.TaskInstance.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ProcessElement}
 */
proto.satys.domain.ProcessElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ProcessElement;
  return proto.satys.domain.ProcessElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ProcessElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ProcessElement}
 */
proto.satys.domain.ProcessElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {!proto.satys.domain.ProcessElement.ElementType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.satys.domain.TaskInstance;
      reader.readMessage(value,proto.satys.domain.TaskInstance.deserializeBinaryFromReader);
      msg.setTaskInstance(value);
      break;
    case 5:
      var value = /** @type {!proto.satys.domain.ProcessElement.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ProcessElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ProcessElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ProcessElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProcessElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaskInstance();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.satys.domain.TaskInstance.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.ProcessElement.Status = {
  STATUS_UNSPECIFIED: 0,
  STATUS_ACTIVE: 1,
  STATUS_COMPLETED: 2,
  STATUS_ABORTED: 3
};

/**
 * @enum {number}
 */
proto.satys.domain.ProcessElement.ElementType = {
  ELEMENT_TYPE_UNSPECIFIED: 0,
  ELEMENT_TYPE_PROCESS: 1,
  ELEMENT_TYPE_ACTIVITY: 2,
  ELEMENT_TYPE_SEQUENCE_FLOW: 3,
  ELEMENT_TYPE_GATEWAY: 4,
  ELEMENT_TYPE_EVENT: 5
};

/**
 * optional int64 key = 1;
 * @return {number}
 */
proto.satys.domain.ProcessElement.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.ProcessElement} returns this
 */
proto.satys.domain.ProcessElement.prototype.setKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ElementType type = 2;
 * @return {!proto.satys.domain.ProcessElement.ElementType}
 */
proto.satys.domain.ProcessElement.prototype.getType = function() {
  return /** @type {!proto.satys.domain.ProcessElement.ElementType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.satys.domain.ProcessElement.ElementType} value
 * @return {!proto.satys.domain.ProcessElement} returns this
 */
proto.satys.domain.ProcessElement.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.satys.domain.ProcessElement.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ProcessElement} returns this
 */
proto.satys.domain.ProcessElement.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TaskInstance task_instance = 4;
 * @return {?proto.satys.domain.TaskInstance}
 */
proto.satys.domain.ProcessElement.prototype.getTaskInstance = function() {
  return /** @type{?proto.satys.domain.TaskInstance} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.TaskInstance, 4));
};


/**
 * @param {?proto.satys.domain.TaskInstance|undefined} value
 * @return {!proto.satys.domain.ProcessElement} returns this
*/
proto.satys.domain.ProcessElement.prototype.setTaskInstance = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessElement} returns this
 */
proto.satys.domain.ProcessElement.prototype.clearTaskInstance = function() {
  return this.setTaskInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessElement.prototype.hasTaskInstance = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Status status = 5;
 * @return {!proto.satys.domain.ProcessElement.Status}
 */
proto.satys.domain.ProcessElement.prototype.getStatus = function() {
  return /** @type {!proto.satys.domain.ProcessElement.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.satys.domain.ProcessElement.Status} value
 * @return {!proto.satys.domain.ProcessElement} returns this
 */
proto.satys.domain.ProcessElement.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp start = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ProcessElement.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ProcessElement} returns this
*/
proto.satys.domain.ProcessElement.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessElement} returns this
 */
proto.satys.domain.ProcessElement.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessElement.prototype.hasStart = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp end = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ProcessElement.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ProcessElement} returns this
*/
proto.satys.domain.ProcessElement.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProcessElement} returns this
 */
proto.satys.domain.ProcessElement.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProcessElement.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Product.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    processesList: jspb.Message.toObjectList(msg.getProcessesList(),
    proto.satys.domain.Process.toObject, includeInstance),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Product}
 */
proto.satys.domain.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Product;
  return proto.satys.domain.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Product}
 */
proto.satys.domain.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 3:
      var value = new proto.satys.domain.Process;
      reader.readMessage(value,proto.satys.domain.Process.deserializeBinaryFromReader);
      msg.addProcesses(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getProcessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.domain.Process.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Product} returns this
 */
proto.satys.domain.Product.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Organisation organisation = 2;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.Product.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 2));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.Product} returns this
*/
proto.satys.domain.Product.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Product} returns this
 */
proto.satys.domain.Product.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Product.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Process processes = 3;
 * @return {!Array<!proto.satys.domain.Process>}
 */
proto.satys.domain.Product.prototype.getProcessesList = function() {
  return /** @type{!Array<!proto.satys.domain.Process>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Process, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Process>} value
 * @return {!proto.satys.domain.Product} returns this
*/
proto.satys.domain.Product.prototype.setProcessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Process=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Process}
 */
proto.satys.domain.Product.prototype.addProcesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Process, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Product} returns this
 */
proto.satys.domain.Product.prototype.clearProcessesList = function() {
  return this.setProcessesList([]);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.satys.domain.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Product} returns this
 */
proto.satys.domain.Product.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.ProductTree.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ProductTree.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ProductTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ProductTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProductTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && proto.satys.domain.Product.toObject(includeInstance, f),
    processTreesList: jspb.Message.toObjectList(msg.getProcessTreesList(),
    proto.satys.domain.ProcessTree.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ProductTree}
 */
proto.satys.domain.ProductTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ProductTree;
  return proto.satys.domain.ProductTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ProductTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ProductTree}
 */
proto.satys.domain.ProductTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Product;
      reader.readMessage(value,proto.satys.domain.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 2:
      var value = new proto.satys.domain.ProcessTree;
      reader.readMessage(value,proto.satys.domain.ProcessTree.deserializeBinaryFromReader);
      msg.addProcessTrees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ProductTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ProductTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ProductTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProductTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Product.serializeBinaryToWriter
    );
  }
  f = message.getProcessTreesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.ProcessTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional Product product = 1;
 * @return {?proto.satys.domain.Product}
 */
proto.satys.domain.ProductTree.prototype.getProduct = function() {
  return /** @type{?proto.satys.domain.Product} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Product, 1));
};


/**
 * @param {?proto.satys.domain.Product|undefined} value
 * @return {!proto.satys.domain.ProductTree} returns this
*/
proto.satys.domain.ProductTree.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProductTree} returns this
 */
proto.satys.domain.ProductTree.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProductTree.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProcessTree process_trees = 2;
 * @return {!Array<!proto.satys.domain.ProcessTree>}
 */
proto.satys.domain.ProductTree.prototype.getProcessTreesList = function() {
  return /** @type{!Array<!proto.satys.domain.ProcessTree>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.ProcessTree, 2));
};


/**
 * @param {!Array<!proto.satys.domain.ProcessTree>} value
 * @return {!proto.satys.domain.ProductTree} returns this
*/
proto.satys.domain.ProductTree.prototype.setProcessTreesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.ProcessTree=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.ProcessTree}
 */
proto.satys.domain.ProductTree.prototype.addProcessTrees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.ProcessTree, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.ProductTree} returns this
 */
proto.satys.domain.ProductTree.prototype.clearProcessTreesList = function() {
  return this.setProcessTreesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.OrganisationId.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.OrganisationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.OrganisationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OrganisationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.OrganisationId}
 */
proto.satys.domain.OrganisationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.OrganisationId;
  return proto.satys.domain.OrganisationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.OrganisationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.OrganisationId}
 */
proto.satys.domain.OrganisationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.OrganisationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.OrganisationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.OrganisationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OrganisationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.satys.domain.OrganisationId.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.OrganisationId} returns this
 */
proto.satys.domain.OrganisationId.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Organisation.repeatedFields_ = [3,8,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Organisation.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Organisation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Organisation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Organisation.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.satys.domain.Product.toObject, includeInstance),
    domain: jspb.Message.getFieldWithDefault(msg, 5, ""),
    parent: (f = msg.getParent()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    reference: jspb.Message.getFieldWithDefault(msg, 7, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.satys.domain.Label.toObject, includeInstance),
    kpiScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    scale: jspb.Message.getFieldWithDefault(msg, 10, 0),
    kpi: (f = msg.getKpi()) && proto.satys.domain.PerformanceIndicator.toObject(includeInstance, f),
    performanceIndicatorsList: jspb.Message.toObjectList(msg.getPerformanceIndicatorsList(),
    proto.satys.domain.PerformanceIndicator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.domain.Organisation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Organisation;
  return proto.satys.domain.Organisation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Organisation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.domain.Organisation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 3:
      var value = new proto.satys.domain.Product;
      reader.readMessage(value,proto.satys.domain.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 6:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 8:
      var value = new proto.satys.domain.Label;
      reader.readMessage(value,proto.satys.domain.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setKpiScore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScale(value);
      break;
    case 11:
      var value = new proto.satys.domain.PerformanceIndicator;
      reader.readMessage(value,proto.satys.domain.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setKpi(value);
      break;
    case 12:
      var value = new proto.satys.domain.PerformanceIndicator;
      reader.readMessage(value,proto.satys.domain.PerformanceIndicator.deserializeBinaryFromReader);
      msg.addPerformanceIndicators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Organisation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Organisation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Organisation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Organisation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.domain.Product.serializeBinaryToWriter
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.satys.domain.Label.serializeBinaryToWriter
    );
  }
  f = message.getKpiScore();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getScale();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getKpi();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.satys.domain.PerformanceIndicator.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.satys.domain.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.Organisation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email_address = 2;
 * @return {string}
 */
proto.satys.domain.Organisation.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Product products = 3;
 * @return {!Array<!proto.satys.domain.Product>}
 */
proto.satys.domain.Organisation.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.satys.domain.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Product, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Product>} value
 * @return {!proto.satys.domain.Organisation} returns this
*/
proto.satys.domain.Organisation.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Product}
 */
proto.satys.domain.Organisation.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional string domain = 5;
 * @return {string}
 */
proto.satys.domain.Organisation.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Organisation parent = 6;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.Organisation.prototype.getParent = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 6));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.Organisation} returns this
*/
proto.satys.domain.Organisation.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Organisation.prototype.hasParent = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string reference = 7;
 * @return {string}
 */
proto.satys.domain.Organisation.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated Label labels = 8;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.domain.Organisation.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Label, 8));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.domain.Organisation} returns this
*/
proto.satys.domain.Organisation.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.domain.Organisation.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional float kpi_score = 9;
 * @return {number}
 */
proto.satys.domain.Organisation.prototype.getKpiScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.setKpiScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int32 scale = 10;
 * @return {number}
 */
proto.satys.domain.Organisation.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.setScale = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional PerformanceIndicator kpi = 11;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.domain.Organisation.prototype.getKpi = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.PerformanceIndicator, 11));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.domain.Organisation} returns this
*/
proto.satys.domain.Organisation.prototype.setKpi = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.clearKpi = function() {
  return this.setKpi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Organisation.prototype.hasKpi = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated PerformanceIndicator performance_indicators = 12;
 * @return {!Array<!proto.satys.domain.PerformanceIndicator>}
 */
proto.satys.domain.Organisation.prototype.getPerformanceIndicatorsList = function() {
  return /** @type{!Array<!proto.satys.domain.PerformanceIndicator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.PerformanceIndicator, 12));
};


/**
 * @param {!Array<!proto.satys.domain.PerformanceIndicator>} value
 * @return {!proto.satys.domain.Organisation} returns this
*/
proto.satys.domain.Organisation.prototype.setPerformanceIndicatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.satys.domain.PerformanceIndicator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.PerformanceIndicator}
 */
proto.satys.domain.Organisation.prototype.addPerformanceIndicators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.satys.domain.PerformanceIndicator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Organisation} returns this
 */
proto.satys.domain.Organisation.prototype.clearPerformanceIndicatorsList = function() {
  return this.setPerformanceIndicatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ExternalOrganisation.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ExternalOrganisation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ExternalOrganisation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ExternalOrganisation.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalDomain: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ExternalOrganisation}
 */
proto.satys.domain.ExternalOrganisation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ExternalOrganisation;
  return proto.satys.domain.ExternalOrganisation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ExternalOrganisation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ExternalOrganisation}
 */
proto.satys.domain.ExternalOrganisation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalRef(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ExternalOrganisation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ExternalOrganisation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ExternalOrganisation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ExternalOrganisation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string external_ref = 1;
 * @return {string}
 */
proto.satys.domain.ExternalOrganisation.prototype.getExternalRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ExternalOrganisation} returns this
 */
proto.satys.domain.ExternalOrganisation.prototype.setExternalRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_domain = 2;
 * @return {string}
 */
proto.satys.domain.ExternalOrganisation.prototype.getExternalDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ExternalOrganisation} returns this
 */
proto.satys.domain.ExternalOrganisation.prototype.setExternalDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.OrganisationWithDescendants.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.OrganisationWithDescendants.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.OrganisationWithDescendants.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.OrganisationWithDescendants} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OrganisationWithDescendants.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    descendantsList: jspb.Message.toObjectList(msg.getDescendantsList(),
    proto.satys.domain.OrganisationWithDescendants.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.OrganisationWithDescendants}
 */
proto.satys.domain.OrganisationWithDescendants.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.OrganisationWithDescendants;
  return proto.satys.domain.OrganisationWithDescendants.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.OrganisationWithDescendants} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.OrganisationWithDescendants}
 */
proto.satys.domain.OrganisationWithDescendants.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = new proto.satys.domain.OrganisationWithDescendants;
      reader.readMessage(value,proto.satys.domain.OrganisationWithDescendants.deserializeBinaryFromReader);
      msg.addDescendants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.OrganisationWithDescendants.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.OrganisationWithDescendants.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.OrganisationWithDescendants} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OrganisationWithDescendants.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getDescendantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.OrganisationWithDescendants.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.OrganisationWithDescendants.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.OrganisationWithDescendants} returns this
*/
proto.satys.domain.OrganisationWithDescendants.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.OrganisationWithDescendants} returns this
 */
proto.satys.domain.OrganisationWithDescendants.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.OrganisationWithDescendants.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OrganisationWithDescendants descendants = 2;
 * @return {!Array<!proto.satys.domain.OrganisationWithDescendants>}
 */
proto.satys.domain.OrganisationWithDescendants.prototype.getDescendantsList = function() {
  return /** @type{!Array<!proto.satys.domain.OrganisationWithDescendants>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.OrganisationWithDescendants, 2));
};


/**
 * @param {!Array<!proto.satys.domain.OrganisationWithDescendants>} value
 * @return {!proto.satys.domain.OrganisationWithDescendants} returns this
*/
proto.satys.domain.OrganisationWithDescendants.prototype.setDescendantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.OrganisationWithDescendants=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.OrganisationWithDescendants}
 */
proto.satys.domain.OrganisationWithDescendants.prototype.addDescendants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.OrganisationWithDescendants, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.OrganisationWithDescendants} returns this
 */
proto.satys.domain.OrganisationWithDescendants.prototype.clearDescendantsList = function() {
  return this.setDescendantsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.OrganisationTree.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.OrganisationTree.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.OrganisationTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.OrganisationTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OrganisationTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    productTreesList: jspb.Message.toObjectList(msg.getProductTreesList(),
    proto.satys.domain.ProductTree.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.OrganisationTree}
 */
proto.satys.domain.OrganisationTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.OrganisationTree;
  return proto.satys.domain.OrganisationTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.OrganisationTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.OrganisationTree}
 */
proto.satys.domain.OrganisationTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = new proto.satys.domain.ProductTree;
      reader.readMessage(value,proto.satys.domain.ProductTree.deserializeBinaryFromReader);
      msg.addProductTrees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.OrganisationTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.OrganisationTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.OrganisationTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OrganisationTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getProductTreesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.ProductTree.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.OrganisationTree.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.OrganisationTree} returns this
*/
proto.satys.domain.OrganisationTree.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.OrganisationTree} returns this
 */
proto.satys.domain.OrganisationTree.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.OrganisationTree.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProductTree product_trees = 2;
 * @return {!Array<!proto.satys.domain.ProductTree>}
 */
proto.satys.domain.OrganisationTree.prototype.getProductTreesList = function() {
  return /** @type{!Array<!proto.satys.domain.ProductTree>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.ProductTree, 2));
};


/**
 * @param {!Array<!proto.satys.domain.ProductTree>} value
 * @return {!proto.satys.domain.OrganisationTree} returns this
*/
proto.satys.domain.OrganisationTree.prototype.setProductTreesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.ProductTree=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.ProductTree}
 */
proto.satys.domain.OrganisationTree.prototype.addProductTrees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.ProductTree, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.OrganisationTree} returns this
 */
proto.satys.domain.OrganisationTree.prototype.clearProductTreesList = function() {
  return this.setProductTreesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ProbacTree.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ProbacTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ProbacTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProbacTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ProbacTree}
 */
proto.satys.domain.ProbacTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ProbacTree;
  return proto.satys.domain.ProbacTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ProbacTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ProbacTree}
 */
proto.satys.domain.ProbacTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ProbacTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ProbacTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ProbacTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ProbacTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.ProbacTree.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.ProbacTree} returns this
*/
proto.satys.domain.ProbacTree.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ProbacTree} returns this
 */
proto.satys.domain.ProbacTree.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ProbacTree.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.MeasurementId.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.MeasurementId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.MeasurementId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.MeasurementId.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organisationId: (f = msg.getOrganisationId()) && proto.satys.domain.OrganisationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.MeasurementId}
 */
proto.satys.domain.MeasurementId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.MeasurementId;
  return proto.satys.domain.MeasurementId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.MeasurementId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.MeasurementId}
 */
proto.satys.domain.MeasurementId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.satys.domain.OrganisationId;
      reader.readMessage(value,proto.satys.domain.OrganisationId.deserializeBinaryFromReader);
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.MeasurementId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.MeasurementId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.MeasurementId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.MeasurementId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganisationId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.OrganisationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.MeasurementId.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.MeasurementId} returns this
 */
proto.satys.domain.MeasurementId.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrganisationId organisation_id = 2;
 * @return {?proto.satys.domain.OrganisationId}
 */
proto.satys.domain.MeasurementId.prototype.getOrganisationId = function() {
  return /** @type{?proto.satys.domain.OrganisationId} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.OrganisationId, 2));
};


/**
 * @param {?proto.satys.domain.OrganisationId|undefined} value
 * @return {!proto.satys.domain.MeasurementId} returns this
*/
proto.satys.domain.MeasurementId.prototype.setOrganisationId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.MeasurementId} returns this
 */
proto.satys.domain.MeasurementId.prototype.clearOrganisationId = function() {
  return this.setOrganisationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.MeasurementId.prototype.hasOrganisationId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Measurement.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Measurement.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Measurement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Measurement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Measurement.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    proto.satys.domain.Questionnaire.toObject, includeInstance),
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.satys.domain.Label.toObject, includeInstance),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.domain.Measurement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Measurement;
  return proto.satys.domain.Measurement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Measurement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.domain.Measurement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var value = new proto.satys.domain.Questionnaire;
      reader.readMessage(value,proto.satys.domain.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 5:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = new proto.satys.domain.Label;
      reader.readMessage(value,proto.satys.domain.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Measurement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Measurement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Measurement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Measurement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.satys.domain.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.satys.domain.Label.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.satys.domain.Measurement.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Measurement.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Measurement} returns this
*/
proto.satys.domain.Measurement.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Measurement.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Measurement.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Measurement} returns this
*/
proto.satys.domain.Measurement.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Measurement.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Questionnaire questionnaires = 4;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.domain.Measurement.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Questionnaire, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.domain.Measurement} returns this
*/
proto.satys.domain.Measurement.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.domain.Measurement.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * optional Organisation organisation = 5;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.Measurement.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 5));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.Measurement} returns this
*/
proto.satys.domain.Measurement.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Measurement.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.satys.domain.Measurement.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Label labels = 7;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.domain.Measurement.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Label, 7));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.domain.Measurement} returns this
*/
proto.satys.domain.Measurement.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.domain.Measurement.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional bool is_active = 8;
 * @return {boolean}
 */
proto.satys.domain.Measurement.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.domain.Measurement} returns this
 */
proto.satys.domain.Measurement.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.QuestionnaireId.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.QuestionnaireId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.QuestionnaireId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.QuestionnaireId.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    measurementId: (f = msg.getMeasurementId()) && proto.satys.domain.MeasurementId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.QuestionnaireId}
 */
proto.satys.domain.QuestionnaireId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.QuestionnaireId;
  return proto.satys.domain.QuestionnaireId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.QuestionnaireId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.QuestionnaireId}
 */
proto.satys.domain.QuestionnaireId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new proto.satys.domain.MeasurementId;
      reader.readMessage(value,proto.satys.domain.MeasurementId.deserializeBinaryFromReader);
      msg.setMeasurementId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.QuestionnaireId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.QuestionnaireId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.QuestionnaireId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.QuestionnaireId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeasurementId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.MeasurementId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.satys.domain.QuestionnaireId.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.QuestionnaireId} returns this
 */
proto.satys.domain.QuestionnaireId.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MeasurementId measurement_id = 2;
 * @return {?proto.satys.domain.MeasurementId}
 */
proto.satys.domain.QuestionnaireId.prototype.getMeasurementId = function() {
  return /** @type{?proto.satys.domain.MeasurementId} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.MeasurementId, 2));
};


/**
 * @param {?proto.satys.domain.MeasurementId|undefined} value
 * @return {!proto.satys.domain.QuestionnaireId} returns this
*/
proto.satys.domain.QuestionnaireId.prototype.setMeasurementId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.QuestionnaireId} returns this
 */
proto.satys.domain.QuestionnaireId.prototype.clearMeasurementId = function() {
  return this.setMeasurementId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.QuestionnaireId.prototype.hasMeasurementId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Questionnaire.repeatedFields_ = [8,9,10,12,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Questionnaire.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Questionnaire.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Questionnaire} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Questionnaire.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastModified: (f = msg.getLastModified()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sent: (f = msg.getSent()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    process: jspb.Message.getFieldWithDefault(msg, 6, ""),
    measurement: (f = msg.getMeasurement()) && proto.satys.domain.Measurement.toObject(includeInstance, f),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.satys.domain.Question.toObject, includeInstance),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    proto.satys.domain.Response.toObject, includeInstance),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.satys.domain.Label.toObject, includeInstance),
    thirdPartyQuestionnaire: (f = msg.getThirdPartyQuestionnaire()) && proto.satys.domain.ThirdPartyQuestionnaire.toObject(includeInstance, f),
    overallMetricsList: jspb.Message.toObjectList(msg.getOverallMetricsList(),
    proto.satys.domain.OverallMetric.toObject, includeInstance),
    priorityMetricsList: jspb.Message.toObjectList(msg.getPriorityMetricsList(),
    proto.satys.domain.PriorityMetric.toObject, includeInstance),
    cohortMetricsList: jspb.Message.toObjectList(msg.getCohortMetricsList(),
    proto.satys.domain.CohortMetric.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.domain.Questionnaire.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Questionnaire;
  return proto.satys.domain.Questionnaire.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Questionnaire} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.domain.Questionnaire.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModified(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcess(value);
      break;
    case 7:
      var value = new proto.satys.domain.Measurement;
      reader.readMessage(value,proto.satys.domain.Measurement.deserializeBinaryFromReader);
      msg.setMeasurement(value);
      break;
    case 8:
      var value = new proto.satys.domain.Question;
      reader.readMessage(value,proto.satys.domain.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 9:
      var value = new proto.satys.domain.Response;
      reader.readMessage(value,proto.satys.domain.Response.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    case 10:
      var value = new proto.satys.domain.Label;
      reader.readMessage(value,proto.satys.domain.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 11:
      var value = new proto.satys.domain.ThirdPartyQuestionnaire;
      reader.readMessage(value,proto.satys.domain.ThirdPartyQuestionnaire.deserializeBinaryFromReader);
      msg.setThirdPartyQuestionnaire(value);
      break;
    case 12:
      var value = new proto.satys.domain.OverallMetric;
      reader.readMessage(value,proto.satys.domain.OverallMetric.deserializeBinaryFromReader);
      msg.addOverallMetrics(value);
      break;
    case 13:
      var value = new proto.satys.domain.PriorityMetric;
      reader.readMessage(value,proto.satys.domain.PriorityMetric.deserializeBinaryFromReader);
      msg.addPriorityMetrics(value);
      break;
    case 14:
      var value = new proto.satys.domain.CohortMetric;
      reader.readMessage(value,proto.satys.domain.CohortMetric.deserializeBinaryFromReader);
      msg.addCohortMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Questionnaire.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Questionnaire.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Questionnaire} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Questionnaire.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastModified();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProcess();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMeasurement();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.satys.domain.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.satys.domain.Question.serializeBinaryToWriter
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.satys.domain.Response.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.satys.domain.Label.serializeBinaryToWriter
    );
  }
  f = message.getThirdPartyQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.satys.domain.ThirdPartyQuestionnaire.serializeBinaryToWriter
    );
  }
  f = message.getOverallMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.satys.domain.OverallMetric.serializeBinaryToWriter
    );
  }
  f = message.getPriorityMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.satys.domain.PriorityMetric.serializeBinaryToWriter
    );
  }
  f = message.getCohortMetricsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.satys.domain.CohortMetric.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Questionnaire.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Questionnaire.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp last_modified = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Questionnaire.prototype.getLastModified = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setLastModified = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearLastModified = function() {
  return this.setLastModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Questionnaire.prototype.hasLastModified = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp sent = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Questionnaire.prototype.getSent = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setSent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearSent = function() {
  return this.setSent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Questionnaire.prototype.hasSent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.satys.domain.Questionnaire.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.satys.domain.Questionnaire.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string process = 6;
 * @return {string}
 */
proto.satys.domain.Questionnaire.prototype.getProcess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.setProcess = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Measurement measurement = 7;
 * @return {?proto.satys.domain.Measurement}
 */
proto.satys.domain.Questionnaire.prototype.getMeasurement = function() {
  return /** @type{?proto.satys.domain.Measurement} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Measurement, 7));
};


/**
 * @param {?proto.satys.domain.Measurement|undefined} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setMeasurement = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearMeasurement = function() {
  return this.setMeasurement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Questionnaire.prototype.hasMeasurement = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Question questions = 8;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.domain.Questionnaire.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Question, 8));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.domain.Questionnaire.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated Response responses = 9;
 * @return {!Array<!proto.satys.domain.Response>}
 */
proto.satys.domain.Questionnaire.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.satys.domain.Response>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Response, 9));
};


/**
 * @param {!Array<!proto.satys.domain.Response>} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.satys.domain.Response=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Response}
 */
proto.satys.domain.Questionnaire.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.satys.domain.Response, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};


/**
 * repeated Label labels = 10;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.domain.Questionnaire.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Label, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.domain.Questionnaire.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional ThirdPartyQuestionnaire third_party_questionnaire = 11;
 * @return {?proto.satys.domain.ThirdPartyQuestionnaire}
 */
proto.satys.domain.Questionnaire.prototype.getThirdPartyQuestionnaire = function() {
  return /** @type{?proto.satys.domain.ThirdPartyQuestionnaire} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.ThirdPartyQuestionnaire, 11));
};


/**
 * @param {?proto.satys.domain.ThirdPartyQuestionnaire|undefined} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setThirdPartyQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearThirdPartyQuestionnaire = function() {
  return this.setThirdPartyQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Questionnaire.prototype.hasThirdPartyQuestionnaire = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated OverallMetric overall_metrics = 12;
 * @return {!Array<!proto.satys.domain.OverallMetric>}
 */
proto.satys.domain.Questionnaire.prototype.getOverallMetricsList = function() {
  return /** @type{!Array<!proto.satys.domain.OverallMetric>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.OverallMetric, 12));
};


/**
 * @param {!Array<!proto.satys.domain.OverallMetric>} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setOverallMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.satys.domain.OverallMetric=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.OverallMetric}
 */
proto.satys.domain.Questionnaire.prototype.addOverallMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.satys.domain.OverallMetric, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearOverallMetricsList = function() {
  return this.setOverallMetricsList([]);
};


/**
 * repeated PriorityMetric priority_metrics = 13;
 * @return {!Array<!proto.satys.domain.PriorityMetric>}
 */
proto.satys.domain.Questionnaire.prototype.getPriorityMetricsList = function() {
  return /** @type{!Array<!proto.satys.domain.PriorityMetric>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.PriorityMetric, 13));
};


/**
 * @param {!Array<!proto.satys.domain.PriorityMetric>} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setPriorityMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.satys.domain.PriorityMetric=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.PriorityMetric}
 */
proto.satys.domain.Questionnaire.prototype.addPriorityMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.satys.domain.PriorityMetric, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearPriorityMetricsList = function() {
  return this.setPriorityMetricsList([]);
};


/**
 * repeated CohortMetric cohort_metrics = 14;
 * @return {!Array<!proto.satys.domain.CohortMetric>}
 */
proto.satys.domain.Questionnaire.prototype.getCohortMetricsList = function() {
  return /** @type{!Array<!proto.satys.domain.CohortMetric>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.CohortMetric, 14));
};


/**
 * @param {!Array<!proto.satys.domain.CohortMetric>} value
 * @return {!proto.satys.domain.Questionnaire} returns this
*/
proto.satys.domain.Questionnaire.prototype.setCohortMetricsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.satys.domain.CohortMetric=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.CohortMetric}
 */
proto.satys.domain.Questionnaire.prototype.addCohortMetrics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.satys.domain.CohortMetric, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Questionnaire} returns this
 */
proto.satys.domain.Questionnaire.prototype.clearCohortMetricsList = function() {
  return this.setCohortMetricsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.QuestionId.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.QuestionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.QuestionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.QuestionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    str: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    questionnaireId: (f = msg.getQuestionnaireId()) && proto.satys.domain.QuestionnaireId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.QuestionId}
 */
proto.satys.domain.QuestionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.QuestionId;
  return proto.satys.domain.QuestionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.QuestionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.QuestionId}
 */
proto.satys.domain.QuestionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStr(value);
      break;
    case 2:
      var value = /** @type {!proto.satys.domain.Question.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.satys.domain.QuestionnaireId;
      reader.readMessage(value,proto.satys.domain.QuestionnaireId.deserializeBinaryFromReader);
      msg.setQuestionnaireId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.QuestionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.QuestionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.QuestionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.QuestionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getQuestionnaireId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.QuestionnaireId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string str = 1;
 * @return {string}
 */
proto.satys.domain.QuestionId.prototype.getStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.QuestionId} returns this
 */
proto.satys.domain.QuestionId.prototype.setStr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Question.Type type = 2;
 * @return {!proto.satys.domain.Question.Type}
 */
proto.satys.domain.QuestionId.prototype.getType = function() {
  return /** @type {!proto.satys.domain.Question.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.satys.domain.Question.Type} value
 * @return {!proto.satys.domain.QuestionId} returns this
 */
proto.satys.domain.QuestionId.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional QuestionnaireId questionnaire_id = 3;
 * @return {?proto.satys.domain.QuestionnaireId}
 */
proto.satys.domain.QuestionId.prototype.getQuestionnaireId = function() {
  return /** @type{?proto.satys.domain.QuestionnaireId} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.QuestionnaireId, 3));
};


/**
 * @param {?proto.satys.domain.QuestionnaireId|undefined} value
 * @return {!proto.satys.domain.QuestionId} returns this
*/
proto.satys.domain.QuestionId.prototype.setQuestionnaireId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.QuestionId} returns this
 */
proto.satys.domain.QuestionId.prototype.clearQuestionnaireId = function() {
  return this.setQuestionnaireId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.QuestionId.prototype.hasQuestionnaireId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Question.repeatedFields_ = [4,10,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaire: (f = msg.getQuestionnaire()) && proto.satys.domain.Questionnaire.toObject(includeInstance, f),
    str: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.satys.domain.Answer.toObject, includeInstance),
    ratingSteps: jspb.Message.getFieldWithDefault(msg, 5, 0),
    category: jspb.Message.getFieldWithDefault(msg, 6, ""),
    typeEnum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    metric: jspb.Message.getFieldWithDefault(msg, 8, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 9, ""),
    choiceOptionsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    order: jspb.Message.getFieldWithDefault(msg, 11, 0),
    name: jspb.Message.getFieldWithDefault(msg, 12, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.satys.domain.Label.toObject, includeInstance),
    thirdPartyQuestion: (f = msg.getThirdPartyQuestion()) && proto.satys.domain.ThirdPartyQuestion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Question}
 */
proto.satys.domain.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Question;
  return proto.satys.domain.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Question}
 */
proto.satys.domain.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Questionnaire;
      reader.readMessage(value,proto.satys.domain.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.satys.domain.Answer;
      reader.readMessage(value,proto.satys.domain.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingSteps(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 7:
      var value = /** @type {!proto.satys.domain.Question.Type} */ (reader.readEnum());
      msg.setTypeEnum(value);
      break;
    case 8:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addChoiceOptions(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 13:
      var value = new proto.satys.domain.Label;
      reader.readMessage(value,proto.satys.domain.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 14:
      var value = new proto.satys.domain.ThirdPartyQuestion;
      reader.readMessage(value,proto.satys.domain.ThirdPartyQuestion.deserializeBinaryFromReader);
      msg.setThirdPartyQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getStr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.satys.domain.Answer.serializeBinaryToWriter
    );
  }
  f = message.getRatingSteps();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTypeEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getChoiceOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.satys.domain.Label.serializeBinaryToWriter
    );
  }
  f = message.getThirdPartyQuestion();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.satys.domain.ThirdPartyQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.Question.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_RATING: 1,
  TYPE_BOOLEAN: 2,
  TYPE_CHOICE: 3,
  TYPE_CHOICES: 4,
  TYPE_OPEN: 5
};

/**
 * @enum {number}
 */
proto.satys.domain.Question.Metric = {
  METRIC_UNSPECIFIED: 0,
  METRIC_MAIN: 1,
  METRIC_PRIORITIES: 2,
  METRIC_COHORT: 3
};

/**
 * optional Questionnaire questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.domain.Question.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.domain.Question} returns this
*/
proto.satys.domain.Question.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Question.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string str = 2;
 * @return {string}
 */
proto.satys.domain.Question.prototype.getStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setStr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.satys.domain.Question.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Answer answers = 4;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.domain.Question.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Answer, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.domain.Question} returns this
*/
proto.satys.domain.Question.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.domain.Question.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * optional int32 rating_steps = 5;
 * @return {number}
 */
proto.satys.domain.Question.prototype.getRatingSteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setRatingSteps = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string category = 6;
 * @return {string}
 */
proto.satys.domain.Question.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Type type_enum = 7;
 * @return {!proto.satys.domain.Question.Type}
 */
proto.satys.domain.Question.prototype.getTypeEnum = function() {
  return /** @type {!proto.satys.domain.Question.Type} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.satys.domain.Question.Type} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setTypeEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Metric metric = 8;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.domain.Question.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string subject = 9;
 * @return {string}
 */
proto.satys.domain.Question.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string choice_options = 10;
 * @return {!Array<string>}
 */
proto.satys.domain.Question.prototype.getChoiceOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setChoiceOptionsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.addChoiceOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.clearChoiceOptionsList = function() {
  return this.setChoiceOptionsList([]);
};


/**
 * optional int32 order = 11;
 * @return {number}
 */
proto.satys.domain.Question.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string name = 12;
 * @return {string}
 */
proto.satys.domain.Question.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated Label labels = 13;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.domain.Question.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Label, 13));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.domain.Question} returns this
*/
proto.satys.domain.Question.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.domain.Question.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional ThirdPartyQuestion third_party_question = 14;
 * @return {?proto.satys.domain.ThirdPartyQuestion}
 */
proto.satys.domain.Question.prototype.getThirdPartyQuestion = function() {
  return /** @type{?proto.satys.domain.ThirdPartyQuestion} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.ThirdPartyQuestion, 14));
};


/**
 * @param {?proto.satys.domain.ThirdPartyQuestion|undefined} value
 * @return {!proto.satys.domain.Question} returns this
*/
proto.satys.domain.Question.prototype.setThirdPartyQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Question} returns this
 */
proto.satys.domain.Question.prototype.clearThirdPartyQuestion = function() {
  return this.setThirdPartyQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Question.prototype.hasThirdPartyQuestion = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Response.repeatedFields_ = [2,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaire: (f = msg.getQuestionnaire()) && proto.satys.domain.Questionnaire.toObject(includeInstance, f),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.satys.domain.Answer.toObject, includeInstance),
    respondent: (f = msg.getRespondent()) && proto.satys.domain.Respondent.toObject(includeInstance, f),
    submitted: (f = msg.getSubmitted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    channel: (f = msg.getChannel()) && proto.satys.domain.Channel.toObject(includeInstance, f),
    location: jspb.Message.getFieldWithDefault(msg, 6, ""),
    thirdPartyResponse: (f = msg.getThirdPartyResponse()) && proto.satys.domain.ThirdPartyResponse.toObject(includeInstance, f),
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.satys.domain.Response.Metadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Response}
 */
proto.satys.domain.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Response;
  return proto.satys.domain.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Response}
 */
proto.satys.domain.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Questionnaire;
      reader.readMessage(value,proto.satys.domain.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 2:
      var value = new proto.satys.domain.Answer;
      reader.readMessage(value,proto.satys.domain.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 3:
      var value = new proto.satys.domain.Respondent;
      reader.readMessage(value,proto.satys.domain.Respondent.deserializeBinaryFromReader);
      msg.setRespondent(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSubmitted(value);
      break;
    case 5:
      var value = new proto.satys.domain.Channel;
      reader.readMessage(value,proto.satys.domain.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 7:
      var value = new proto.satys.domain.ThirdPartyResponse;
      reader.readMessage(value,proto.satys.domain.ThirdPartyResponse.deserializeBinaryFromReader);
      msg.setThirdPartyResponse(value);
      break;
    case 8:
      var value = new proto.satys.domain.Response.Metadata;
      reader.readMessage(value,proto.satys.domain.Response.Metadata.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.Answer.serializeBinaryToWriter
    );
  }
  f = message.getRespondent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.Respondent.serializeBinaryToWriter
    );
  }
  f = message.getSubmitted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.satys.domain.Channel.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getThirdPartyResponse();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.satys.domain.ThirdPartyResponse.serializeBinaryToWriter
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.satys.domain.Response.Metadata.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.satys.domain.Response.Metadata.oneofGroups_ = [[2,3,4,5]];

/**
 * @enum {number}
 */
proto.satys.domain.Response.Metadata.ValueCase = {
  VALUE_NOT_SET: 0,
  STRING_VALUE: 2,
  INTEGER_VALUE: 3,
  FLOAT_VALUE: 4,
  TIMESTAMP_VALUE: 5
};

/**
 * @return {proto.satys.domain.Response.Metadata.ValueCase}
 */
proto.satys.domain.Response.Metadata.prototype.getValueCase = function() {
  return /** @type {proto.satys.domain.Response.Metadata.ValueCase} */(jspb.Message.computeOneofCase(this, proto.satys.domain.Response.Metadata.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Response.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Response.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Response.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Response.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stringValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    integerValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    timestampValue: (f = msg.getTimestampValue()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Response.Metadata}
 */
proto.satys.domain.Response.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Response.Metadata;
  return proto.satys.domain.Response.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Response.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Response.Metadata}
 */
proto.satys.domain.Response.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntegerValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatValue(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Response.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Response.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Response.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Response.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.satys.domain.Response.Metadata.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string string_value = 2;
 * @return {string}
 */
proto.satys.domain.Response.Metadata.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.satys.domain.Response.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.satys.domain.Response.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.Metadata.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 integer_value = 3;
 * @return {number}
 */
proto.satys.domain.Response.Metadata.prototype.getIntegerValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.setIntegerValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.satys.domain.Response.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.clearIntegerValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.satys.domain.Response.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.Metadata.prototype.hasIntegerValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float float_value = 4;
 * @return {number}
 */
proto.satys.domain.Response.Metadata.prototype.getFloatValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.setFloatValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.satys.domain.Response.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.clearFloatValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.satys.domain.Response.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.Metadata.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_value = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Response.Metadata.prototype.getTimestampValue = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Response.Metadata} returns this
*/
proto.satys.domain.Response.Metadata.prototype.setTimestampValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.satys.domain.Response.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Response.Metadata} returns this
 */
proto.satys.domain.Response.Metadata.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.Metadata.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Questionnaire questionnaire = 1;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.domain.Response.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Questionnaire, 1));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.domain.Response} returns this
*/
proto.satys.domain.Response.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Answer answers = 2;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.domain.Response.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Answer, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.domain.Response} returns this
*/
proto.satys.domain.Response.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.domain.Response.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * optional Respondent respondent = 3;
 * @return {?proto.satys.domain.Respondent}
 */
proto.satys.domain.Response.prototype.getRespondent = function() {
  return /** @type{?proto.satys.domain.Respondent} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Respondent, 3));
};


/**
 * @param {?proto.satys.domain.Respondent|undefined} value
 * @return {!proto.satys.domain.Response} returns this
*/
proto.satys.domain.Response.prototype.setRespondent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.clearRespondent = function() {
  return this.setRespondent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.prototype.hasRespondent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp submitted = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.Response.prototype.getSubmitted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.Response} returns this
*/
proto.satys.domain.Response.prototype.setSubmitted = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.clearSubmitted = function() {
  return this.setSubmitted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.prototype.hasSubmitted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Channel channel = 5;
 * @return {?proto.satys.domain.Channel}
 */
proto.satys.domain.Response.prototype.getChannel = function() {
  return /** @type{?proto.satys.domain.Channel} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Channel, 5));
};


/**
 * @param {?proto.satys.domain.Channel|undefined} value
 * @return {!proto.satys.domain.Response} returns this
*/
proto.satys.domain.Response.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string location = 6;
 * @return {string}
 */
proto.satys.domain.Response.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ThirdPartyResponse third_party_response = 7;
 * @return {?proto.satys.domain.ThirdPartyResponse}
 */
proto.satys.domain.Response.prototype.getThirdPartyResponse = function() {
  return /** @type{?proto.satys.domain.ThirdPartyResponse} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.ThirdPartyResponse, 7));
};


/**
 * @param {?proto.satys.domain.ThirdPartyResponse|undefined} value
 * @return {!proto.satys.domain.Response} returns this
*/
proto.satys.domain.Response.prototype.setThirdPartyResponse = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.clearThirdPartyResponse = function() {
  return this.setThirdPartyResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Response.prototype.hasThirdPartyResponse = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Metadata metadata = 8;
 * @return {!Array<!proto.satys.domain.Response.Metadata>}
 */
proto.satys.domain.Response.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.satys.domain.Response.Metadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Response.Metadata, 8));
};


/**
 * @param {!Array<!proto.satys.domain.Response.Metadata>} value
 * @return {!proto.satys.domain.Response} returns this
*/
proto.satys.domain.Response.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.satys.domain.Response.Metadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Response.Metadata}
 */
proto.satys.domain.Response.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.satys.domain.Response.Metadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Response} returns this
 */
proto.satys.domain.Response.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Answer.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Answer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Answer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Answer.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: (f = msg.getResponse()) && proto.satys.domain.Response.toObject(includeInstance, f),
    answer: jspb.Message.getFieldWithDefault(msg, 2, ""),
    question: (f = msg.getQuestion()) && proto.satys.domain.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.domain.Answer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Answer;
  return proto.satys.domain.Answer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Answer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.domain.Answer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Response;
      reader.readMessage(value,proto.satys.domain.Response.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 3:
      var value = new proto.satys.domain.Question;
      reader.readMessage(value,proto.satys.domain.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Answer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Answer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Answer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Answer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Response.serializeBinaryToWriter
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional Response response = 1;
 * @return {?proto.satys.domain.Response}
 */
proto.satys.domain.Answer.prototype.getResponse = function() {
  return /** @type{?proto.satys.domain.Response} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Response, 1));
};


/**
 * @param {?proto.satys.domain.Response|undefined} value
 * @return {!proto.satys.domain.Answer} returns this
*/
proto.satys.domain.Answer.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Answer} returns this
 */
proto.satys.domain.Answer.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Answer.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string answer = 2;
 * @return {string}
 */
proto.satys.domain.Answer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Answer} returns this
 */
proto.satys.domain.Answer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Question question = 3;
 * @return {?proto.satys.domain.Question}
 */
proto.satys.domain.Answer.prototype.getQuestion = function() {
  return /** @type{?proto.satys.domain.Question} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Question, 3));
};


/**
 * @param {?proto.satys.domain.Question|undefined} value
 * @return {!proto.satys.domain.Answer} returns this
*/
proto.satys.domain.Answer.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Answer} returns this
 */
proto.satys.domain.Answer.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Answer.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.Respondent.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Respondent.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Respondent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Respondent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Respondent.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    proto.satys.domain.Response.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Respondent}
 */
proto.satys.domain.Respondent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Respondent;
  return proto.satys.domain.Respondent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Respondent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Respondent}
 */
proto.satys.domain.Respondent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 5:
      var value = new proto.satys.domain.Response;
      reader.readMessage(value,proto.satys.domain.Response.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Respondent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Respondent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Respondent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Respondent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.satys.domain.Response.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.satys.domain.Respondent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Respondent} returns this
 */
proto.satys.domain.Respondent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email_address = 2;
 * @return {string}
 */
proto.satys.domain.Respondent.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Respondent} returns this
 */
proto.satys.domain.Respondent.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.satys.domain.Respondent.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Respondent} returns this
 */
proto.satys.domain.Respondent.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phonenumber = 4;
 * @return {string}
 */
proto.satys.domain.Respondent.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Respondent} returns this
 */
proto.satys.domain.Respondent.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Response responses = 5;
 * @return {!Array<!proto.satys.domain.Response>}
 */
proto.satys.domain.Respondent.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.satys.domain.Response>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Response, 5));
};


/**
 * @param {!Array<!proto.satys.domain.Response>} value
 * @return {!proto.satys.domain.Respondent} returns this
*/
proto.satys.domain.Respondent.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.satys.domain.Response=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Response}
 */
proto.satys.domain.Respondent.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.satys.domain.Response, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.Respondent} returns this
 */
proto.satys.domain.Respondent.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Channel.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Channel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Channel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Channel.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activeQuestionnaire: (f = msg.getActiveQuestionnaire()) && proto.satys.domain.Questionnaire.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.domain.Channel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Channel;
  return proto.satys.domain.Channel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Channel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.domain.Channel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.satys.domain.Questionnaire;
      reader.readMessage(value,proto.satys.domain.Questionnaire.deserializeBinaryFromReader);
      msg.setActiveQuestionnaire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Channel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Channel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Channel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Channel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActiveQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.satys.domain.Channel.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Channel} returns this
 */
proto.satys.domain.Channel.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.satys.domain.Channel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Channel} returns this
 */
proto.satys.domain.Channel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Questionnaire active_questionnaire = 3;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.domain.Channel.prototype.getActiveQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Questionnaire, 3));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.domain.Channel} returns this
*/
proto.satys.domain.Channel.prototype.setActiveQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Channel} returns this
 */
proto.satys.domain.Channel.prototype.clearActiveQuestionnaire = function() {
  return this.setActiveQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Channel.prototype.hasActiveQuestionnaire = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ChannelPlanning.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ChannelPlanning.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ChannelPlanning} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ChannelPlanning.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    channel: (f = msg.getChannel()) && proto.satys.domain.Channel.toObject(includeInstance, f),
    questionnaire: (f = msg.getQuestionnaire()) && proto.satys.domain.Questionnaire.toObject(includeInstance, f),
    location: jspb.Message.getFieldWithDefault(msg, 5, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ChannelPlanning}
 */
proto.satys.domain.ChannelPlanning.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ChannelPlanning;
  return proto.satys.domain.ChannelPlanning.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ChannelPlanning} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ChannelPlanning}
 */
proto.satys.domain.ChannelPlanning.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 3:
      var value = new proto.satys.domain.Channel;
      reader.readMessage(value,proto.satys.domain.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 4:
      var value = new proto.satys.domain.Questionnaire;
      reader.readMessage(value,proto.satys.domain.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ChannelPlanning.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ChannelPlanning.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ChannelPlanning} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ChannelPlanning.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.Channel.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.satys.domain.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ChannelPlanning.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ChannelPlanning} returns this
*/
proto.satys.domain.ChannelPlanning.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ChannelPlanning} returns this
 */
proto.satys.domain.ChannelPlanning.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ChannelPlanning.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ChannelPlanning.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ChannelPlanning} returns this
*/
proto.satys.domain.ChannelPlanning.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ChannelPlanning} returns this
 */
proto.satys.domain.ChannelPlanning.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ChannelPlanning.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Channel channel = 3;
 * @return {?proto.satys.domain.Channel}
 */
proto.satys.domain.ChannelPlanning.prototype.getChannel = function() {
  return /** @type{?proto.satys.domain.Channel} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Channel, 3));
};


/**
 * @param {?proto.satys.domain.Channel|undefined} value
 * @return {!proto.satys.domain.ChannelPlanning} returns this
*/
proto.satys.domain.ChannelPlanning.prototype.setChannel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ChannelPlanning} returns this
 */
proto.satys.domain.ChannelPlanning.prototype.clearChannel = function() {
  return this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ChannelPlanning.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Questionnaire questionnaire = 4;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.domain.ChannelPlanning.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Questionnaire, 4));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.domain.ChannelPlanning} returns this
*/
proto.satys.domain.ChannelPlanning.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ChannelPlanning} returns this
 */
proto.satys.domain.ChannelPlanning.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ChannelPlanning.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string location = 5;
 * @return {string}
 */
proto.satys.domain.ChannelPlanning.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ChannelPlanning} returns this
 */
proto.satys.domain.ChannelPlanning.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string uid = 6;
 * @return {string}
 */
proto.satys.domain.ChannelPlanning.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ChannelPlanning} returns this
 */
proto.satys.domain.ChannelPlanning.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ThirdPartyOauth.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ThirdPartyOauth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ThirdPartyOauth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyOauth.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.satys.domain.User.toObject(includeInstance, f),
    thirdParty: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refreshToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scope: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expires: (f = msg.getExpires()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accessToken: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ThirdPartyOauth}
 */
proto.satys.domain.ThirdPartyOauth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ThirdPartyOauth;
  return proto.satys.domain.ThirdPartyOauth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ThirdPartyOauth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ThirdPartyOauth}
 */
proto.satys.domain.ThirdPartyOauth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.User;
      reader.readMessage(value,proto.satys.domain.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdParty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpires(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ThirdPartyOauth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ThirdPartyOauth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ThirdPartyOauth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyOauth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.User.serializeBinaryToWriter
    );
  }
  f = message.getThirdParty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpires();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.satys.domain.User}
 */
proto.satys.domain.ThirdPartyOauth.prototype.getUser = function() {
  return /** @type{?proto.satys.domain.User} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.User, 1));
};


/**
 * @param {?proto.satys.domain.User|undefined} value
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
*/
proto.satys.domain.ThirdPartyOauth.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
 */
proto.satys.domain.ThirdPartyOauth.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ThirdPartyOauth.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string third_party = 2;
 * @return {string}
 */
proto.satys.domain.ThirdPartyOauth.prototype.getThirdParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
 */
proto.satys.domain.ThirdPartyOauth.prototype.setThirdParty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string refresh_token = 3;
 * @return {string}
 */
proto.satys.domain.ThirdPartyOauth.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
 */
proto.satys.domain.ThirdPartyOauth.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope = 4;
 * @return {string}
 */
proto.satys.domain.ThirdPartyOauth.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
 */
proto.satys.domain.ThirdPartyOauth.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp expires = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.ThirdPartyOauth.prototype.getExpires = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
*/
proto.satys.domain.ThirdPartyOauth.prototype.setExpires = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
 */
proto.satys.domain.ThirdPartyOauth.prototype.clearExpires = function() {
  return this.setExpires(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ThirdPartyOauth.prototype.hasExpires = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string access_token = 6;
 * @return {string}
 */
proto.satys.domain.ThirdPartyOauth.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyOauth} returns this
 */
proto.satys.domain.ThirdPartyOauth.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ThirdPartyQuestionnaire.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ThirdPartyQuestionnaire.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ThirdPartyQuestionnaire} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyQuestionnaire.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdParty: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalRef: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ThirdPartyQuestionnaire}
 */
proto.satys.domain.ThirdPartyQuestionnaire.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ThirdPartyQuestionnaire;
  return proto.satys.domain.ThirdPartyQuestionnaire.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ThirdPartyQuestionnaire} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ThirdPartyQuestionnaire}
 */
proto.satys.domain.ThirdPartyQuestionnaire.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdParty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ThirdPartyQuestionnaire.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ThirdPartyQuestionnaire.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ThirdPartyQuestionnaire} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyQuestionnaire.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdParty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string third_party = 2;
 * @return {string}
 */
proto.satys.domain.ThirdPartyQuestionnaire.prototype.getThirdParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyQuestionnaire} returns this
 */
proto.satys.domain.ThirdPartyQuestionnaire.prototype.setThirdParty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_ref = 3;
 * @return {string}
 */
proto.satys.domain.ThirdPartyQuestionnaire.prototype.getExternalRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyQuestionnaire} returns this
 */
proto.satys.domain.ThirdPartyQuestionnaire.prototype.setExternalRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ThirdPartyQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ThirdPartyQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ThirdPartyQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdParty: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalRef: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ThirdPartyQuestion}
 */
proto.satys.domain.ThirdPartyQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ThirdPartyQuestion;
  return proto.satys.domain.ThirdPartyQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ThirdPartyQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ThirdPartyQuestion}
 */
proto.satys.domain.ThirdPartyQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdParty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ThirdPartyQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ThirdPartyQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ThirdPartyQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdParty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string third_party = 2;
 * @return {string}
 */
proto.satys.domain.ThirdPartyQuestion.prototype.getThirdParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyQuestion} returns this
 */
proto.satys.domain.ThirdPartyQuestion.prototype.setThirdParty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_ref = 3;
 * @return {string}
 */
proto.satys.domain.ThirdPartyQuestion.prototype.getExternalRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyQuestion} returns this
 */
proto.satys.domain.ThirdPartyQuestion.prototype.setExternalRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ThirdPartyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ThirdPartyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ThirdPartyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdParty: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isBulk: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ThirdPartyResponse}
 */
proto.satys.domain.ThirdPartyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ThirdPartyResponse;
  return proto.satys.domain.ThirdPartyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ThirdPartyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ThirdPartyResponse}
 */
proto.satys.domain.ThirdPartyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdParty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalRef(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProcessed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBulk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ThirdPartyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ThirdPartyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ThirdPartyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ThirdPartyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdParty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsBulk();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string third_party = 2;
 * @return {string}
 */
proto.satys.domain.ThirdPartyResponse.prototype.getThirdParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyResponse} returns this
 */
proto.satys.domain.ThirdPartyResponse.prototype.setThirdParty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_ref = 3;
 * @return {string}
 */
proto.satys.domain.ThirdPartyResponse.prototype.getExternalRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ThirdPartyResponse} returns this
 */
proto.satys.domain.ThirdPartyResponse.prototype.setExternalRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool processed = 4;
 * @return {boolean}
 */
proto.satys.domain.ThirdPartyResponse.prototype.getProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.domain.ThirdPartyResponse} returns this
 */
proto.satys.domain.ThirdPartyResponse.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_bulk = 5;
 * @return {boolean}
 */
proto.satys.domain.ThirdPartyResponse.prototype.getIsBulk = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.satys.domain.ThirdPartyResponse} returns this
 */
proto.satys.domain.ThirdPartyResponse.prototype.setIsBulk = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Integration.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Integration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Integration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Integration.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thirdPartyOauth: (f = msg.getThirdPartyOauth()) && proto.satys.domain.ThirdPartyOauth.toObject(includeInstance, f),
    questionnaire: (f = msg.getQuestionnaire()) && proto.satys.domain.Questionnaire.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Integration}
 */
proto.satys.domain.Integration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Integration;
  return proto.satys.domain.Integration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Integration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Integration}
 */
proto.satys.domain.Integration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 3:
      var value = new proto.satys.domain.ThirdPartyOauth;
      reader.readMessage(value,proto.satys.domain.ThirdPartyOauth.deserializeBinaryFromReader);
      msg.setThirdPartyOauth(value);
      break;
    case 4:
      var value = new proto.satys.domain.Questionnaire;
      reader.readMessage(value,proto.satys.domain.Questionnaire.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Integration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Integration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Integration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Integration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThirdPartyOauth();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.ThirdPartyOauth.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.satys.domain.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.satys.domain.Integration.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Integration} returns this
 */
proto.satys.domain.Integration.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ThirdPartyOauth third_party_oauth = 3;
 * @return {?proto.satys.domain.ThirdPartyOauth}
 */
proto.satys.domain.Integration.prototype.getThirdPartyOauth = function() {
  return /** @type{?proto.satys.domain.ThirdPartyOauth} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.ThirdPartyOauth, 3));
};


/**
 * @param {?proto.satys.domain.ThirdPartyOauth|undefined} value
 * @return {!proto.satys.domain.Integration} returns this
*/
proto.satys.domain.Integration.prototype.setThirdPartyOauth = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Integration} returns this
 */
proto.satys.domain.Integration.prototype.clearThirdPartyOauth = function() {
  return this.setThirdPartyOauth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Integration.prototype.hasThirdPartyOauth = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Questionnaire questionnaire = 4;
 * @return {?proto.satys.domain.Questionnaire}
 */
proto.satys.domain.Integration.prototype.getQuestionnaire = function() {
  return /** @type{?proto.satys.domain.Questionnaire} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Questionnaire, 4));
};


/**
 * @param {?proto.satys.domain.Questionnaire|undefined} value
 * @return {!proto.satys.domain.Integration} returns this
*/
proto.satys.domain.Integration.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Integration} returns this
 */
proto.satys.domain.Integration.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Integration.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    scope: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    owner: (f = msg.getOwner()) && proto.satys.domain.Organisation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.Label}
 */
proto.satys.domain.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.Label;
  return proto.satys.domain.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.Label}
 */
proto.satys.domain.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string scope = 1;
 * @return {string}
 */
proto.satys.domain.Label.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Label} returns this
 */
proto.satys.domain.Label.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.satys.domain.Label.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.Label} returns this
 */
proto.satys.domain.Label.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Organisation owner = 3;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.Label.prototype.getOwner = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 3));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.Label} returns this
*/
proto.satys.domain.Label.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.Label} returns this
 */
proto.satys.domain.Label.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.Label.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.UniversalQueryInterface.repeatedFields_ = [1,2,3,4,6,10,11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.UniversalQueryInterface.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.UniversalQueryInterface.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.UniversalQueryInterface} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.UniversalQueryInterface.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationsList: jspb.Message.toObjectList(msg.getOrganisationsList(),
    proto.satys.domain.Organisation.toObject, includeInstance),
    measurementsList: jspb.Message.toObjectList(msg.getMeasurementsList(),
    proto.satys.domain.Measurement.toObject, includeInstance),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    proto.satys.domain.Questionnaire.toObject, includeInstance),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.satys.domain.Question.toObject, includeInstance),
    range: (f = msg.getRange()) && proto.satys.domain.UniversalQueryInterface.DateRange.toObject(includeInstance, f),
    questionFiltersList: jspb.Message.toObjectList(msg.getQuestionFiltersList(),
    proto.satys.domain.UniversalQueryInterface.QuestionFilter.toObject, includeInstance),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.satys.domain.Answer.toObject, includeInstance),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.satys.domain.Label.toObject, includeInstance),
    responseMetadataList: jspb.Message.toObjectList(msg.getResponseMetadataList(),
    proto.satys.domain.Response.Metadata.toObject, includeInstance),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.satys.domain.Channel.toObject, includeInstance),
    performanceIndicator: (f = msg.getPerformanceIndicator()) && proto.satys.domain.PerformanceIndicator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.UniversalQueryInterface}
 */
proto.satys.domain.UniversalQueryInterface.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.UniversalQueryInterface;
  return proto.satys.domain.UniversalQueryInterface.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.UniversalQueryInterface} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.UniversalQueryInterface}
 */
proto.satys.domain.UniversalQueryInterface.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.addOrganisations(value);
      break;
    case 2:
      var value = new proto.satys.domain.Measurement;
      reader.readMessage(value,proto.satys.domain.Measurement.deserializeBinaryFromReader);
      msg.addMeasurements(value);
      break;
    case 3:
      var value = new proto.satys.domain.Questionnaire;
      reader.readMessage(value,proto.satys.domain.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    case 4:
      var value = new proto.satys.domain.Question;
      reader.readMessage(value,proto.satys.domain.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 5:
      var value = new proto.satys.domain.UniversalQueryInterface.DateRange;
      reader.readMessage(value,proto.satys.domain.UniversalQueryInterface.DateRange.deserializeBinaryFromReader);
      msg.setRange(value);
      break;
    case 6:
      var value = new proto.satys.domain.UniversalQueryInterface.QuestionFilter;
      reader.readMessage(value,proto.satys.domain.UniversalQueryInterface.QuestionFilter.deserializeBinaryFromReader);
      msg.addQuestionFilters(value);
      break;
    case 10:
      var value = new proto.satys.domain.Answer;
      reader.readMessage(value,proto.satys.domain.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    case 11:
      var value = new proto.satys.domain.Label;
      reader.readMessage(value,proto.satys.domain.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 12:
      var value = new proto.satys.domain.Response.Metadata;
      reader.readMessage(value,proto.satys.domain.Response.Metadata.deserializeBinaryFromReader);
      msg.addResponseMetadata(value);
      break;
    case 13:
      var value = new proto.satys.domain.Channel;
      reader.readMessage(value,proto.satys.domain.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 14:
      var value = new proto.satys.domain.PerformanceIndicator;
      reader.readMessage(value,proto.satys.domain.PerformanceIndicator.deserializeBinaryFromReader);
      msg.setPerformanceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.UniversalQueryInterface.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.UniversalQueryInterface.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.UniversalQueryInterface} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.UniversalQueryInterface.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getMeasurementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.Measurement.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.satys.domain.Questionnaire.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.satys.domain.Question.serializeBinaryToWriter
    );
  }
  f = message.getRange();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.satys.domain.UniversalQueryInterface.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getQuestionFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.satys.domain.UniversalQueryInterface.QuestionFilter.serializeBinaryToWriter
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.satys.domain.Answer.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.satys.domain.Label.serializeBinaryToWriter
    );
  }
  f = message.getResponseMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.satys.domain.Response.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.satys.domain.Channel.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceIndicator();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.satys.domain.PerformanceIndicator.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.UniversalQueryInterface.DateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.UniversalQueryInterface.DateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.UniversalQueryInterface.DateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    until: (f = msg.getUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.UniversalQueryInterface.DateRange}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.UniversalQueryInterface.DateRange;
  return proto.satys.domain.UniversalQueryInterface.DateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.UniversalQueryInterface.DateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.UniversalQueryInterface.DateRange}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.UniversalQueryInterface.DateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.UniversalQueryInterface.DateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.UniversalQueryInterface.DateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.UniversalQueryInterface.DateRange} returns this
*/
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.UniversalQueryInterface.DateRange} returns this
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp until = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.getUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.UniversalQueryInterface.DateRange} returns this
*/
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.setUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.UniversalQueryInterface.DateRange} returns this
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.clearUntil = function() {
  return this.setUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.UniversalQueryInterface.DateRange.prototype.hasUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.UniversalQueryInterface.QuestionFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.UniversalQueryInterface.QuestionFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    str: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typeEnum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metric: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.UniversalQueryInterface.QuestionFilter}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.UniversalQueryInterface.QuestionFilter;
  return proto.satys.domain.UniversalQueryInterface.QuestionFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.UniversalQueryInterface.QuestionFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.UniversalQueryInterface.QuestionFilter}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStr(value);
      break;
    case 2:
      var value = /** @type {!proto.satys.domain.Question.Type} */ (reader.readEnum());
      msg.setTypeEnum(value);
      break;
    case 3:
      var value = /** @type {!proto.satys.domain.Question.Metric} */ (reader.readEnum());
      msg.setMetric(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.UniversalQueryInterface.QuestionFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.UniversalQueryInterface.QuestionFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTypeEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMetric();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string str = 1;
 * @return {string}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.getStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.UniversalQueryInterface.QuestionFilter} returns this
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.setStr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Question.Type type_enum = 2;
 * @return {!proto.satys.domain.Question.Type}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.getTypeEnum = function() {
  return /** @type {!proto.satys.domain.Question.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.satys.domain.Question.Type} value
 * @return {!proto.satys.domain.UniversalQueryInterface.QuestionFilter} returns this
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.setTypeEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Question.Metric metric = 3;
 * @return {!proto.satys.domain.Question.Metric}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.getMetric = function() {
  return /** @type {!proto.satys.domain.Question.Metric} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.satys.domain.Question.Metric} value
 * @return {!proto.satys.domain.UniversalQueryInterface.QuestionFilter} returns this
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.setMetric = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.UniversalQueryInterface.QuestionFilter} returns this
 */
proto.satys.domain.UniversalQueryInterface.QuestionFilter.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Organisation organisations = 1;
 * @return {!Array<!proto.satys.domain.Organisation>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getOrganisationsList = function() {
  return /** @type{!Array<!proto.satys.domain.Organisation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Organisation, 1));
};


/**
 * @param {!Array<!proto.satys.domain.Organisation>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setOrganisationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.Organisation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Organisation}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addOrganisations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.Organisation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearOrganisationsList = function() {
  return this.setOrganisationsList([]);
};


/**
 * repeated Measurement measurements = 2;
 * @return {!Array<!proto.satys.domain.Measurement>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getMeasurementsList = function() {
  return /** @type{!Array<!proto.satys.domain.Measurement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Measurement, 2));
};


/**
 * @param {!Array<!proto.satys.domain.Measurement>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setMeasurementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.Measurement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Measurement}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addMeasurements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.Measurement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearMeasurementsList = function() {
  return this.setMeasurementsList([]);
};


/**
 * repeated Questionnaire questionnaires = 3;
 * @return {!Array<!proto.satys.domain.Questionnaire>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.satys.domain.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Questionnaire, 3));
};


/**
 * @param {!Array<!proto.satys.domain.Questionnaire>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.satys.domain.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Questionnaire}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.satys.domain.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};


/**
 * repeated Question questions = 4;
 * @return {!Array<!proto.satys.domain.Question>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Question, 4));
};


/**
 * @param {!Array<!proto.satys.domain.Question>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Question}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * optional DateRange range = 5;
 * @return {?proto.satys.domain.UniversalQueryInterface.DateRange}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getRange = function() {
  return /** @type{?proto.satys.domain.UniversalQueryInterface.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.UniversalQueryInterface.DateRange, 5));
};


/**
 * @param {?proto.satys.domain.UniversalQueryInterface.DateRange|undefined} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setRange = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearRange = function() {
  return this.setRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.UniversalQueryInterface.prototype.hasRange = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated QuestionFilter question_filters = 6;
 * @return {!Array<!proto.satys.domain.UniversalQueryInterface.QuestionFilter>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getQuestionFiltersList = function() {
  return /** @type{!Array<!proto.satys.domain.UniversalQueryInterface.QuestionFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.UniversalQueryInterface.QuestionFilter, 6));
};


/**
 * @param {!Array<!proto.satys.domain.UniversalQueryInterface.QuestionFilter>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setQuestionFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.satys.domain.UniversalQueryInterface.QuestionFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.UniversalQueryInterface.QuestionFilter}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addQuestionFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.satys.domain.UniversalQueryInterface.QuestionFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearQuestionFiltersList = function() {
  return this.setQuestionFiltersList([]);
};


/**
 * repeated Answer answers = 10;
 * @return {!Array<!proto.satys.domain.Answer>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.satys.domain.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Answer, 10));
};


/**
 * @param {!Array<!proto.satys.domain.Answer>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.satys.domain.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Answer}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.satys.domain.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};


/**
 * repeated Label labels = 11;
 * @return {!Array<!proto.satys.domain.Label>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Label, 11));
};


/**
 * @param {!Array<!proto.satys.domain.Label>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.satys.domain.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Label}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.satys.domain.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated Response.Metadata response_metadata = 12;
 * @return {!Array<!proto.satys.domain.Response.Metadata>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getResponseMetadataList = function() {
  return /** @type{!Array<!proto.satys.domain.Response.Metadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Response.Metadata, 12));
};


/**
 * @param {!Array<!proto.satys.domain.Response.Metadata>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setResponseMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.satys.domain.Response.Metadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Response.Metadata}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addResponseMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.satys.domain.Response.Metadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearResponseMetadataList = function() {
  return this.setResponseMetadataList([]);
};


/**
 * repeated Channel channels = 13;
 * @return {!Array<!proto.satys.domain.Channel>}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.satys.domain.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.Channel, 13));
};


/**
 * @param {!Array<!proto.satys.domain.Channel>} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.satys.domain.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.Channel}
 */
proto.satys.domain.UniversalQueryInterface.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.satys.domain.Channel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional PerformanceIndicator performance_indicator = 14;
 * @return {?proto.satys.domain.PerformanceIndicator}
 */
proto.satys.domain.UniversalQueryInterface.prototype.getPerformanceIndicator = function() {
  return /** @type{?proto.satys.domain.PerformanceIndicator} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.PerformanceIndicator, 14));
};


/**
 * @param {?proto.satys.domain.PerformanceIndicator|undefined} value
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
*/
proto.satys.domain.UniversalQueryInterface.prototype.setPerformanceIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.UniversalQueryInterface} returns this
 */
proto.satys.domain.UniversalQueryInterface.prototype.clearPerformanceIndicator = function() {
  return this.setPerformanceIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.UniversalQueryInterface.prototype.hasPerformanceIndicator = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.PerformanceIndicator.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.PerformanceIndicator.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.PerformanceIndicator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.PerformanceIndicator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PerformanceIndicator.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    thresholdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    requiredValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    desiredValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    scale: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minScale: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.PerformanceIndicator}
 */
proto.satys.domain.PerformanceIndicator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.PerformanceIndicator;
  return proto.satys.domain.PerformanceIndicator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.PerformanceIndicator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.PerformanceIndicator}
 */
proto.satys.domain.PerformanceIndicator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.satys.domain.PerformanceIndicator.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addThresholds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRequiredValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDesiredValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScale(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.PerformanceIndicator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.PerformanceIndicator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.PerformanceIndicator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PerformanceIndicator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getThresholdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getRequiredValue();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDesiredValue();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getScale();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMinScale();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.PerformanceIndicator.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_AVERAGE: 1,
  TYPE_CSAT: 2,
  TYPE_NPS: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.satys.domain.PerformanceIndicator.Type}
 */
proto.satys.domain.PerformanceIndicator.prototype.getType = function() {
  return /** @type {!proto.satys.domain.PerformanceIndicator.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.satys.domain.PerformanceIndicator.Type} value
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated int32 thresholds = 2;
 * @return {!Array<number>}
 */
proto.satys.domain.PerformanceIndicator.prototype.getThresholdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.setThresholdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.addThresholds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.clearThresholdsList = function() {
  return this.setThresholdsList([]);
};


/**
 * optional float required_value = 3;
 * @return {number}
 */
proto.satys.domain.PerformanceIndicator.prototype.getRequiredValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.setRequiredValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float desired_value = 4;
 * @return {number}
 */
proto.satys.domain.PerformanceIndicator.prototype.getDesiredValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.setDesiredValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 scale = 5;
 * @return {number}
 */
proto.satys.domain.PerformanceIndicator.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.setScale = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 min_scale = 6;
 * @return {number}
 */
proto.satys.domain.PerformanceIndicator.prototype.getMinScale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.PerformanceIndicator} returns this
 */
proto.satys.domain.PerformanceIndicator.prototype.setMinScale = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.PeriodReport.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.PeriodReport.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.PeriodReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.PeriodReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PeriodReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    currentPeriod: (f = msg.getCurrentPeriod()) && proto.satys.domain.PeriodReport.DateTimeRange.toObject(includeInstance, f),
    comparisonPeriod: (f = msg.getComparisonPeriod()) && proto.satys.domain.PeriodReport.DateTimeRange.toObject(includeInstance, f),
    topicsList: jspb.Message.toObjectList(msg.getTopicsList(),
    proto.satys.domain.PeriodReport.Topic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.PeriodReport}
 */
proto.satys.domain.PeriodReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.PeriodReport;
  return proto.satys.domain.PeriodReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.PeriodReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.PeriodReport}
 */
proto.satys.domain.PeriodReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 2:
      var value = new proto.satys.domain.PeriodReport.DateTimeRange;
      reader.readMessage(value,proto.satys.domain.PeriodReport.DateTimeRange.deserializeBinaryFromReader);
      msg.setCurrentPeriod(value);
      break;
    case 3:
      var value = new proto.satys.domain.PeriodReport.DateTimeRange;
      reader.readMessage(value,proto.satys.domain.PeriodReport.DateTimeRange.deserializeBinaryFromReader);
      msg.setComparisonPeriod(value);
      break;
    case 4:
      var value = new proto.satys.domain.PeriodReport.Topic;
      reader.readMessage(value,proto.satys.domain.PeriodReport.Topic.deserializeBinaryFromReader);
      msg.addTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.PeriodReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.PeriodReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.PeriodReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PeriodReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPeriod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.satys.domain.PeriodReport.DateTimeRange.serializeBinaryToWriter
    );
  }
  f = message.getComparisonPeriod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.PeriodReport.DateTimeRange.serializeBinaryToWriter
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.satys.domain.PeriodReport.Topic.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.PeriodReport.Topic.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.PeriodReport.Topic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.PeriodReport.Topic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PeriodReport.Topic.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comparisonContent: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.PeriodReport.Topic}
 */
proto.satys.domain.PeriodReport.Topic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.PeriodReport.Topic;
  return proto.satys.domain.PeriodReport.Topic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.PeriodReport.Topic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.PeriodReport.Topic}
 */
proto.satys.domain.PeriodReport.Topic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComparisonContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.PeriodReport.Topic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.PeriodReport.Topic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.PeriodReport.Topic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PeriodReport.Topic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComparisonContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.satys.domain.PeriodReport.Topic.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.PeriodReport.Topic} returns this
 */
proto.satys.domain.PeriodReport.Topic.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.satys.domain.PeriodReport.Topic.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.PeriodReport.Topic} returns this
 */
proto.satys.domain.PeriodReport.Topic.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comparison_content = 3;
 * @return {string}
 */
proto.satys.domain.PeriodReport.Topic.prototype.getComparisonContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.PeriodReport.Topic} returns this
 */
proto.satys.domain.PeriodReport.Topic.prototype.setComparisonContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.PeriodReport.DateTimeRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.PeriodReport.DateTimeRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PeriodReport.DateTimeRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.PeriodReport.DateTimeRange}
 */
proto.satys.domain.PeriodReport.DateTimeRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.PeriodReport.DateTimeRange;
  return proto.satys.domain.PeriodReport.DateTimeRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.PeriodReport.DateTimeRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.PeriodReport.DateTimeRange}
 */
proto.satys.domain.PeriodReport.DateTimeRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.PeriodReport.DateTimeRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.PeriodReport.DateTimeRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PeriodReport.DateTimeRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.PeriodReport.DateTimeRange} returns this
*/
proto.satys.domain.PeriodReport.DateTimeRange.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.PeriodReport.DateTimeRange} returns this
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.PeriodReport.DateTimeRange} returns this
*/
proto.satys.domain.PeriodReport.DateTimeRange.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.PeriodReport.DateTimeRange} returns this
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.PeriodReport.DateTimeRange.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Organisation organisation = 1;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.PeriodReport.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 1));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.PeriodReport} returns this
*/
proto.satys.domain.PeriodReport.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.PeriodReport} returns this
 */
proto.satys.domain.PeriodReport.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.PeriodReport.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DateTimeRange current_period = 2;
 * @return {?proto.satys.domain.PeriodReport.DateTimeRange}
 */
proto.satys.domain.PeriodReport.prototype.getCurrentPeriod = function() {
  return /** @type{?proto.satys.domain.PeriodReport.DateTimeRange} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.PeriodReport.DateTimeRange, 2));
};


/**
 * @param {?proto.satys.domain.PeriodReport.DateTimeRange|undefined} value
 * @return {!proto.satys.domain.PeriodReport} returns this
*/
proto.satys.domain.PeriodReport.prototype.setCurrentPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.PeriodReport} returns this
 */
proto.satys.domain.PeriodReport.prototype.clearCurrentPeriod = function() {
  return this.setCurrentPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.PeriodReport.prototype.hasCurrentPeriod = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DateTimeRange comparison_period = 3;
 * @return {?proto.satys.domain.PeriodReport.DateTimeRange}
 */
proto.satys.domain.PeriodReport.prototype.getComparisonPeriod = function() {
  return /** @type{?proto.satys.domain.PeriodReport.DateTimeRange} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.PeriodReport.DateTimeRange, 3));
};


/**
 * @param {?proto.satys.domain.PeriodReport.DateTimeRange|undefined} value
 * @return {!proto.satys.domain.PeriodReport} returns this
*/
proto.satys.domain.PeriodReport.prototype.setComparisonPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.PeriodReport} returns this
 */
proto.satys.domain.PeriodReport.prototype.clearComparisonPeriod = function() {
  return this.setComparisonPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.PeriodReport.prototype.hasComparisonPeriod = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Topic topics = 4;
 * @return {!Array<!proto.satys.domain.PeriodReport.Topic>}
 */
proto.satys.domain.PeriodReport.prototype.getTopicsList = function() {
  return /** @type{!Array<!proto.satys.domain.PeriodReport.Topic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.PeriodReport.Topic, 4));
};


/**
 * @param {!Array<!proto.satys.domain.PeriodReport.Topic>} value
 * @return {!proto.satys.domain.PeriodReport} returns this
*/
proto.satys.domain.PeriodReport.prototype.setTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.satys.domain.PeriodReport.Topic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.PeriodReport.Topic}
 */
proto.satys.domain.PeriodReport.prototype.addTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.satys.domain.PeriodReport.Topic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.PeriodReport} returns this
 */
proto.satys.domain.PeriodReport.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.NotificationSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.NotificationSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.NotificationSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.NotificationSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    interval: jspb.Message.getFieldWithDefault(msg, 1, 0),
    method: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastSent: (f = msg.getLastSent()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    organisation: (f = msg.getOrganisation()) && proto.satys.domain.Organisation.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.satys.domain.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.NotificationSetting}
 */
proto.satys.domain.NotificationSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.NotificationSetting;
  return proto.satys.domain.NotificationSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.NotificationSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.NotificationSetting}
 */
proto.satys.domain.NotificationSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.satys.domain.NotificationSetting.Interval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    case 2:
      var value = /** @type {!proto.satys.domain.NotificationSetting.Method} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSent(value);
      break;
    case 4:
      var value = new proto.satys.domain.Organisation;
      reader.readMessage(value,proto.satys.domain.Organisation.deserializeBinaryFromReader);
      msg.setOrganisation(value);
      break;
    case 5:
      var value = new proto.satys.domain.Profile;
      reader.readMessage(value,proto.satys.domain.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.NotificationSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.NotificationSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.NotificationSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.NotificationSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLastSent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrganisation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.satys.domain.Organisation.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.satys.domain.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.NotificationSetting.Interval = {
  INTERVAL_UNSPECIFIED: 0,
  INTERVAL_NONE: 1,
  INTERVAL_MONTHLY: 2
};

/**
 * @enum {number}
 */
proto.satys.domain.NotificationSetting.Method = {
  METHOD_UNSPECIFIED: 0,
  METHOD_EMAIL: 1
};

/**
 * optional Interval interval = 1;
 * @return {!proto.satys.domain.NotificationSetting.Interval}
 */
proto.satys.domain.NotificationSetting.prototype.getInterval = function() {
  return /** @type {!proto.satys.domain.NotificationSetting.Interval} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.satys.domain.NotificationSetting.Interval} value
 * @return {!proto.satys.domain.NotificationSetting} returns this
 */
proto.satys.domain.NotificationSetting.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Method method = 2;
 * @return {!proto.satys.domain.NotificationSetting.Method}
 */
proto.satys.domain.NotificationSetting.prototype.getMethod = function() {
  return /** @type {!proto.satys.domain.NotificationSetting.Method} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.satys.domain.NotificationSetting.Method} value
 * @return {!proto.satys.domain.NotificationSetting} returns this
 */
proto.satys.domain.NotificationSetting.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_sent = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.satys.domain.NotificationSetting.prototype.getLastSent = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.satys.domain.NotificationSetting} returns this
*/
proto.satys.domain.NotificationSetting.prototype.setLastSent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.NotificationSetting} returns this
 */
proto.satys.domain.NotificationSetting.prototype.clearLastSent = function() {
  return this.setLastSent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.NotificationSetting.prototype.hasLastSent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Organisation organisation = 4;
 * @return {?proto.satys.domain.Organisation}
 */
proto.satys.domain.NotificationSetting.prototype.getOrganisation = function() {
  return /** @type{?proto.satys.domain.Organisation} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Organisation, 4));
};


/**
 * @param {?proto.satys.domain.Organisation|undefined} value
 * @return {!proto.satys.domain.NotificationSetting} returns this
*/
proto.satys.domain.NotificationSetting.prototype.setOrganisation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.NotificationSetting} returns this
 */
proto.satys.domain.NotificationSetting.prototype.clearOrganisation = function() {
  return this.setOrganisation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.NotificationSetting.prototype.hasOrganisation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Profile profile = 5;
 * @return {?proto.satys.domain.Profile}
 */
proto.satys.domain.NotificationSetting.prototype.getProfile = function() {
  return /** @type{?proto.satys.domain.Profile} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.Profile, 5));
};


/**
 * @param {?proto.satys.domain.Profile|undefined} value
 * @return {!proto.satys.domain.NotificationSetting} returns this
*/
proto.satys.domain.NotificationSetting.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.NotificationSetting} returns this
 */
proto.satys.domain.NotificationSetting.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.NotificationSetting.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.OverallMetric.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.OverallMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.OverallMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.OverallMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OverallMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    overallQuestionsList: jspb.Message.toObjectList(msg.getOverallQuestionsList(),
    proto.satys.domain.QuestionId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.OverallMetric}
 */
proto.satys.domain.OverallMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.OverallMetric;
  return proto.satys.domain.OverallMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.OverallMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.OverallMetric}
 */
proto.satys.domain.OverallMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.QuestionId;
      reader.readMessage(value,proto.satys.domain.QuestionId.deserializeBinaryFromReader);
      msg.addOverallQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.OverallMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.OverallMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.OverallMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.OverallMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverallQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.domain.QuestionId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuestionId overall_questions = 1;
 * @return {!Array<!proto.satys.domain.QuestionId>}
 */
proto.satys.domain.OverallMetric.prototype.getOverallQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.QuestionId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.QuestionId, 1));
};


/**
 * @param {!Array<!proto.satys.domain.QuestionId>} value
 * @return {!proto.satys.domain.OverallMetric} returns this
*/
proto.satys.domain.OverallMetric.prototype.setOverallQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.QuestionId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.QuestionId}
 */
proto.satys.domain.OverallMetric.prototype.addOverallQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.QuestionId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.OverallMetric} returns this
 */
proto.satys.domain.OverallMetric.prototype.clearOverallQuestionsList = function() {
  return this.setOverallQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.PriorityMetric.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.PriorityMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.PriorityMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.PriorityMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PriorityMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceQuestionsList: jspb.Message.toObjectList(msg.getReferenceQuestionsList(),
    proto.satys.domain.QuestionId.toObject, includeInstance),
    priorityQuestionsList: jspb.Message.toObjectList(msg.getPriorityQuestionsList(),
    proto.satys.domain.QuestionId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.PriorityMetric}
 */
proto.satys.domain.PriorityMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.PriorityMetric;
  return proto.satys.domain.PriorityMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.PriorityMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.PriorityMetric}
 */
proto.satys.domain.PriorityMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.QuestionId;
      reader.readMessage(value,proto.satys.domain.QuestionId.deserializeBinaryFromReader);
      msg.addReferenceQuestions(value);
      break;
    case 2:
      var value = new proto.satys.domain.QuestionId;
      reader.readMessage(value,proto.satys.domain.QuestionId.deserializeBinaryFromReader);
      msg.addPriorityQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.PriorityMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.PriorityMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.PriorityMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.PriorityMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.domain.QuestionId.serializeBinaryToWriter
    );
  }
  f = message.getPriorityQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.QuestionId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuestionId reference_questions = 1;
 * @return {!Array<!proto.satys.domain.QuestionId>}
 */
proto.satys.domain.PriorityMetric.prototype.getReferenceQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.QuestionId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.QuestionId, 1));
};


/**
 * @param {!Array<!proto.satys.domain.QuestionId>} value
 * @return {!proto.satys.domain.PriorityMetric} returns this
*/
proto.satys.domain.PriorityMetric.prototype.setReferenceQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.QuestionId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.QuestionId}
 */
proto.satys.domain.PriorityMetric.prototype.addReferenceQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.QuestionId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.PriorityMetric} returns this
 */
proto.satys.domain.PriorityMetric.prototype.clearReferenceQuestionsList = function() {
  return this.setReferenceQuestionsList([]);
};


/**
 * repeated QuestionId priority_questions = 2;
 * @return {!Array<!proto.satys.domain.QuestionId>}
 */
proto.satys.domain.PriorityMetric.prototype.getPriorityQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.QuestionId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.QuestionId, 2));
};


/**
 * @param {!Array<!proto.satys.domain.QuestionId>} value
 * @return {!proto.satys.domain.PriorityMetric} returns this
*/
proto.satys.domain.PriorityMetric.prototype.setPriorityQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.QuestionId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.QuestionId}
 */
proto.satys.domain.PriorityMetric.prototype.addPriorityQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.QuestionId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.PriorityMetric} returns this
 */
proto.satys.domain.PriorityMetric.prototype.clearPriorityQuestionsList = function() {
  return this.setPriorityQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.CohortMetric.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.CohortMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.CohortMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.CohortMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.CohortMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceQuestionsList: jspb.Message.toObjectList(msg.getReferenceQuestionsList(),
    proto.satys.domain.QuestionId.toObject, includeInstance),
    cohortQuestionsList: jspb.Message.toObjectList(msg.getCohortQuestionsList(),
    proto.satys.domain.QuestionId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.CohortMetric}
 */
proto.satys.domain.CohortMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.CohortMetric;
  return proto.satys.domain.CohortMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.CohortMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.CohortMetric}
 */
proto.satys.domain.CohortMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.satys.domain.QuestionId;
      reader.readMessage(value,proto.satys.domain.QuestionId.deserializeBinaryFromReader);
      msg.addReferenceQuestions(value);
      break;
    case 2:
      var value = new proto.satys.domain.QuestionId;
      reader.readMessage(value,proto.satys.domain.QuestionId.deserializeBinaryFromReader);
      msg.addCohortQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.CohortMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.CohortMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.CohortMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.CohortMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.satys.domain.QuestionId.serializeBinaryToWriter
    );
  }
  f = message.getCohortQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.satys.domain.QuestionId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QuestionId reference_questions = 1;
 * @return {!Array<!proto.satys.domain.QuestionId>}
 */
proto.satys.domain.CohortMetric.prototype.getReferenceQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.QuestionId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.QuestionId, 1));
};


/**
 * @param {!Array<!proto.satys.domain.QuestionId>} value
 * @return {!proto.satys.domain.CohortMetric} returns this
*/
proto.satys.domain.CohortMetric.prototype.setReferenceQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.QuestionId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.QuestionId}
 */
proto.satys.domain.CohortMetric.prototype.addReferenceQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.QuestionId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.CohortMetric} returns this
 */
proto.satys.domain.CohortMetric.prototype.clearReferenceQuestionsList = function() {
  return this.setReferenceQuestionsList([]);
};


/**
 * repeated QuestionId cohort_questions = 2;
 * @return {!Array<!proto.satys.domain.QuestionId>}
 */
proto.satys.domain.CohortMetric.prototype.getCohortQuestionsList = function() {
  return /** @type{!Array<!proto.satys.domain.QuestionId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.QuestionId, 2));
};


/**
 * @param {!Array<!proto.satys.domain.QuestionId>} value
 * @return {!proto.satys.domain.CohortMetric} returns this
*/
proto.satys.domain.CohortMetric.prototype.setCohortQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.satys.domain.QuestionId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.QuestionId}
 */
proto.satys.domain.CohortMetric.prototype.addCohortQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.satys.domain.QuestionId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.CohortMetric} returns this
 */
proto.satys.domain.CohortMetric.prototype.clearCohortQuestionsList = function() {
  return this.setCohortQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.domain.ResponseQuestionnaireMap.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ResponseQuestionnaireMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ResponseQuestionnaireMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ResponseQuestionnaireMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sourceQuestionnaire: (f = msg.getSourceQuestionnaire()) && proto.satys.domain.QuestionnaireId.toObject(includeInstance, f),
    targetQuestionnaire: (f = msg.getTargetQuestionnaire()) && proto.satys.domain.QuestionnaireId.toObject(includeInstance, f),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.satys.domain.ResponseQuestionnaireMap.Condition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap}
 */
proto.satys.domain.ResponseQuestionnaireMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ResponseQuestionnaireMap;
  return proto.satys.domain.ResponseQuestionnaireMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ResponseQuestionnaireMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap}
 */
proto.satys.domain.ResponseQuestionnaireMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.satys.domain.ResponseQuestionnaireMap.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = new proto.satys.domain.QuestionnaireId;
      reader.readMessage(value,proto.satys.domain.QuestionnaireId.deserializeBinaryFromReader);
      msg.setSourceQuestionnaire(value);
      break;
    case 4:
      var value = new proto.satys.domain.QuestionnaireId;
      reader.readMessage(value,proto.satys.domain.QuestionnaireId.deserializeBinaryFromReader);
      msg.setTargetQuestionnaire(value);
      break;
    case 5:
      var value = new proto.satys.domain.ResponseQuestionnaireMap.Condition;
      reader.readMessage(value,proto.satys.domain.ResponseQuestionnaireMap.Condition.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ResponseQuestionnaireMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ResponseQuestionnaireMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ResponseQuestionnaireMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSourceQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.satys.domain.QuestionnaireId.serializeBinaryToWriter
    );
  }
  f = message.getTargetQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.satys.domain.QuestionnaireId.serializeBinaryToWriter
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.satys.domain.ResponseQuestionnaireMap.Condition.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.ResponseQuestionnaireMap.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_QUESTION: 1,
  TYPE_METADATA: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.domain.ResponseQuestionnaireMap.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.domain.ResponseQuestionnaireMap.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    comparisonOperator: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.domain.ResponseQuestionnaireMap.Condition;
  return proto.satys.domain.ResponseQuestionnaireMap.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.domain.ResponseQuestionnaireMap.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {!proto.satys.domain.ResponseQuestionnaireMap.Condition.ComparisonOperator} */ (reader.readEnum());
      msg.setComparisonOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.domain.ResponseQuestionnaireMap.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.domain.ResponseQuestionnaireMap.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getComparisonOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.ComparisonOperator = {
  COMPARISON_OPERATOR_UNSPECIFIED: 0,
  COMPARISON_OPERATOR_EQUALS: 1,
  COMPARISON_OPERATOR_LESS_THAN: 2,
  COMPARISON_OPERATOR_GREATER_THAN: 3
};

/**
 * optional string key = 1;
 * @return {string}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Any value = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition} returns this
*/
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ComparisonOperator comparison_operator = 3;
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition.ComparisonOperator}
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.getComparisonOperator = function() {
  return /** @type {!proto.satys.domain.ResponseQuestionnaireMap.Condition.ComparisonOperator} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.satys.domain.ResponseQuestionnaireMap.Condition.ComparisonOperator} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.Condition.prototype.setComparisonOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Type type = 1;
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Type}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.getType = function() {
  return /** @type {!proto.satys.domain.ResponseQuestionnaireMap.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.satys.domain.ResponseQuestionnaireMap.Type} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional QuestionnaireId source_questionnaire = 3;
 * @return {?proto.satys.domain.QuestionnaireId}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.getSourceQuestionnaire = function() {
  return /** @type{?proto.satys.domain.QuestionnaireId} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.QuestionnaireId, 3));
};


/**
 * @param {?proto.satys.domain.QuestionnaireId|undefined} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
*/
proto.satys.domain.ResponseQuestionnaireMap.prototype.setSourceQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.clearSourceQuestionnaire = function() {
  return this.setSourceQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.hasSourceQuestionnaire = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional QuestionnaireId target_questionnaire = 4;
 * @return {?proto.satys.domain.QuestionnaireId}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.getTargetQuestionnaire = function() {
  return /** @type{?proto.satys.domain.QuestionnaireId} */ (
    jspb.Message.getWrapperField(this, proto.satys.domain.QuestionnaireId, 4));
};


/**
 * @param {?proto.satys.domain.QuestionnaireId|undefined} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
*/
proto.satys.domain.ResponseQuestionnaireMap.prototype.setTargetQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.clearTargetQuestionnaire = function() {
  return this.setTargetQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.hasTargetQuestionnaire = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Condition conditions = 5;
 * @return {!Array<!proto.satys.domain.ResponseQuestionnaireMap.Condition>}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.satys.domain.ResponseQuestionnaireMap.Condition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.satys.domain.ResponseQuestionnaireMap.Condition, 5));
};


/**
 * @param {!Array<!proto.satys.domain.ResponseQuestionnaireMap.Condition>} value
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
*/
proto.satys.domain.ResponseQuestionnaireMap.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.satys.domain.ResponseQuestionnaireMap.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.ResponseQuestionnaireMap.Condition}
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.satys.domain.ResponseQuestionnaireMap.Condition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.domain.ResponseQuestionnaireMap} returns this
 */
proto.satys.domain.ResponseQuestionnaireMap.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


goog.object.extend(exports, proto.satys.domain);
