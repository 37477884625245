declare global {
    interface Window {
        dataLayer: unknown[]
        gtag?: (...args: unknown[]) => void
    }
}

export const googleAnalytics = (id: string) => {

    const script = document.createElement("script")
    script.async = true
    script.src = "https://www.googletagmanager.com/gtag/js?id=" + id

    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []

    /**
     * Since Google Analytics really needs an Arguments object we'll pass
     * it that and add an eslint disable comment.
     *
     * To keep typings and prevent typescript to errror out
     * we added the `...args` argument.
     */
    function gtag(..._args: unknown[]) {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments)
    }

    window.gtag = gtag

    gtag("js", new Date())
    gtag("config", id, {
        send_page_view: false,
    })

    return gtag
}
