/**
 * @fileoverview gRPC-Web generated client stub for satys.usertaskmanager
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var satys_domain_domain_pb = require('../../satys/domain/domain_pb.js')
const proto = {};
proto.satys = {};
proto.satys.usertaskmanager = require('./usertaskmanager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.satys.usertaskmanager.UserTaskManagerAPIClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.satys.usertaskmanager.UserTaskManagerAPIPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.usertaskmanager.GetTaskInstancesRequest,
 *   !proto.satys.usertaskmanager.GetTaskInstancesResponse>}
 */
const methodDescriptor_UserTaskManagerAPI_get_task_instances = new grpc.web.MethodDescriptor(
  '/satys.usertaskmanager.UserTaskManagerAPI/get_task_instances',
  grpc.web.MethodType.UNARY,
  proto.satys.usertaskmanager.GetTaskInstancesRequest,
  proto.satys.usertaskmanager.GetTaskInstancesResponse,
  /**
   * @param {!proto.satys.usertaskmanager.GetTaskInstancesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.usertaskmanager.GetTaskInstancesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.usertaskmanager.GetTaskInstancesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.usertaskmanager.GetTaskInstancesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.usertaskmanager.UserTaskManagerAPIClient.prototype.get_task_instances =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/get_task_instances',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_get_task_instances,
      callback);
};


/**
 * @param {!proto.satys.usertaskmanager.GetTaskInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.usertaskmanager.GetTaskInstancesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.usertaskmanager.UserTaskManagerAPIPromiseClient.prototype.get_task_instances =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/get_task_instances',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_get_task_instances);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.usertaskmanager.ClaimTaskInstanceRequest,
 *   !proto.satys.usertaskmanager.ClaimTaskInstanceResponse>}
 */
const methodDescriptor_UserTaskManagerAPI_claim_task_instance = new grpc.web.MethodDescriptor(
  '/satys.usertaskmanager.UserTaskManagerAPI/claim_task_instance',
  grpc.web.MethodType.UNARY,
  proto.satys.usertaskmanager.ClaimTaskInstanceRequest,
  proto.satys.usertaskmanager.ClaimTaskInstanceResponse,
  /**
   * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.usertaskmanager.ClaimTaskInstanceResponse.deserializeBinary
);


/**
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.usertaskmanager.ClaimTaskInstanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.usertaskmanager.ClaimTaskInstanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.usertaskmanager.UserTaskManagerAPIClient.prototype.claim_task_instance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/claim_task_instance',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_claim_task_instance,
      callback);
};


/**
 * @param {!proto.satys.usertaskmanager.ClaimTaskInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.usertaskmanager.ClaimTaskInstanceResponse>}
 *     Promise that resolves to the response
 */
proto.satys.usertaskmanager.UserTaskManagerAPIPromiseClient.prototype.claim_task_instance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/claim_task_instance',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_claim_task_instance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.usertaskmanager.CompleteTaskInstanceRequest,
 *   !proto.satys.usertaskmanager.CompleteTaskInstanceResponse>}
 */
const methodDescriptor_UserTaskManagerAPI_complete_task_instance = new grpc.web.MethodDescriptor(
  '/satys.usertaskmanager.UserTaskManagerAPI/complete_task_instance',
  grpc.web.MethodType.UNARY,
  proto.satys.usertaskmanager.CompleteTaskInstanceRequest,
  proto.satys.usertaskmanager.CompleteTaskInstanceResponse,
  /**
   * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.usertaskmanager.CompleteTaskInstanceResponse.deserializeBinary
);


/**
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.usertaskmanager.CompleteTaskInstanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.usertaskmanager.CompleteTaskInstanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.usertaskmanager.UserTaskManagerAPIClient.prototype.complete_task_instance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/complete_task_instance',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_complete_task_instance,
      callback);
};


/**
 * @param {!proto.satys.usertaskmanager.CompleteTaskInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.usertaskmanager.CompleteTaskInstanceResponse>}
 *     Promise that resolves to the response
 */
proto.satys.usertaskmanager.UserTaskManagerAPIPromiseClient.prototype.complete_task_instance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/complete_task_instance',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_complete_task_instance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.usertaskmanager.StartEventRequest,
 *   !proto.satys.usertaskmanager.StartEventResponse>}
 */
const methodDescriptor_UserTaskManagerAPI_start_event = new grpc.web.MethodDescriptor(
  '/satys.usertaskmanager.UserTaskManagerAPI/start_event',
  grpc.web.MethodType.UNARY,
  proto.satys.usertaskmanager.StartEventRequest,
  proto.satys.usertaskmanager.StartEventResponse,
  /**
   * @param {!proto.satys.usertaskmanager.StartEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.usertaskmanager.StartEventResponse.deserializeBinary
);


/**
 * @param {!proto.satys.usertaskmanager.StartEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.usertaskmanager.StartEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.usertaskmanager.StartEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.usertaskmanager.UserTaskManagerAPIClient.prototype.start_event =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/start_event',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_start_event,
      callback);
};


/**
 * @param {!proto.satys.usertaskmanager.StartEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.usertaskmanager.StartEventResponse>}
 *     Promise that resolves to the response
 */
proto.satys.usertaskmanager.UserTaskManagerAPIPromiseClient.prototype.start_event =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/start_event',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_start_event);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.usertaskmanager.CancelProcessInstanceRequest,
 *   !proto.satys.usertaskmanager.CancelProcessInstanceResponse>}
 */
const methodDescriptor_UserTaskManagerAPI_cancel_process_instance = new grpc.web.MethodDescriptor(
  '/satys.usertaskmanager.UserTaskManagerAPI/cancel_process_instance',
  grpc.web.MethodType.UNARY,
  proto.satys.usertaskmanager.CancelProcessInstanceRequest,
  proto.satys.usertaskmanager.CancelProcessInstanceResponse,
  /**
   * @param {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.usertaskmanager.CancelProcessInstanceResponse.deserializeBinary
);


/**
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.usertaskmanager.CancelProcessInstanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.usertaskmanager.CancelProcessInstanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.usertaskmanager.UserTaskManagerAPIClient.prototype.cancel_process_instance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/cancel_process_instance',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_cancel_process_instance,
      callback);
};


/**
 * @param {!proto.satys.usertaskmanager.CancelProcessInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.usertaskmanager.CancelProcessInstanceResponse>}
 *     Promise that resolves to the response
 */
proto.satys.usertaskmanager.UserTaskManagerAPIPromiseClient.prototype.cancel_process_instance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.usertaskmanager.UserTaskManagerAPI/cancel_process_instance',
      request,
      metadata || {},
      methodDescriptor_UserTaskManagerAPI_cancel_process_instance);
};


module.exports = proto.satys.usertaskmanager;

