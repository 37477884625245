import m from "mithril"
import state from "/src/state"
import DefaultLayout from "/src/layout/default_layout"

import { guardRoute } from "./guard"
import { translate as t } from "/src/i18n"
import { analytics } from "/src/extensions"
import routes, { DEFAULT_ROUTE, Route } from "./routes"
import { getUser, getRole, getUserRoles, setRole } from "/src/auth"
import { setCurrentPathname } from "../utils/set_url_params"

const actions = require("/src/actions/index.ls")
const meta = require("/src/htmlmeta.ls")

const ns = "routes"

export let currentRoute: Route & { vnode?: m.Vnode<unknown>, path: string }

export const getCurrentPathFromWindow = () => {
    let currentHash = window.location.hash
    if (currentHash.charAt(0) === "#") {
        currentHash = currentHash.substring(1)
    }

    let path = currentHash
    let search = ""
    if (currentHash.includes("?")) {
        [path, search] = currentHash.split("?")
    }
    const params = new URLSearchParams(search || window.location.search)
    return { path, params }
}

export function useRouter(prefix: string = "#") {
    m.route.prefix = prefix

    function routeToMithril(path: string, route: Route) {
        return {
            async onmatch(args: Record<string, unknown>, requestedPath: string) {
                if (m.route.get() === requestedPath) {
                    return new Promise(function () { })
                }
                currentRoute = { ...route, path }
                setCurrentPathname(path)

                state.user = await getUser()
                if (args.organisation_domain) {
                    const userRoles = await getUserRoles()
                    const role = userRoles.find((role) => role.getOrganisation().getDomain() === args.organisation_domain)

                    if (role) {
                        setRole(role)
                    } else {
                        // You don't have access to this organisation
                        return m.route.set("/select_role", { replace: true })
                    }
                }

                await guardRoute(route)
                state.role = getRole()

                analytics.event("page_view", {
                    page_title: route.title,
                    page_path: requestedPath,
                })

                const Component = await getCompponentFromRoute(route)

                return {
                    view: (vnode: m.Vnode<unknown>) => {
                        currentRoute.vnode = m(
                            Component, Object.assign({}, vnode.attrs, route.attrs),
                        )
                        return currentRoute.vnode
                    },
                }
            },

            render(vnode: m.Vnode<unknown>) {
                meta.title.page = t(route.title, ns)
                vnode.attrs = Object.assign({}, vnode.attrs, route.attrs)
                const Layout = route.layout || DefaultLayout
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TS2769
                return m(Layout, { state, actions }, vnode)
            },
        }
    }

    async function init(element: HTMLElement) {
        // Convert routes to be compatible with the Mitrhil.js router
        const mithrilRoutes: Record<string, m.RouteResolver> = {}
        for (const [path, route] of Object.entries(routes)) {
            mithrilRoutes[path] = routeToMithril(path, route)
        }

        // Guard the intital route
        const currentPath = getCurrentPathFromWindow().path || DEFAULT_ROUTE
        currentRoute = { ...routes[currentPath], path: currentPath }
        if (currentRoute) {
            await guardRoute(currentRoute)
        }

        m.route(element, DEFAULT_ROUTE, mithrilRoutes)
    }

    async function initError(
        error: Error,
        element: HTMLElement,
        errorPath: string = "/error",
    ) {
        m.route(element, errorPath, {
            errorPath: {
                async onmatch(_: Record<string, unknown>, requestedPath: string) {
                    if (requestedPath !== m.route.get()) {
                        analytics.event("page_view", {
                            page_title: "Error",
                            page_path: requestedPath,
                        })
                    }
                    return (await import("/src/error_page")).default
                },
                render(vnode) {
                    meta.title.page = "Error"
                    console.error(error)
                    vnode.attrs = Object.assign(vnode.attrs, { "error": error })
                    return [vnode]
                },
            },
        })
    }

    return {
        initRouter: init,
        initRouterError: initError,
        currentRoute,
    }
}

async function getCompponentFromRoute(route: Route) {
    let Component = await route.component
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Object.hasOwn(Component, "default")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Component = Component.default
    }
    return Component
}
