/**
 * Returns a promise resolving when the given condition is met.
 */
export const until = (condition: () => boolean) => {
    const poll = (resolve: (_?: unknown) => void) => {
        if (condition()) {
            resolve()
        } else {
            setTimeout(() => poll(resolve), 250)
        }
    }

    return new Promise(poll)
}
