import m from "mithril"
import { Button } from "polythene-mithril"
import { ButtonCSS } from "polythene-css"
import MithrilTsx from "/src/mithril-tsx"

interface Attrs {
    icon?: string | JSX.Element | m.Children,
    before?: string,
    className?: string,
    loading?: boolean,
    disabled?: boolean,
    [key: string]: unknown,
}

class PrimaryButton extends MithrilTsx<Attrs> {
    view({
        children,
        attrs: {
            icon,
            disabled = false,
            className = "",
            before,
            loading = false,
            ...attrs
        },
    }: this["Vnode"]) {
        if (typeof icon === "string") {
            icon = <i class="before material-icons">{ icon }</i>
        }

        if (loading) {
            disabled = true
            icon = <i class="before material-icons">cached</i>
        }

        if (icon) {
            className += " has-icon"
        }

        return m(Button, {
            before: icon || before,
            className: `primary-button ${className}`,
            extraWide: true,
            disabled: disabled,
            ...attrs,
        }, children)
    }
}

ButtonCSS.addStyle(".primary-button", {
    color_light_wash_background: "rgba(186,46,109)",
    color_light_text: "rgba(186,46,109)",
    color_dark_background: "rgba(186,46,109)",
    color_dark_text: "white",
})

export default PrimaryButton
