.breadcrumbs :global
    overflow: visible

    .crumbs
        display: flex
        align-items: center
        white-space: nowrap
        color: #BABABA

        .crumb-item
            padding: 0 5px
            font-size: 1rem
            line-height: 1.5rem
            cursor: pointer
            color: #BABABA

            &--margin-top
                margin-top: 3px

            svg
                width: 0.8rem

        .parent
            display: inline-block
            max-width: 150px
            overflow: hidden
            text-overflow: ellipsis
            color: #bababa

            &:active, &:focus, &:hover
                color: #bababa

                display: inline
                max-width: none
                text-decoration: underline

        .seperator
            font-size: 1rem
            line-height: 1rem
            margin-top: 1px

        .current .crumb-item
            color: #505050
            font-weight: bold

        .dropdown
            position: absolute
            display: none
            // the padding of .dropdown-item - the padding of .crumb-item
            left: calc(-1rem + 5px)
            top: 150%
            z-index: 10
            min-width: 200px
            max-height: 80vh
            overflow-x: hidden
            overflow-y: auto
            background-color: #FFFFFF
            border: 1px solid var(--light-grey)
            border-radius: 5px
            box-shadow: 0px 8px 15px -2px rgba(0, 0, 0, 0.1)

            &.active
                display: block

        .dropdown-item
            display: block
            padding: 1rem
            font-weight: bold
            cursor: pointer
            color: #BABABA

            &:hover, &.active
                background-color: var(--satys-light-accent)
                color: #505050

@media print
    .bread-crumbs :global
        font-size: 20px

        .crumbs
            a
                display: inline
                max-width: none
                text-decoration: none

            span:nth-child(1)
                display: none

            span:not(:last-child) 
                overflow: hidden
                text-overflow: ellipsis
