import m from "mithril"

let _currentPathname = ""

export function setCurrentPathname(pathname: string) {
    _currentPathname = pathname
}

export function setUrlParams(parameters: Record<string, unknown>) {
    const params = Object.assign({}, m.route.param(), parameters)
    m.route.set(m.buildPathname(_currentPathname, params))
}
