import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"

export default class TableRow extends MithrilTsx<Record<string, unknown>> {

    view(vnode: this["Vnode"]) {
        return (
            <tr {...vnode.attrs}>
                { vnode.children }
            </tr>
        )
    }
}
