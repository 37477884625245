m = require 'mithril'
require 'google-protobuf/google/protobuf/struct_pb'
{datanalysis_pb} = require 'datanalysis-client-js'
{usertaskmanager_pb, domain_pb} = require 'usertaskmanager-client-js'

{GetCurrentRoleRequest, GetProcessesRequest} = datanalysis_pb
{
    CompleteTaskInstanceRequest,
    GetTaskInstancesRequest,
    StartEventRequest,
    CancelProcessInstanceRequest,
    ClaimTaskInstanceRequest
} = usertaskmanager_pb
{
    datanalysis_stub,
    datanalysis_promise_stub,
    usertaskmanager_stub,
    usertaskmanager_promise_stub,
} = require '/src/extensions'
{status, grpc} = require '/src/utils'
state = require '/src/state' .default

Actions = (state=state) ->

    get_processes_status: status.IDLE
    get_processes: (callback) ->
        if @get_processes_status isnt status.IDLE then return
        @get_processes_status = status.LOADING

        datanalysis_stub.get_processes do
            new GetProcessesRequest
            {}
            (err, resp) ~>
                if err
                    grpc.handle_err err
                    @get_processes_status = status.FAILED
                else
                    @get_processes_status = status.SUCCEEDED
                    state.processes = resp.getProcessesList!
                if callback then callback!
                m.redraw!

    get_process_by_names: (product_name, process_name) ->
        return state.processes.find (process_) ~>
            process_.getName! is process_name and process_.getProduct!getName! is product_name

    get_role_status: status.IDLE
    get_role: (callback) ->
        @get_role_status = status.LOADING
        datanalysis_stub.get_current_role do
            new GetCurrentRoleRequest
            {}
            (err, resp) ~>
                if err
                    @get_role_status = status.FAILED
                    grpc.handle_err err
                    m.redraw!
                else
                    @get_role_status = status.SUCCEEDED
                    state.role = resp.getRole!
                if callback then callback!

    start_event_status: status.IDLE
    start_event_curried: (process) -> (callback) ~> @start_event process, callback
    start_event: (process, callback) ->
        @start_event_status = status.LOADING
        small_process = process.clone!
        small_process.setBpmn void

        req = new StartEventRequest
        req.setProcess small_process

        usertaskmanager_stub.start_event do
            req
            {}
            (err, resp) ~>
                if err
                    @start_event_status = status.FAILED
                    grpc.handle_err err
                else
                    setTimeout ~>
                        @start_event_status = status.SUCCEEDED
                        @get_tasks_status = status.IDLE
                        @get_tasks process
                    , 1000
                if callback then callback!
                m.redraw!

    cancel_process_instance_status: status.IDLE
    cancel_process_instance: (task_inst, callback) ->
        @cancel_process_instance_status = status.LOADING

        req = new CancelProcessInstanceRequest
        req.setProcessInstance task_inst.getProcessInstance!
        usertaskmanager_stub.cancel_process_instance req, {},
            (err, resp) ~>
                if err
                    @cancel_process_instance_status = status.FAILED
                    grpc.handle_err err
                else
                    @cancel_process_instance_status = status.SUCCEEDED
                    @get_tasks_status = status.IDLE
                    @get_tasks task_inst.getProcessInstance!getProcess!
                if callback then callback!
                m.redraw!

    get_tasks_status: status.IDLE
    get_tasks_curried: (process) -> (silent, callback) ~> @get_tasks process, silent, callback
    get_tasks: (process, silent, callback) ->
        if not silent
            if @get_tasks_status isnt status.IDLE then return
            @get_tasks_status = status.LOADING
        # Get user TaskInstances
        req = new GetTaskInstancesRequest
        req.setProcess process

        usertaskmanager_stub.get_task_instances do
            req
            {}
            (err, resp) ~>
                if err
                    if not silent
                        grpc.handle_err err
                        @get_tasks_status = status.FAILED
                else
                    state.tasks = resp.getTaskInstancesList!
                    @get_tasks_status = status.SUCCEEDED
                if callback then callback!
                m.redraw!

    claim_task_status: status.IDLE
    claim_task: (task_inst, user=state.user) ->
        @claim_task_status = status.LOADING

        req = new ClaimTaskInstanceRequest
        req.setTaskInstance task_inst
        usertaskmanager_stub.claim_task_instance req, {},
            (err, resp) ~>
                if err
                    @claim_task_status = status.FAILED
                    grpc.handle_err err
                else
                    @claim_task_status = status.SUCCEEDED
                    task_inst.setAssignee user?email
                    # Refresh the list of task_instances
                    @get_tasks_status = status.IDLE
                    @get_tasks task_inst.getProcessInstance!getProcess!
                m.redraw!

    complete_task_status: status.IDLE
    complete_task: ({task_inst, success_cb, error_cb, vars, tries_left=3} = {}) ->
        # task_inst     The domain.TaskInstance to be completed
        # success_cb    Function to be executed after a succesful repsonse
        # error_cb      Fucntion to be executed after a failed response, only invoked after
        #               last/definite fail
        # vars          An object, these variables are injected into the workflow
        # tries_left    Amount of tries left when invoking, defaults to 3 tries
        #
        # Automatically retries failed requests twice again, as it is hard to re-execute
        # the whole task again.
        @complete_task_status = status.LOADING
        tries_left -= 1

        task_inst.getProcessInstance!setVariables!
        if vars
            pojo = {}
            for key, val of vars
                try
                    pojo[key] = to_object val
                catch
                    pojo[key] = val
            struct = proto.google.protobuf.Struct.fromJavaScript pojo
            task_inst.getProcessInstance!setVariables struct

        req = new CompleteTaskInstanceRequest
        req.setTaskInstance task_inst
        usertaskmanager_stub.complete_task_instance req, {},
            (err, resp) ~>
                if err and tries_left > 0
                    setTimeout ~>
                        @complete_task {task_inst, success_cb, error_cb, vars, tries_left}
                    , 1000
                else if err
                    @complete_task_status = status.FAILED
                    grpc.handle_err err
                    if error_cb then error_cb!
                else
                    @complete_task_status = status.SUCCEEDED
                    @get_tasks_status = status.IDLE
                    @get_tasks task_inst.getProcessInstance!getProcess!
                    if success_cb then success_cb!
                m.redraw!

module.exports = Actions
