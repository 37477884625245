import grpcWeb from "grpc-web"
import state from "/src/state"
import { Message } from "google-protobuf"
import { ConstructableRequest, Invoker } from "./types"

const fakeanalysisEndpoints = [
    "get_my_performance_indicators",
    "get_rating_stats",
    "get_active_channels",
    "get_rating_stats",
    "get_responses_count",
    "get_priority_stats",
    "get_suborganisation_ranking",
    "get_channel_ranking",
    "get_answers_with_sentiment",
    "get_labels",
    "get_hourly_pattern",
    "get_rating_stats_timeseries",
    "get_parent_rating_stats_timeseries",
    "get_answers_count",
    "get_multiple_organisation_question_comparison",
    "get_suborganisation_rankings",
    "get_measurements",
]

export class EnvironmentRequestInterceptor {
    intercept (request: grpcWeb.Request<Message, Message>, invoker: Invoker): grpcWeb.UnaryResponse<Message, Message> {
        const endpoint = request.getMethodDescriptor().getName().split("/").at(-1)
        let metadata = request.getMetadata()
        if (state.dashboard.is_demo && fakeanalysisEndpoints.includes(endpoint)) {
            metadata = Object.assign({}, request.getMetadata(), { "Environment": "faker" })
        }
        return invoker(
            // Make a copy with the new metadata, as changing the metadata by reference also changes it for other calls
            new (request.constructor as ConstructableRequest)(
                request.getRequestMessage(),
                request.getMethodDescriptor(),
                metadata,
                request.getCallOptions(),
            ),
        )
    }
}
