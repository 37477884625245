import m from "mithril"
import { Icon } from "polythene-mithril"
import MithrilTsx from "/src/mithril-tsx"

interface Attrs {
    [key: string]: unknown,
}
class MaterialIcon extends MithrilTsx<Attrs> {

    view(vnode: this["Vnode"]) {
        const { icon, ...other } = vnode.attrs
        return m(Icon, Object.assign({}, {
            svg: m("i.before.material-icons", icon),
            ...other,
        }, vnode.children))
    }
}

export default MaterialIcon
