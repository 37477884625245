import m from "mithril"
import { Tabs } from "polythene-mithril"
import { Options } from "polythene-core-tabs"
import { TabsCSS } from "polythene-css"
import MithrilTsx from "/src/mithril-tsx"

class CardTabs extends MithrilTsx<Options> {
    view({ attrs, children }: this["Vnode"]) {
        const {
            className = "",
            all = { ink: false },
            menu = true,
            tabs,
            ...other
        } = attrs

        return m(Tabs, {
            all: all,
            className: `card-tabs ${className}`,
            menu: menu,
            tabs: tabs,
            ...other,
        }, children)
    }
}

export default CardTabs

TabsCSS.addStyle(".card-tabs", {
    menu_tab_height: 36,
    manu_tab_icon_label_height: 36,
})
