import m from "mithril"
import { AuthStatus } from "/src/auth/types"
import EmptyLayout from "/src/layout/empty_layout"
import DefaultLayout from "/src/layout/default_layout"

let actions = require("/src/actions/index.ls")

if (process.env.NODE_ENV === "test") {
    actions = require("/mock/actions/index.ls")
}

export const DEFAULT_ROUTE = "/select_role"

export interface Route {
    component: m.ComponentTypes | m.ClassComponent | Promise<m.ComponentTypes | m.ClassComponent>,
    layout?: m.ComponentTypes,
    title: string,
    attrs?: Record<string, unknown>,
    auth: AuthStatus,
}

const routes: Record<string, Route> = {
    "/error": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/error_page"),
        layout: EmptyLayout,
        title: "error",
        auth: AuthStatus.UNAUTHENTICATED,
    },

    // Auth
    "/logout": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/logout"),
        layout: EmptyLayout,
        title: "Logout",
        auth: AuthStatus.UNAUTHENTICATED,
    },
    "/register": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/signup"),
        layout: EmptyLayout,
        title: "register",
        auth: AuthStatus.UNAUTHENTICATED,
    },
    "/verify_email": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/verify_email"),
        layout: EmptyLayout,
        title: "verifyEmail",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.NOT_VERIFIED,
    },
    "/unorganised": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/unorganised"),
        layout: EmptyLayout,
        title: "unorganised",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.NO_ROLES,
    },
    "/select_role": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/select_role"),
        layout: EmptyLayout,
        title: "selectRole",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.HAS_ROLES,
    },

    // Profile
    "/profile/notifications": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: cannot find module for .ls file
        component: require("/src/profile/index.ls"),
        layout: DefaultLayout,
        title: "profile.notifications",
        attrs: {
            actions: actions,
            tab: "notifications",
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/profile/organisation": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: cannot find module for .ls file
        component: require("/src/profile/index.ls"),
        layout: DefaultLayout,
        title: "profile.organisation",
        attrs: {
            actions: actions,
            tab: "organisation",
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/profile": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: cannot find module for .ls file
        component: require("/src/profile/index.ls"),
        layout: DefaultLayout,
        title: "profile",
        attrs: {
            actions: actions,
            tab: "profile",
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },

    // Process
    "/:organisation_domain/process/:product_name/:process_name/:task_name": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/process"),
        title: "process",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/:organisation_domain/process/:product_name/:process_name": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/process"),
        title: "process",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/:organisation_domain/process": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/process"),
        title: "process",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },

    // Dashboard
    "/:organisation_domain/startblock/dashboarding/view_dashboard": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/views/startblock/dashboarding/view_dashboard"),
        title: "dashboard",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/:organisation_domain/startblock/indepth_view/select_indepth_view": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/views/startblock/indepth_view/select_indepth_view"),
        title: "selectIndepthView",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/:organisation_domain/startblock/indepth_view/view_indepth_view": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Type is not assignable Route.component
        component: import("/src/views/startblock/indepth_view/view_indepth_view"),
        title: "viewIndepthView",
        attrs: {
            actions: actions,
        },
        auth: AuthStatus.ACTIVE_ROLE,
    },

    // Manage measurements
    "/:organisation_domain/startblock/manage": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: cannot find module for .ls file
        component: require("/src/views/startblock/manage/index.ls"),
        title: "manage.index",
        attrs: { actions: actions },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/:organisation_domain/startblock/manage/measurements": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: cannot find module for .ls file
        component: require("/src/views/startblock/manage/measurements/index.ls"),
        title: "manage.measurements",
        attrs: { actions: actions },
        auth: AuthStatus.ACTIVE_ROLE,
    },
    "/:organisation_domain/startblock/manage/measurements/questionnaires": {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: cannot find module for .ls file
        component: require("/src/views/startblock/manage/measurements/questionnaires.ls"),
        title: "manage.measurements.questionnaires",
        attrs: { actions: actions },
        auth: AuthStatus.ACTIVE_ROLE,
    },
}

export default routes
