import { toPng, toJpeg } from "html-to-image"

type AllowedFileTypes = "png" | "jpeg"

/**
 * Converts an HTML element to an image and initiates a download of that image.
 *
 * @param node - The HTML element to be converted.
 * @param name - The name of the downloaded image file.
 * @param fileType - The type of the image, either "png" or "jpeg". Defaults to "png".
 */
export async function convertHtmlToImageAndDownload(
    node: HTMLElement,
    name: string,
    fileType: AllowedFileTypes = "png",
) {
    const dataUrl = await getDataUrlFromNodeByFileType(node, fileType)
    downloadDataUrl(dataUrl, `${name}.${fileType}`)
    URL.revokeObjectURL(dataUrl)
}

/**
 * Converts the given HTML node to a data URL of the specified file type.
 *
 * @param node - The HTML element to be converted.
 * @param fileType - The type of the image, either "png" or "jpeg".
 */
async function getDataUrlFromNodeByFileType(node: HTMLElement, fileType: AllowedFileTypes) {
    const converters = {
        "png": toPng,
        "jpeg": toJpeg,
    }

    return await converters[fileType](node)
}

/**
 * Initiates a download for the given data URL.
 *
 * @param dataUrl - The data URL of the image.
 * @param name - The name of the downloaded file.
 */
function downloadDataUrl(dataUrl: string, name: string) {
    const link = document.createElement("a")
    link.download = name
    link.href = dataUrl
    link.click()
}
