/**
 * @fileoverview gRPC-Web generated client stub for satys.processor
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var satys_domain_domain_pb = require('../../satys/domain/domain_pb.js')

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js')
const proto = {};
proto.satys = {};
proto.satys.processor = require('./processor_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.satys.processor.ProcessorAPIClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.satys.processor.ProcessorAPIPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.processor.StartProcessRequest,
 *   !proto.satys.processor.StartProcessResponse>}
 */
const methodDescriptor_ProcessorAPI_start_process = new grpc.web.MethodDescriptor(
  '/satys.processor.ProcessorAPI/start_process',
  grpc.web.MethodType.UNARY,
  proto.satys.processor.StartProcessRequest,
  proto.satys.processor.StartProcessResponse,
  /**
   * @param {!proto.satys.processor.StartProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.processor.StartProcessResponse.deserializeBinary
);


/**
 * @param {!proto.satys.processor.StartProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.processor.StartProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.processor.StartProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.processor.ProcessorAPIClient.prototype.start_process =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/start_process',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_start_process,
      callback);
};


/**
 * @param {!proto.satys.processor.StartProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.processor.StartProcessResponse>}
 *     Promise that resolves to the response
 */
proto.satys.processor.ProcessorAPIPromiseClient.prototype.start_process =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/start_process',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_start_process);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.processor.WatchProcessRequest,
 *   !proto.satys.processor.WatchProcessResponse>}
 */
const methodDescriptor_ProcessorAPI_watch_process = new grpc.web.MethodDescriptor(
  '/satys.processor.ProcessorAPI/watch_process',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.satys.processor.WatchProcessRequest,
  proto.satys.processor.WatchProcessResponse,
  /**
   * @param {!proto.satys.processor.WatchProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.processor.WatchProcessResponse.deserializeBinary
);


/**
 * @param {!proto.satys.processor.WatchProcessRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.satys.processor.WatchProcessResponse>}
 *     The XHR Node Readable Stream
 */
proto.satys.processor.ProcessorAPIClient.prototype.watch_process =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/satys.processor.ProcessorAPI/watch_process',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_watch_process);
};


/**
 * @param {!proto.satys.processor.WatchProcessRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.satys.processor.WatchProcessResponse>}
 *     The XHR Node Readable Stream
 */
proto.satys.processor.ProcessorAPIPromiseClient.prototype.watch_process =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/satys.processor.ProcessorAPI/watch_process',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_watch_process);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.processor.GetProcessStateRequest,
 *   !proto.satys.processor.GetProcessStateResponse>}
 */
const methodDescriptor_ProcessorAPI_get_process_state = new grpc.web.MethodDescriptor(
  '/satys.processor.ProcessorAPI/get_process_state',
  grpc.web.MethodType.UNARY,
  proto.satys.processor.GetProcessStateRequest,
  proto.satys.processor.GetProcessStateResponse,
  /**
   * @param {!proto.satys.processor.GetProcessStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.processor.GetProcessStateResponse.deserializeBinary
);


/**
 * @param {!proto.satys.processor.GetProcessStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.processor.GetProcessStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.processor.GetProcessStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.processor.ProcessorAPIClient.prototype.get_process_state =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/get_process_state',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_get_process_state,
      callback);
};


/**
 * @param {!proto.satys.processor.GetProcessStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.processor.GetProcessStateResponse>}
 *     Promise that resolves to the response
 */
proto.satys.processor.ProcessorAPIPromiseClient.prototype.get_process_state =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/get_process_state',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_get_process_state);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.processor.GetProcessVariablesRequest,
 *   !proto.satys.processor.GetProcessVariablesResponse>}
 */
const methodDescriptor_ProcessorAPI_get_process_variables = new grpc.web.MethodDescriptor(
  '/satys.processor.ProcessorAPI/get_process_variables',
  grpc.web.MethodType.UNARY,
  proto.satys.processor.GetProcessVariablesRequest,
  proto.satys.processor.GetProcessVariablesResponse,
  /**
   * @param {!proto.satys.processor.GetProcessVariablesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.processor.GetProcessVariablesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.processor.GetProcessVariablesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.processor.GetProcessVariablesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.processor.GetProcessVariablesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.processor.ProcessorAPIClient.prototype.get_process_variables =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/get_process_variables',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_get_process_variables,
      callback);
};


/**
 * @param {!proto.satys.processor.GetProcessVariablesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.processor.GetProcessVariablesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.processor.ProcessorAPIPromiseClient.prototype.get_process_variables =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/get_process_variables',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_get_process_variables);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.processor.CompleteTaskRequest,
 *   !proto.satys.processor.CompleteTaskResponse>}
 */
const methodDescriptor_ProcessorAPI_complete_task = new grpc.web.MethodDescriptor(
  '/satys.processor.ProcessorAPI/complete_task',
  grpc.web.MethodType.UNARY,
  proto.satys.processor.CompleteTaskRequest,
  proto.satys.processor.CompleteTaskResponse,
  /**
   * @param {!proto.satys.processor.CompleteTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.processor.CompleteTaskResponse.deserializeBinary
);


/**
 * @param {!proto.satys.processor.CompleteTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.processor.CompleteTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.processor.CompleteTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.processor.ProcessorAPIClient.prototype.complete_task =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/complete_task',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_complete_task,
      callback);
};


/**
 * @param {!proto.satys.processor.CompleteTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.processor.CompleteTaskResponse>}
 *     Promise that resolves to the response
 */
proto.satys.processor.ProcessorAPIPromiseClient.prototype.complete_task =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/complete_task',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_complete_task);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.processor.GetRunningProcessesRequest,
 *   !proto.satys.processor.GetRunningProcessesResponse>}
 */
const methodDescriptor_ProcessorAPI_get_running_processes = new grpc.web.MethodDescriptor(
  '/satys.processor.ProcessorAPI/get_running_processes',
  grpc.web.MethodType.UNARY,
  proto.satys.processor.GetRunningProcessesRequest,
  proto.satys.processor.GetRunningProcessesResponse,
  /**
   * @param {!proto.satys.processor.GetRunningProcessesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.processor.GetRunningProcessesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.processor.GetRunningProcessesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.processor.GetRunningProcessesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.processor.GetRunningProcessesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.processor.ProcessorAPIClient.prototype.get_running_processes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/get_running_processes',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_get_running_processes,
      callback);
};


/**
 * @param {!proto.satys.processor.GetRunningProcessesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.processor.GetRunningProcessesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.processor.ProcessorAPIPromiseClient.prototype.get_running_processes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.processor.ProcessorAPI/get_running_processes',
      request,
      metadata || {},
      methodDescriptor_ProcessorAPI_get_running_processes);
};


module.exports = proto.satys.processor;

