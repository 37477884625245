import { Profile, Role, Task, User } from "@satys/contracts/satys/domain/domain_pb"

export interface AuthMetadata {
    authorization?: string
    role?: string
    organisation?: string
}

export interface Token {
    id_token?: string
    access_token?: string
    scope?: string
}

export interface AuthState {
    status: AuthStatus,
    user?: User
    role?: Role
    roles: Role[]
    profile?: Profile
    roleTasks: Task[]
}

export interface LoginAppState {
    target: string
}

export enum AuthStatus {
    UNAUTHENTICATED = 0,  // Not authenticated at all
    NOT_VERIFIED = 1, // Authenticated, but email address is unverified
    NO_ROLES = 2,  // Authenticated, but no roles assigned to user (or user not in Satys database)
    HAS_ROLES = 3,  // Authenticated with an existing user in our system which has roles
    ACTIVE_ROLE = 4,  // Authenticated with a role set
}

export class LoginRedirect extends Error {}
