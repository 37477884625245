m = require "mithril"
{capitalize} = require "prelude-ls"

card = require "/src/components/card/index.tsx" .default
table = require "/src/components/table/index.tsx" .default
Spinner = require '/src/components/polythene/MaterialDesignSpinner.tsx' .default
{translate: t, getLocale} = require "/src/i18n"
{grpc, get_namespace, status, timestamp_pb_to_date_str} = require "/src/utils"
{datanalysis_pb, domain_pb} = require "datanalysis-client-js"
{
    GetQuestionnairesRequest,
    GetResponsesCountRequest,
    GetQuestionsRequest,
    GetQuestionnaireUrlsRequest,
    GetQuestionnaireUrlsResponse,
} = datanalysis_pb
{Organisation, Measurement} = domain_pb

state = require "/src/state" .default

ns = get_namespace __filename

module.exports =

    oninit: (vnode) ->
        {domain, name, actions} = vnode.attrs
        @actions = actions state
        @status = status.LOADING

        @headers =
          * column: 'questionnaire'
            title: t 'questionnaire', ns |> capitalize
          * column: 'responses',
            title: t 'responses', ns |> capitalize
          * column: 'questions',
            title: t 'questions', ns |> capitalize
          * column: 'start_date'
            title: t 'start', ns |> capitalize
          * column: 'channel'
            title: t 'channel', ns |> capitalize

        @organisation = new Organisation
        @organisation.setDomain domain
        @measurement = new Measurement
        @measurement.setName name
        @measurement.setOrganisation @organisation

        @get_questionnaires!

    get_questionnaires: ->>
        intl = new Intl.DateTimeFormat getLocale().toString(),
            year: 'numeric'
            month: 'short'

        req = new GetQuestionnairesRequest
        req.setMeasurementsList [@measurement]

        response = await (@actions.dashboard.aio_get_questionnaires req: req).promise

        @questionnaires = await Promise.all do
            response.getQuestionnairesList!.map (questionnaire) ~>>
                # Fill measurement start date for switching to indepth_view
                @measurement.setStart questionnaire.getMeasurement!getStart!
                {editUrl: edit_url} = (await @get_questionnaire_urls questionnaire).toObject!

                questionnaire: questionnaire.getTitle!
                responses: await @get_responses_count questionnaire
                questions: await @get_questions_count questionnaire
                start_date: intl.format questionnaire.getMeasurement!getStart!?toDate!
                channel: t 'not_available', ns, 'n.v.t'
                attrs:
                    className: if not edit_url then 'no-edit'
                    onclick: (event) ~>
                        event.preventDefault!
                        if edit_url
                            document.location = edit_url

        @status = status.SUCCEEDED

    get_responses_count: (questionnaire) ->>
        req = new GetResponsesCountRequest
        req.setQuestionnairesList [questionnaire]

        request = @actions.dashboard.aio_get_responses_count {req, daterange: void}

        return (await request.promise).getResponsesCount!

    get_questions_count: (questionnaire) ->>
        req = new GetQuestionsRequest
        req.setQuestionnairesList [questionnaire]

        request = @actions.dashboard.aio_get_questions req: req

        (await request.promise).getQuestionsList!length

    get_questionnaire_urls: (questionnaire) ->>
        req = new GetQuestionnaireUrlsRequest
        req.setQuestionnaire questionnaire
        try
            return await (@actions.dashboard.aio_get_questionnaire_urls req: req, throw_if_err: true).promise
        catch error
            console.warn error
            return new GetQuestionnaireUrlsResponse(view_url="", edit_url="")

    view: (vnode) ->
        {name, organisation_domain} = vnode.attrs
        view_href = "/#{organisation_domain}/startblock/indepth_view/view_indepth_view?name=#name"
        try
            iso_date = timestamp_pb_to_date_str @measurement.getStart!
            view_href += "&start=#iso_date"

        m '#questionnaires.pb5.cf',
            m ".pt5.cf.measure-broad",
                m m.route.Link, {
                    selector: 'a.satys-accent'
                    href: "/#{organisation_domain}/startblock/manage/measurements"
                },
                    m 'i.material-icons.v-mid', 'chevron_left'
                    m 'span.v-mid', t 'back', ns
                m m.route.Link, {
                    selector: 'a.fr.small-action.indepth-view'
                    href: view_href
                },
                    m 'i.material-icons', 'assessment'
                m 'h1', name
                # Filters
                m '.flex.form.pb3'
                    # Wow such empty
                # Main layout
                m ".grid",
                    m card,
                        header:
                            title: t 'questionnaires', ns
                        colspan: 12

                        m ".col-center-12",
                            if @status is status.LOADING
                                m ".h4",
                                    m Spinner
                            else
                                m ".table-view.edit",
                                    m table,
                                        headers: @headers
                                        data: @questionnaires
