import grpcWeb from "grpc-web"
import { Message } from "google-protobuf"
import { AioInvoker } from "./types"
import { ABORTED_STATUS_CODE } from "/src/utils/grpc"

// Make an asynchronous unary request cancelable. It hackily uses the request's metadata
export class CancelAioUnaryInterceptor {
    async intercept (request: grpcWeb.Request<Message, Message>, invoker: AioInvoker) {
        const signal = request.getMetadata().signal as unknown as AbortSignal
        delete request.getMetadata().signal

        if (signal?.aborted) {
            throw new grpcWeb.RpcError(ABORTED_STATUS_CODE, signal.reason, request.getMetadata())
        }

        const response = await invoker(request)
        if (signal?.aborted) {
            throw new grpcWeb.RpcError(ABORTED_STATUS_CODE, signal.reason, request.getMetadata())
        }
        return response
    }
}
