// source: satys/processor/processor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var satys_domain_domain_pb = require('../../satys/domain/domain_pb.js');
goog.object.extend(proto, satys_domain_domain_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.satys.processor.CompleteTaskRequest', null, global);
goog.exportSymbol('proto.satys.processor.CompleteTaskResponse', null, global);
goog.exportSymbol('proto.satys.processor.GetProcessStateRequest', null, global);
goog.exportSymbol('proto.satys.processor.GetProcessStateResponse', null, global);
goog.exportSymbol('proto.satys.processor.GetProcessVariablesRequest', null, global);
goog.exportSymbol('proto.satys.processor.GetProcessVariablesResponse', null, global);
goog.exportSymbol('proto.satys.processor.GetRunningProcessesRequest', null, global);
goog.exportSymbol('proto.satys.processor.GetRunningProcessesResponse', null, global);
goog.exportSymbol('proto.satys.processor.StartProcessRequest', null, global);
goog.exportSymbol('proto.satys.processor.StartProcessResponse', null, global);
goog.exportSymbol('proto.satys.processor.WatchProcessRequest', null, global);
goog.exportSymbol('proto.satys.processor.WatchProcessResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.StartProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.StartProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.StartProcessRequest.displayName = 'proto.satys.processor.StartProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.StartProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.StartProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.StartProcessResponse.displayName = 'proto.satys.processor.StartProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.WatchProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.WatchProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.WatchProcessRequest.displayName = 'proto.satys.processor.WatchProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.WatchProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.WatchProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.WatchProcessResponse.displayName = 'proto.satys.processor.WatchProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.GetProcessStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.GetProcessStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.GetProcessStateRequest.displayName = 'proto.satys.processor.GetProcessStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.GetProcessStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.GetProcessStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.GetProcessStateResponse.displayName = 'proto.satys.processor.GetProcessStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.GetProcessVariablesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.GetProcessVariablesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.GetProcessVariablesRequest.displayName = 'proto.satys.processor.GetProcessVariablesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.GetProcessVariablesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.GetProcessVariablesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.GetProcessVariablesResponse.displayName = 'proto.satys.processor.GetProcessVariablesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.CompleteTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.CompleteTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.CompleteTaskRequest.displayName = 'proto.satys.processor.CompleteTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.CompleteTaskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.CompleteTaskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.CompleteTaskResponse.displayName = 'proto.satys.processor.CompleteTaskResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.GetRunningProcessesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.satys.processor.GetRunningProcessesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.GetRunningProcessesRequest.displayName = 'proto.satys.processor.GetRunningProcessesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.satys.processor.GetRunningProcessesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.satys.processor.GetRunningProcessesResponse.repeatedFields_, null);
};
goog.inherits(proto.satys.processor.GetRunningProcessesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.satys.processor.GetRunningProcessesResponse.displayName = 'proto.satys.processor.GetRunningProcessesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.StartProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.StartProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.StartProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.StartProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    process: (f = msg.getProcess()) && satys_domain_domain_pb.Process.toObject(includeInstance, f),
    variables: (f = msg.getVariables()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.StartProcessRequest}
 */
proto.satys.processor.StartProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.StartProcessRequest;
  return proto.satys.processor.StartProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.StartProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.StartProcessRequest}
 */
proto.satys.processor.StartProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.Process;
      reader.readMessage(value,satys_domain_domain_pb.Process.deserializeBinaryFromReader);
      msg.setProcess(value);
      break;
    case 2:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.StartProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.StartProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.StartProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.StartProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.Process.serializeBinaryToWriter
    );
  }
  f = message.getVariables();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.Process process = 1;
 * @return {?proto.satys.domain.Process}
 */
proto.satys.processor.StartProcessRequest.prototype.getProcess = function() {
  return /** @type{?proto.satys.domain.Process} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.Process, 1));
};


/**
 * @param {?proto.satys.domain.Process|undefined} value
 * @return {!proto.satys.processor.StartProcessRequest} returns this
*/
proto.satys.processor.StartProcessRequest.prototype.setProcess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.StartProcessRequest} returns this
 */
proto.satys.processor.StartProcessRequest.prototype.clearProcess = function() {
  return this.setProcess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.StartProcessRequest.prototype.hasProcess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Struct variables = 2;
 * @return {?proto.google.protobuf.Struct}
 */
proto.satys.processor.StartProcessRequest.prototype.getVariables = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 2));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.satys.processor.StartProcessRequest} returns this
*/
proto.satys.processor.StartProcessRequest.prototype.setVariables = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.StartProcessRequest} returns this
 */
proto.satys.processor.StartProcessRequest.prototype.clearVariables = function() {
  return this.setVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.StartProcessRequest.prototype.hasVariables = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.StartProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.StartProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.StartProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.StartProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processInstance: (f = msg.getProcessInstance()) && satys_domain_domain_pb.ProcessInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.StartProcessResponse}
 */
proto.satys.processor.StartProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.StartProcessResponse;
  return proto.satys.processor.StartProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.StartProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.StartProcessResponse}
 */
proto.satys.processor.StartProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ProcessInstance;
      reader.readMessage(value,satys_domain_domain_pb.ProcessInstance.deserializeBinaryFromReader);
      msg.setProcessInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.StartProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.StartProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.StartProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.StartProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ProcessInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ProcessInstance process_instance = 1;
 * @return {?proto.satys.domain.ProcessInstance}
 */
proto.satys.processor.StartProcessResponse.prototype.getProcessInstance = function() {
  return /** @type{?proto.satys.domain.ProcessInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ProcessInstance, 1));
};


/**
 * @param {?proto.satys.domain.ProcessInstance|undefined} value
 * @return {!proto.satys.processor.StartProcessResponse} returns this
*/
proto.satys.processor.StartProcessResponse.prototype.setProcessInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.StartProcessResponse} returns this
 */
proto.satys.processor.StartProcessResponse.prototype.clearProcessInstance = function() {
  return this.setProcessInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.StartProcessResponse.prototype.hasProcessInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.WatchProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.WatchProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.WatchProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.WatchProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workflowInstanceKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processInstanceKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.WatchProcessRequest}
 */
proto.satys.processor.WatchProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.WatchProcessRequest;
  return proto.satys.processor.WatchProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.WatchProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.WatchProcessRequest}
 */
proto.satys.processor.WatchProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkflowInstanceKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.WatchProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.WatchProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.WatchProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.WatchProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkflowInstanceKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessInstanceKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 workflow_instance_key = 1;
 * @return {number}
 */
proto.satys.processor.WatchProcessRequest.prototype.getWorkflowInstanceKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.processor.WatchProcessRequest} returns this
 */
proto.satys.processor.WatchProcessRequest.prototype.setWorkflowInstanceKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string process_instance_key = 2;
 * @return {string}
 */
proto.satys.processor.WatchProcessRequest.prototype.getProcessInstanceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.processor.WatchProcessRequest} returns this
 */
proto.satys.processor.WatchProcessRequest.prototype.setProcessInstanceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.WatchProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.WatchProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.WatchProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.WatchProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processInstance: (f = msg.getProcessInstance()) && satys_domain_domain_pb.ProcessInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.WatchProcessResponse}
 */
proto.satys.processor.WatchProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.WatchProcessResponse;
  return proto.satys.processor.WatchProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.WatchProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.WatchProcessResponse}
 */
proto.satys.processor.WatchProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ProcessInstance;
      reader.readMessage(value,satys_domain_domain_pb.ProcessInstance.deserializeBinaryFromReader);
      msg.setProcessInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.WatchProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.WatchProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.WatchProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.WatchProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ProcessInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ProcessInstance process_instance = 1;
 * @return {?proto.satys.domain.ProcessInstance}
 */
proto.satys.processor.WatchProcessResponse.prototype.getProcessInstance = function() {
  return /** @type{?proto.satys.domain.ProcessInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ProcessInstance, 1));
};


/**
 * @param {?proto.satys.domain.ProcessInstance|undefined} value
 * @return {!proto.satys.processor.WatchProcessResponse} returns this
*/
proto.satys.processor.WatchProcessResponse.prototype.setProcessInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.WatchProcessResponse} returns this
 */
proto.satys.processor.WatchProcessResponse.prototype.clearProcessInstance = function() {
  return this.setProcessInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.WatchProcessResponse.prototype.hasProcessInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.GetProcessStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.GetProcessStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.GetProcessStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workflowInstanceKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processInstanceKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.GetProcessStateRequest}
 */
proto.satys.processor.GetProcessStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.GetProcessStateRequest;
  return proto.satys.processor.GetProcessStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.GetProcessStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.GetProcessStateRequest}
 */
proto.satys.processor.GetProcessStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkflowInstanceKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.GetProcessStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.GetProcessStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.GetProcessStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkflowInstanceKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessInstanceKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 workflow_instance_key = 1;
 * @return {number}
 */
proto.satys.processor.GetProcessStateRequest.prototype.getWorkflowInstanceKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.processor.GetProcessStateRequest} returns this
 */
proto.satys.processor.GetProcessStateRequest.prototype.setWorkflowInstanceKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string process_instance_key = 2;
 * @return {string}
 */
proto.satys.processor.GetProcessStateRequest.prototype.getProcessInstanceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.processor.GetProcessStateRequest} returns this
 */
proto.satys.processor.GetProcessStateRequest.prototype.setProcessInstanceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.GetProcessStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.GetProcessStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.GetProcessStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processInstance: (f = msg.getProcessInstance()) && satys_domain_domain_pb.ProcessInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.GetProcessStateResponse}
 */
proto.satys.processor.GetProcessStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.GetProcessStateResponse;
  return proto.satys.processor.GetProcessStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.GetProcessStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.GetProcessStateResponse}
 */
proto.satys.processor.GetProcessStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ProcessInstance;
      reader.readMessage(value,satys_domain_domain_pb.ProcessInstance.deserializeBinaryFromReader);
      msg.setProcessInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.GetProcessStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.GetProcessStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.GetProcessStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      satys_domain_domain_pb.ProcessInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional satys.domain.ProcessInstance process_instance = 1;
 * @return {?proto.satys.domain.ProcessInstance}
 */
proto.satys.processor.GetProcessStateResponse.prototype.getProcessInstance = function() {
  return /** @type{?proto.satys.domain.ProcessInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.ProcessInstance, 1));
};


/**
 * @param {?proto.satys.domain.ProcessInstance|undefined} value
 * @return {!proto.satys.processor.GetProcessStateResponse} returns this
*/
proto.satys.processor.GetProcessStateResponse.prototype.setProcessInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.GetProcessStateResponse} returns this
 */
proto.satys.processor.GetProcessStateResponse.prototype.clearProcessInstance = function() {
  return this.setProcessInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.GetProcessStateResponse.prototype.hasProcessInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.GetProcessVariablesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.GetProcessVariablesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessVariablesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workflowInstanceKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processInstanceKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    taskInstance: (f = msg.getTaskInstance()) && satys_domain_domain_pb.TaskInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.GetProcessVariablesRequest}
 */
proto.satys.processor.GetProcessVariablesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.GetProcessVariablesRequest;
  return proto.satys.processor.GetProcessVariablesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.GetProcessVariablesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.GetProcessVariablesRequest}
 */
proto.satys.processor.GetProcessVariablesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkflowInstanceKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceKey(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.TaskInstance;
      reader.readMessage(value,satys_domain_domain_pb.TaskInstance.deserializeBinaryFromReader);
      msg.setTaskInstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.GetProcessVariablesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.GetProcessVariablesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessVariablesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkflowInstanceKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessInstanceKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaskInstance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.TaskInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 workflow_instance_key = 1;
 * @return {number}
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.getWorkflowInstanceKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.processor.GetProcessVariablesRequest} returns this
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.setWorkflowInstanceKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string process_instance_key = 3;
 * @return {string}
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.getProcessInstanceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.processor.GetProcessVariablesRequest} returns this
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.setProcessInstanceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional satys.domain.TaskInstance task_instance = 2;
 * @return {?proto.satys.domain.TaskInstance}
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.getTaskInstance = function() {
  return /** @type{?proto.satys.domain.TaskInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.TaskInstance, 2));
};


/**
 * @param {?proto.satys.domain.TaskInstance|undefined} value
 * @return {!proto.satys.processor.GetProcessVariablesRequest} returns this
*/
proto.satys.processor.GetProcessVariablesRequest.prototype.setTaskInstance = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.GetProcessVariablesRequest} returns this
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.clearTaskInstance = function() {
  return this.setTaskInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.GetProcessVariablesRequest.prototype.hasTaskInstance = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.GetProcessVariablesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.GetProcessVariablesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.GetProcessVariablesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessVariablesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    variables: (f = msg.getVariables()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.GetProcessVariablesResponse}
 */
proto.satys.processor.GetProcessVariablesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.GetProcessVariablesResponse;
  return proto.satys.processor.GetProcessVariablesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.GetProcessVariablesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.GetProcessVariablesResponse}
 */
proto.satys.processor.GetProcessVariablesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.GetProcessVariablesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.GetProcessVariablesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.GetProcessVariablesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetProcessVariablesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariables();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Struct variables = 1;
 * @return {?proto.google.protobuf.Struct}
 */
proto.satys.processor.GetProcessVariablesResponse.prototype.getVariables = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 1));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.satys.processor.GetProcessVariablesResponse} returns this
*/
proto.satys.processor.GetProcessVariablesResponse.prototype.setVariables = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.GetProcessVariablesResponse} returns this
 */
proto.satys.processor.GetProcessVariablesResponse.prototype.clearVariables = function() {
  return this.setVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.GetProcessVariablesResponse.prototype.hasVariables = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.CompleteTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.CompleteTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.CompleteTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.CompleteTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workflowInstanceKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processInstanceKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taskInstance: (f = msg.getTaskInstance()) && satys_domain_domain_pb.TaskInstance.toObject(includeInstance, f),
    variables: (f = msg.getVariables()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.CompleteTaskRequest}
 */
proto.satys.processor.CompleteTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.CompleteTaskRequest;
  return proto.satys.processor.CompleteTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.CompleteTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.CompleteTaskRequest}
 */
proto.satys.processor.CompleteTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkflowInstanceKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceKey(value);
      break;
    case 2:
      var value = new satys_domain_domain_pb.TaskInstance;
      reader.readMessage(value,satys_domain_domain_pb.TaskInstance.deserializeBinaryFromReader);
      msg.setTaskInstance(value);
      break;
    case 3:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setVariables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.CompleteTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.CompleteTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.CompleteTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.CompleteTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkflowInstanceKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProcessInstanceKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTaskInstance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      satys_domain_domain_pb.TaskInstance.serializeBinaryToWriter
    );
  }
  f = message.getVariables();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 workflow_instance_key = 1;
 * @return {number}
 */
proto.satys.processor.CompleteTaskRequest.prototype.getWorkflowInstanceKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.satys.processor.CompleteTaskRequest} returns this
 */
proto.satys.processor.CompleteTaskRequest.prototype.setWorkflowInstanceKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string process_instance_key = 4;
 * @return {string}
 */
proto.satys.processor.CompleteTaskRequest.prototype.getProcessInstanceKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.satys.processor.CompleteTaskRequest} returns this
 */
proto.satys.processor.CompleteTaskRequest.prototype.setProcessInstanceKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional satys.domain.TaskInstance task_instance = 2;
 * @return {?proto.satys.domain.TaskInstance}
 */
proto.satys.processor.CompleteTaskRequest.prototype.getTaskInstance = function() {
  return /** @type{?proto.satys.domain.TaskInstance} */ (
    jspb.Message.getWrapperField(this, satys_domain_domain_pb.TaskInstance, 2));
};


/**
 * @param {?proto.satys.domain.TaskInstance|undefined} value
 * @return {!proto.satys.processor.CompleteTaskRequest} returns this
*/
proto.satys.processor.CompleteTaskRequest.prototype.setTaskInstance = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.CompleteTaskRequest} returns this
 */
proto.satys.processor.CompleteTaskRequest.prototype.clearTaskInstance = function() {
  return this.setTaskInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.CompleteTaskRequest.prototype.hasTaskInstance = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Struct variables = 3;
 * @return {?proto.google.protobuf.Struct}
 */
proto.satys.processor.CompleteTaskRequest.prototype.getVariables = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 3));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.satys.processor.CompleteTaskRequest} returns this
*/
proto.satys.processor.CompleteTaskRequest.prototype.setVariables = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.satys.processor.CompleteTaskRequest} returns this
 */
proto.satys.processor.CompleteTaskRequest.prototype.clearVariables = function() {
  return this.setVariables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.satys.processor.CompleteTaskRequest.prototype.hasVariables = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.CompleteTaskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.CompleteTaskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.CompleteTaskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.CompleteTaskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.CompleteTaskResponse}
 */
proto.satys.processor.CompleteTaskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.CompleteTaskResponse;
  return proto.satys.processor.CompleteTaskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.CompleteTaskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.CompleteTaskResponse}
 */
proto.satys.processor.CompleteTaskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.CompleteTaskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.CompleteTaskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.CompleteTaskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.CompleteTaskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.GetRunningProcessesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.GetRunningProcessesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.GetRunningProcessesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetRunningProcessesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.GetRunningProcessesRequest}
 */
proto.satys.processor.GetRunningProcessesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.GetRunningProcessesRequest;
  return proto.satys.processor.GetRunningProcessesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.GetRunningProcessesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.GetRunningProcessesRequest}
 */
proto.satys.processor.GetRunningProcessesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.GetRunningProcessesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.GetRunningProcessesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.GetRunningProcessesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetRunningProcessesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.satys.processor.GetRunningProcessesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.satys.processor.GetRunningProcessesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.satys.processor.GetRunningProcessesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.satys.processor.GetRunningProcessesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetRunningProcessesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processInstancesList: jspb.Message.toObjectList(msg.getProcessInstancesList(),
    satys_domain_domain_pb.ProcessInstance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.satys.processor.GetRunningProcessesResponse}
 */
proto.satys.processor.GetRunningProcessesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.satys.processor.GetRunningProcessesResponse;
  return proto.satys.processor.GetRunningProcessesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.satys.processor.GetRunningProcessesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.satys.processor.GetRunningProcessesResponse}
 */
proto.satys.processor.GetRunningProcessesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new satys_domain_domain_pb.ProcessInstance;
      reader.readMessage(value,satys_domain_domain_pb.ProcessInstance.deserializeBinaryFromReader);
      msg.addProcessInstances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.satys.processor.GetRunningProcessesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.satys.processor.GetRunningProcessesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.satys.processor.GetRunningProcessesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.satys.processor.GetRunningProcessesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      satys_domain_domain_pb.ProcessInstance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated satys.domain.ProcessInstance process_instances = 1;
 * @return {!Array<!proto.satys.domain.ProcessInstance>}
 */
proto.satys.processor.GetRunningProcessesResponse.prototype.getProcessInstancesList = function() {
  return /** @type{!Array<!proto.satys.domain.ProcessInstance>} */ (
    jspb.Message.getRepeatedWrapperField(this, satys_domain_domain_pb.ProcessInstance, 1));
};


/**
 * @param {!Array<!proto.satys.domain.ProcessInstance>} value
 * @return {!proto.satys.processor.GetRunningProcessesResponse} returns this
*/
proto.satys.processor.GetRunningProcessesResponse.prototype.setProcessInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.satys.domain.ProcessInstance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.satys.domain.ProcessInstance}
 */
proto.satys.processor.GetRunningProcessesResponse.prototype.addProcessInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.satys.domain.ProcessInstance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.satys.processor.GetRunningProcessesResponse} returns this
 */
proto.satys.processor.GetRunningProcessesResponse.prototype.clearProcessInstancesList = function() {
  return this.setProcessInstancesList([]);
};


goog.object.extend(exports, proto.satys.processor);
