import "./index.sass"
import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import { getNamespace } from "/src/utils"
import { translate as t } from "/src/i18n"
import { analytics } from "/src/extensions"

const ns = getNamespace(__filename)

interface Attrs {
    type: string
}

export default class InsightFeedback extends MithrilTsx<Attrs> {
    type: string
    insightFeedback = {
        like: false,
        dislike: false,
    }

    oninit(vnode: this["Vnode"]) {
        this.type = vnode.attrs.type
        const insightFeedback = this.getInsightFeedback()
        this.insightFeedback.like = insightFeedback[this.type] === "like"
        this.insightFeedback.dislike = insightFeedback[this.type] === "dislike"
    }

    setLike(event: Event) {
        event.preventDefault()
        this.insightFeedback.like = !this.insightFeedback.like
        this.insightFeedback.dislike = false
        this.storeAndSendInsightFeedback("like")
    }

    setDislike(event: Event) {
        event.preventDefault()
        this.insightFeedback.dislike = !this.insightFeedback.dislike
        this.insightFeedback.like = false
        this.storeAndSendInsightFeedback("dislike")
    }

    storeAndSendInsightFeedback(feedback: string) {
        const insightFeedback = this.getInsightFeedback()

        // Delete the current feedback for this card type
        delete insightFeedback[this.type]

        // If the user has given feedback, send it to analytics and set it in the feedback object
        if (this.insightFeedback.like || this.insightFeedback.dislike) {
            insightFeedback[this.type] = feedback
            analytics.event("insight_feedback", {
                card_type: this.type,
                feedback: feedback,
            })
        }

        // Store the feedback object in local storage
        localStorage.setItem("insightFeedback", JSON.stringify(insightFeedback))
    }

    getInsightFeedback() {
        // Get the current feedback from local storage
        let insightFeedback = JSON.parse(localStorage.getItem("insightFeedback"))

        // If there is no feedback or it is not an object, create an empty object
        if (!insightFeedback || typeof insightFeedback !== "object") {
            insightFeedback = {}
        }

        return insightFeedback
    }

    view() {
        return (
            <div class="insight-feedback-wrapper noprint">
                <div class="insight-feedback">
                    <button onclick={(e: Event) => this.setLike(e)} title={t("like", ns)}>
                        { this.insightFeedback.like ? (
                            m.trust(require("/src/assets/icons/thumbs_up_filled.svg"))
                        ) : m.trust(require("/src/assets/icons/thumbs_up.svg")) }
                    </button>
                    <button onclick={(e: Event) => this.setDislike(e)} title={t("dislike", ns)}>
                        { this.insightFeedback.dislike ? (
                            m.trust(require("/src/assets/icons/thumbs_down_filled.svg"))
                        ) : m.trust(require("/src/assets/icons/thumbs_down.svg")) }
                    </button>
                </div>
            </div>
        )
    }
}
