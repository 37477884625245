m = require 'mithril'
{ Button, TextField } = require '/src/components/polythene'
state = require '/src/state' .default
{status} = require '/src/utils'
{translate: t}: i18n = require '/src/i18n'

ns = 'profile'

require '/src/css/_profile.sass'

module.exports =

    view: (vnode) ->
        [
            m "h1", t 'organisation', ns
            m '.group.cf',
                m TextField,
                    label: t 'current_organisation', ns
                    type: 'text'
                    icon: 'domain'
                    id: 'current_organisation'
                    readonly: true
                    defaultValue: state.role?getOrganisation!getName!
                m TextField,
                    label: t 'current_role', ns
                    id: 'current_role'
                    type: 'text'
                    icon: 'assignment_ind'
                    readonly: true
                    defaultValue: state.role?getName!
                    after:
                        m Button,
                            element: m.route.Link
                            href: m.buildPathname '/select_role', { return_to: m.route.get! }
                            className: 'absolute right-0 top-0 z-1 fs-normal'
                            label: t 'change_role', ns
        ]
