import m from "mithril"
import { Snackbar as OriginalSnackbar } from "polythene-mithril"
import MithrilTsx from "/src/mithril-tsx"

export default class Snackbar extends MithrilTsx<OriginalSnackbar> {
    public static show = OriginalSnackbar.show
    public static hide = OriginalSnackbar.hide
    show = OriginalSnackbar.show
    hide = OriginalSnackbar.hide

    onupdate(vnode: m.VnodeDOM<OriginalSnackbar, this>) {
        vnode.dom.removeEventListener("mouseenter", (OriginalSnackbar as any).pause)
        vnode.dom.addEventListener("mouseenter", (OriginalSnackbar as any).pause)
        vnode.dom.removeEventListener("mouseleave", (OriginalSnackbar as any).unpause)
        vnode.dom.addEventListener("mouseleave", (OriginalSnackbar as any).unpause)
    }

    onremove(vnode: m.VnodeDOM<OriginalSnackbar, this>) {
        vnode.dom.removeEventListener("mouseenter", (OriginalSnackbar as any).pause)
        vnode.dom.removeEventListener("mouseleave", (OriginalSnackbar as any).unpause)
    }

    view(vnode: m.Vnode<OriginalSnackbar, this>) {
        return m(OriginalSnackbar, vnode.attrs, vnode.children)
    }
}
