m = require 'mithril'
{any} = require 'prelude-ls'

{domain_pb} = require 'datanalysis-client-js'
{Profile} = domain_pb
state = require '/src/state' .default

{ Dialog, Button, PrimaryButton, TextField, List, ListTile } = require '/src/components/polythene'

{status} = require '/src/utils'
{translate: t}: i18n = require '/src/i18n'

Notifications = require './notifications' .default

ns = 'profile'

require '/src/css/_profile.sass'

TABS =
    profile: require './profile'
    organisation: require './organisation'
    notifications: Notifications


module.exports =

    oninit: (vnode) ->
        @profile = void

    active_class: (route) ->
        if document.location.hash.match new RegExp "#{route}$"
            'active'
        else
            ''

    view: (vnode) ->
        {tab, ...attrs} = vnode.attrs

        m '#profile.flex.flex-1',
            m '.tabs.flex.flex-auto.tabshadow.w-30.justify-end',
                m List,
                    className: 'w5 mt5'
                    tiles:
                        m ListTile,
                            title: t 'profile', ns
                            element: m.route.Link
                            className: @active_class 'profile'
                            url: href: '/profile'
                        m ListTile,
                            title: t 'organisation', ns
                            element: m.route.Link
                            className: @active_class 'organisation'
                            url: href: '/profile/organisation'
                        m ListTile,
                            title: t 'notifications'
                            element: m.route.Link
                            className: @active_class 'notifications'
                            url: href: '/profile/notifications'
            m '.flex-auto.mw6.ph5',
                m TABS[tab], {...attrs}
