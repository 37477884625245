import { googleAnalytics } from "./external_scripts/google_analytics"

const hotjarAnalytics = require("/src/external_scripts/hotjar.ls")

export class Analytics {
    gtag: (
        type: "event" | "set",
        name: string,
        params: Record<string, unknown>,
    ) => void
    hotjar: (
        type: "event" | "identity",
        value: string,
        params: Record<string, unknown>,
    ) => void

    constructor(
        gtag: {tracking_code: string},
        hotjar: {id: number, version: number},
    ) {
        this.gtag = googleAnalytics(gtag.tracking_code)
        this.hotjar = hotjarAnalytics(hotjar.id, hotjar.version)
    }

    /**
     * Fire an event to all connected analytics.
     */
    event(name: string, params?: Record<string, unknown>) {
        this.gtag("event", name, params)
        this.hotjar("event", name, params)
    }

    /**
     * Set information about the user.
     * Make sure to never include personal information.
     */
    identity(properties: Record<string, unknown>) {
        this.gtag("set", "user_properties", properties)
        this.hotjar("identity", null, properties)
    }
}

export class FakeAnalytics extends Analytics {

    constructor() {
        console.debug("Using FakeAnalytics")
        super({ tracking_code: "" }, { id: 0, version: 0 })

        this.gtag = function(...args) {
            const str = JSON.stringify(Object.values(args))
            console.debug(`[gtag] Hypothetical tracking fired with ${str}`)
        }
        this.hotjar = function(...args) {
            const str = JSON.stringify(Object.values(args))
            console.debug(`[hotjar] Hypothetical tracking fired with ${str}`)
        }
    }
}
