import {
    datanalysis_stub,
    datanalysis_promise_stub,
    utm_stub,
    utm_promise_stub,
} from "/src/stubs"
import config from "/src/config"
import { Analytics, FakeAnalytics } from "/src/analytics"
import Sentry from "/src/external_scripts/sentry"

const tawkto = require("/src/external_scripts/tawkto.ls")

if (process.env.NODE_ENV !== "production") {
    try {
        window.__GRPCWEB_DEVTOOLS__([
            datanalysis_stub,
            datanalysis_promise_stub,
            utm_stub,
            utm_promise_stub,
        ])
        console.debug("Setup gRPC-Web devtools")
    } catch {
        console.debug("Failed to setup gRPC-Web devtools")
    }
}

let analytics: Analytics

if (config.gtag_tracking_code && config.hotjar?.id) {
    analytics = new Analytics(
        { tracking_code: config.gtag_tracking_code },
        { id: config.hotjar.id, version: config.hotjar.version },
    )
} else {
    analytics = new FakeAnalytics()
}

if (config.tawkto_id) {
    tawkto.init(config.tawkto_id)
} else {
    console.debug("[tawkto] not configured")
}
let sentry: Sentry
if (process.env.NODE_ENV !== "test") {
    sentry = new Sentry({ dsn: process.env.SENTRY_DSN })
    sentry.init()
}

export {
    analytics,
    datanalysis_stub,
    datanalysis_promise_stub,
    tawkto,
    utm_stub,
    utm_stub as usertaskmanager_stub,
    utm_promise_stub,
}
