export let COLORS = getCssColorVariables()
export let ANIMATION = getCssAnimationVariables()

function getCssColorVariables() {
    return {
        GRAPH: {
            RED: getComputedStyle(document.documentElement).getPropertyValue("--satys-graph-red"),
            NEUTRAL: getComputedStyle(document.documentElement).getPropertyValue("--satys-graph-neutral"),
            GREEN: getComputedStyle(document.documentElement).getPropertyValue("--satys-graph-green"),
        },
    }
}

function getCssAnimationVariables() {
    return {
        DURATION: Number(getComputedStyle(document.documentElement).getPropertyValue("--animation-duration")),
        DURATION_LONG: Number(getComputedStyle(document.documentElement).getPropertyValue("--animation-duration-long")),
    }
}

export function reloadCssVariables() {
    COLORS = getCssColorVariables()
    ANIMATION = getCssAnimationVariables()
}
