import grpcWeb from "grpc-web"
import { Message } from "google-protobuf"
import { getAuthMetadata, getAuthMetadataSync } from "/src/auth"
import { ConstructableRequest, Invoker, AioInvoker } from "./types"

// Sync interceptor which injects the cached access token and auth headers.
// Can be used for all synchronous requests AND async streams, since their
// initial request doesn't return a promise, somehow.
export class AuthRequestInterceptor {
    intercept (request: grpcWeb.Request<Message, Message>, invoker: Invoker): grpcWeb.UnaryResponse<Message, Message> {
        const metadata = Object.assign({}, getAuthMetadataSync(), request.getMetadata())
        return invoker(
            // Make a copy with the new metadata, as changing the metadata by reference also changes it for other calls
            new (request.constructor as ConstructableRequest)(
                request.getRequestMessage(),
                request.getMethodDescriptor(),
                metadata,
                request.getCallOptions(),
            ),
        )
    }
}

// Async interceptor which smartly injects the access token and auth headers.
// This is used for async unary requests, since it returns a promise.
export class AioAuthRequestInterceptor {
    async intercept(request: grpcWeb.Request<Message, Message>, invoker: AioInvoker): Promise<grpcWeb.UnaryResponse<Message, Message>> {
        const metadata = Object.assign({}, await getAuthMetadata(), request.getMetadata())
        return await invoker(
            // Make a copy with the new metadata, as changing the metadata by reference also changes it for other calls
            new (request.constructor as ConstructableRequest)(
                request.getRequestMessage(),
                request.getMethodDescriptor(),
                metadata,
                request.getCallOptions(),
            ),
        )
    }
}
