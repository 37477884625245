import "./index.sass"
import m from "mithril"
import { translate as t } from "../../i18n"
import MithrilTsx from "../../mithril-tsx"
import { getNamespace } from "/src/utils"

const ns = getNamespace(__filename)

interface Attrs {
    notice: string
    small?: boolean
    faded?: boolean
}

export default class FeatureNotice extends MithrilTsx<Attrs> {
    view(vnode: this["Vnode"]) {
        const { notice, small, faded } = vnode.attrs
        const noticeClass = notice.replace(/_/g, "-")

        return (
            <div className={`feature-notice z-1 ${small ? "small" : ""} ${faded ? "faded" : ""}`}>
                <div className={`notice ${noticeClass}`}>
                    { t(notice, ns) }
                </div>
            </div>
        )
    }
}
