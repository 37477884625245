@use '/src/css/variables' as *
@use 'sass:color'

.wrapper :global
    width: 100%
    height: 3rem
    position: sticky
    top: 0px
    filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.10))
    will-change: filter // To get safari to show the drop-shadow
    z-index: 200

    #header
        position: sticky
        display: flex
        align-items: center
        top: 0
        width: 100%
        height: 3rem
        padding: 0.5rem
        background: white
        z-index: 9999
        border-bottom: 1px solid var(--satys-bg-color)

        a.give_feedback
            color: var(--var-text-color)
            color: $satys-accent
            text-decoration: none

            &:hover .emoji
                opacity: 1

            .emoji
                transition: .3s ease opacity
                margin-right: 10px
                opacity: 0

@media print
    #header
        position: absolute !important
