import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import SidebarLink from "./link"

import { State } from "/src/state"
import { Actions } from "/src/actions"
import { getNamespace } from "/src/utils"
import { currentRoute } from "/src/router"
import { translate as t } from "/src/i18n"
import { Role, User } from "@satys/contracts/satys/domain/domain_pb"
import { getProfilePictureUrl, getRole, getUser, getUserRoles, logout } from "/src/auth"

import * as styles from "./index.module.sass"

const ns = getNamespace(__filename)

interface Attrs {
    state: State
    actions: (state: State) => Actions
}

export default class Sidebar extends MithrilTsx<Attrs> {
    actions: Actions
    user: User
    roles: Role[] = []
    profilePictureUrl = ""

    async oninit(vnode: this["Vnode"]) {
        this.actions = vnode.attrs.actions(vnode.attrs.state)
        await Promise.all([this.getUser(), this.getUserRoles(), this.getProfilePictureUrl()])

        if (getRole().getOrganisation()) {
            await this.actions.auth.aio_get_current_organisation()
            m.redraw()
        }
    }

    async getUser() {
        this.user = await getUser()
    }

    async getUserRoles() {
        this.roles = await getUserRoles()
    }

    async getProfilePictureUrl() {
        this.profilePictureUrl = await getProfilePictureUrl()
    }

    hasSatysAdminRole() {
        return this.roles.some(r => r.getOrganisation().getDomain() === "cx_satys" && r.getName() === "admin")
    }

    view(vnode: this["Vnode"]) {
        const { state } = vnode.attrs
        const organisation_domain = getRole().getOrganisation().getDomain()

        return (
            <div class={`${styles.sidebar_wrapper} noprint`}>
                <div class="sidebar">
                    <a href="/" class="sidebar__brand">
                        <img src="/img/logo.png" class="sidebar__image" />
                    </a>

                    <nav class="sidebar__nav">
                        <div class="spacer"></div>
                        <SidebarLink
                            href={`/${organisation_domain}/startblock/dashboarding/view_dashboard`}
                            icon={require("/src/assets/icons/dashboard.svg")}
                            text={t("dashboard", ns)}
                        />
                        <SidebarLink
                            href={`/${organisation_domain}/startblock/indepth_view/select_indepth_view`}
                            active={m.route.get()?.includes("/startblock/indepth_view")}
                            icon={require("/src/assets/icons/measurements.svg")}
                            text={t("measurements", ns)}
                        />
                        { this.hasSatysAdminRole() ? (
                            <SidebarLink
                                href={`/${organisation_domain}/process`}
                                active={m.route.get()?.includes("/process")}
                                icon={require("/src/assets/icons/measurements.svg")}
                                text={t("processes", ns)}
                            />
                        ) : <></> }
                    </nav>

                    <div class="absolute bottom-0 w-100 noprint">
                        <div class="sidebar__footer">
                            <a
                                href={m.route.prefix + m.buildPathname("/select_role", { return_to: currentRoute?.path })}
                                class="sidebar__footer-item"
                            >
                                <span class="flex items-center justify-center w2 h2 mr2">
                                    { m.trust(require("/src/assets/icons/arrow_right_left.svg")) }
                                </span>
                                { t("changeRole", ns) }
                            </a>

                            { !state.user ? (
                                <ul class="menu">
                                    <li>
                                        { m(m.route.Link, {
                                            href: "/login",
                                        }, "Login") }
                                    </li>
                                </ul>
                            ) : (
                                <div class="profile">
                                    <div class="details">
                                        <a class="flex flex-row items-center truncate" href={`/${m.route.prefix}/profile`}>
                                            <img src={this.profilePictureUrl} class="avatar" />
                                            <div class="flex flex-column overflow-hidden">
                                                <span class="user">
                                                    { state.profile?.getName() || this.user?.getEmailAddress() }
                                                </span>

                                                { getRole() ? (
                                                    <span class="role" title={getRole().getName() + " @ " + getRole().getOrganisation().getDomain()}>
                                                        { getRole().getName() } @ { getRole().getOrganisation().getDomain() }
                                                    </span>
                                                ) : <></> }
                                            </div>
                                        </a>
                                        <span onclick={logout} class="logout" title={t("logout")}>
                                            { m.trust(require("/src/assets/icons/logout.svg")) }
                                        </span>
                                    </div>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
