import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import Header from "/src/components/header"
import Sidebar from "/src/components/sidebar"
import { State } from "/src/state"
import { Actions } from "/src/actions"

interface Attrs {
    state: State
    actions: () => Actions
}

export default class DefaultLayout extends MithrilTsx<Attrs> {
    view(vnode: this["Vnode"]) {
        const { state, actions } = vnode.attrs

        return (
            <div class="flex flex-row w-100">
                <Sidebar state={state} actions={actions} />

                <div class="page">
                    <Header state={state} actions={actions} />
                    { vnode.children }
                </div>
            </div>
        )
    }
}
