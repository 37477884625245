import m from "mithril"
import * as grpc from "./grpc"
import Status from "./status"

import { getLocale } from "/src/i18n"
import { Message } from "google-protobuf"
import { isAuthorizedFor } from "/src/auth"
import { roundWithPrecision } from "./round"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import { PerformanceIndicator } from "@satys/contracts/satys/domain/domain_pb"

const status = Status

export const DEFAULT_PERFORMANCE_INDICATOR = new PerformanceIndicator
DEFAULT_PERFORMANCE_INDICATOR.setType(PerformanceIndicator.Type.TYPE_AVERAGE)
DEFAULT_PERFORMANCE_INDICATOR.setMinScale(1)
DEFAULT_PERFORMANCE_INDICATOR.setScale(10)
DEFAULT_PERFORMANCE_INDICATOR.setRequiredValue(3.67)

type StringObject = {
    [key in string]: StringObject | string
}

export function AuthorizedLink(): m.Component<m.RouteLinkAttrs> {
    let authorized = false
    return {
        onupdate(vnode) {
            isAuthorizedFor(vnode.attrs.task).then((_authorized) => {
                if (_authorized !== authorized) {
                    authorized = _authorized
                    m.redraw()
                }
            })
        },

        view(vnode) {
            const { href, task = href, ...attrs } = vnode.attrs
            if (authorized === true) {
                m(
                    m.route.Link,
                    { href, ...attrs },
                    vnode.children,
                )
            }
        },
    }
}

export function camelToSnake(value: string, recursive?: boolean): string;
export function camelToSnake(value: StringObject, recursive?: boolean): StringObject;
export function camelToSnake(value: string[], recursive?: boolean): string[];
export function camelToSnake(
    value: string | StringObject | string[],
    recursive = true,
): string | StringObject | string[] {
    if (typeof value === "string") {
        return value.replace(/[\w]([A-Z])/g, (m) => `${m[0]}_${m[1]}`).toLowerCase()
    }
    if (typeof value === "object") {
        const newObject = {}
        // eslint-disable-next-line prefer-const
        for (let [key, val] of Object.entries(value)) {
            if (typeof val === "string") {
                val = camelToSnake(val)
            } else if (recursive) {
                val = camelToSnake(val)
            }
            newObject[camelToSnake(key)] = val
        }
        return newObject
    }
    return value
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function camelToHuman(str: string) {
    return capitalize(str.replace(/_/g, " "))
}

export function snakeToCamel(str: string) {
    str = str.replace(/_([a-z0-9])/g, (m) => m[1].toUpperCase())
    if (str.toUpperCase() === str) {
        return str
    }
    return `${str.charAt(0).toLocaleLowerCase()}${str.slice(1)}`
}

export function px(str: string | number) { return `${str}px` }
export function em(str: string | number) { return `${str}em` }
export function rem(str: string | number) { return `${str}rem` }

export function toCssSafe(str: string) {
    return String(str).replace(/[^a-z0-9]/g, (str) => {
        const charCode = str.charCodeAt(0)
        if (charCode === 32) {
            return "-"
        } else if (charCode >= 65 && charCode <= 90) {
            return str.toLowerCase()
        } else {
            return "_" + ("000" + charCode.toString(16)).slice(-4)
        }
    })
}

export function parseQueryString(str: string): Record<string, string> {
    if (str.startsWith("?")) {
        str = str.substring(1)
    }
    const vars = str.split("&")
    const variables = {}
    for (const variable of vars) {
        const [key, val] = variable.split("=")
        variables[decodeURIComponent(key)] = decodeURIComponent(val)
    }
    return variables
}

export function getNamespace(path: string) {
    if (path.indexOf("/views/") !== -1) {
        return path.split("views")
            .pop()
            // Remove file extension
            .replace(/\..*$/gi, "")
            .split("/")
            .filter(Boolean)
            // Take first three items
            .slice(0, 3)
            .join(".")
    }
    return path.split("src")
        .pop()
        // Remove file extension and index if it exists
        .replace(/(index)?\..*$/gi, "")
        .split("/")
        .filter(Boolean)
        .join(".")
}

export function clamp(value: number, min: number, max: number) {
    return Math.max(Math.min(value, max), min)
}

// Convert proto date into YYYY-MM-DD
export function dateToString(date: Date) {
    const y = date.getFullYear()
    const m = `0${date.getMonth() + 1}`.slice(-2)
    const d = `0${date.getDate()}`.slice(-2)
    return `${y}-${m}-${d}`
}

// Convert proto proto Timestamp into YYYY-MM-DD
export function protoTimestampToDateString(timestamp: Timestamp) {
    const date = timestamp.toDate()
    return dateToString(date)
}

/**
 * **Note**: This formula only works if the minScale of the oldScale is 1.
 */
function _scaledScore(oldScale: number, newScale: number, score: number, minScale: number) {
    if (score) {
        const old_range = (oldScale - 1)
        const new_range = (newScale - minScale)
        return (((score - 1) * new_range) / old_range) + minScale
    }
    return 0
}

export function scaledScore(scale: number, score: number, minScale: number) {
    return _scaledScore(5, scale, score, minScale)
}

export function truncate(maxLength: number, value: string) {
    if (value && value.length > maxLength) {
        return `${value.substring(0, maxLength - 3)}...`
    }
    return value
}

export function piScoreToString(score: number, performanceIndicator: PerformanceIndicator, scale?: number, locale: Intl.Locale = getLocale()) {
    if (performanceIndicator.getType() === PerformanceIndicator.Type.TYPE_AVERAGE) {
        scale = scale || performanceIndicator.getScale() || 5
        const digits = scale > 10 ? 0 : 1
        return scaleScoreByPi(
            score, performanceIndicator, scale,
        ).toLocaleString(locale, {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        })
    }
    if (performanceIndicator.getType() === PerformanceIndicator.Type.TYPE_CSAT) {
        return scaleScoreByPi(
            score, performanceIndicator, scale,
        ).toLocaleString(locale, {
            style: "percent",
            maximumFractionDigits: 0,
        })
    }
    if (performanceIndicator.getType() === PerformanceIndicator.Type.TYPE_NPS) {
        return scaleScoreByPi(
            score, performanceIndicator, scale,
        ).toLocaleString(locale, {
            maximumFractionDigits: 0,
        })
    }
    throw Error("Unkown PerformanceIndicator type given.")
}

interface Typical {
    lower: number,
    upper: number,
}

/**
 * Convert a typical object to a string which compactly shows the lower and upper boundary.
 */
export function typicalToString(
    typical: Typical,
    performanceIndicator?: PerformanceIndicator,
    locale: Intl.Locale = getLocale(),
) {
    const options: Intl.NumberFormatOptions = { notation: "compact" }
    if (performanceIndicator?.getType() === PerformanceIndicator.Type.TYPE_CSAT) {
        options.style = "percent"
    }

    let { lower, upper } = typical
    // Scale them when performanceIndicator provided
    if (performanceIndicator?.getType()) {
        lower = scaleScoreByPi(lower, performanceIndicator)
        upper = scaleScoreByPi(upper, performanceIndicator)
    }

    const lowerString = lower.toLocaleString(locale, options)
    const upperString = upper.toLocaleString(locale, options)
    return `${lowerString} - ${upperString}`
}

export function scaleScoreByPi(score: number, performanceIndicator: PerformanceIndicator, scale?: number) {
    if (performanceIndicator.getType() === PerformanceIndicator.Type.TYPE_AVERAGE) {
        scale = scale || performanceIndicator.getScale() || 5
        return roundWithPrecision(scaledScore(scale, score, performanceIndicator.getMinScale()), 1)
    }
    if (performanceIndicator.getType() === PerformanceIndicator.Type.TYPE_CSAT) {
        return roundWithPrecision(score, 2)
    }
    if (performanceIndicator.getType() === PerformanceIndicator.Type.TYPE_NPS) {
        return roundWithPrecision(score, 0)
    }
    return score
}

/**
 * Whether the given element is within the client viewport.
 */
export function elementInView(element: HTMLElement) {
    const rect = element.getBoundingClientRect()
    return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

export function groupBy<T>(list: T[], func: (arg: T) => string): Record<string, T[]> {
    const results = {}
    list.forEach((value) => {
        const key = func(value)
        if (key in results) {
            results[key].push(value)
        } else {
            results[key] = [value]
        }
    })
    return results
}

export function sortBy<T>(list: T[], func: (arg: T) => unknown) {
    return [...list].sort((a, b) => {
        const aValue = func(a)
        const bValue = func(b)
        if (aValue > bValue) {
            return 1
        } else if (aValue < bValue) {
            return -1
        }
        return 0
    })
}

/**
 * This function creates a deep clone of the given object.
 * It tries to use the browser build-in method "structuredClone".
 * Or it uses the build-in .clone() method for protobuf messages.
 *
 * NOTE: This has a fallback to JSON.stringify, which is NOT an ideal solution.
 * This works ONLY for primitive values like Numbers, Strings or Booleans.
 * Values you can also use in JSON.
 */
export function deepClone<T>(obj: T): T {
    if (obj instanceof Message) {
        return obj.clone()
    }

    if (Array.isArray(obj) && obj.every(x => x instanceof Message)) {
        return obj.reduce((acc, curr) => acc.push(deepClone(curr)), [])
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
    if (typeof window["structuredClone"] === "function") {
        return structuredClone(obj)
    }

    return JSON.parse(JSON.stringify(obj))
}

export const camel_to_human = camelToHuman
export const to_css_safe = toCssSafe
export const parse_query_string = parseQueryString
export const get_namespace = getNamespace
export const timestamp_pb_to_date_str = protoTimestampToDateString
export const date_to_str = dateToString
export const _scaled_score = _scaledScore
export const scaled_score = scaledScore
export const pi_score_to_str = piScoreToString

export {
    Status,
    status,
    grpc,
}
