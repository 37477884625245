{ vars } = require 'polythene-style'

satys_accent = '186, 46, 109'
satys_dark_accent = '63, 4, 12'
satys_light_accent = '215, 168, 189'

custom_vars =
    color_primary: satys_accent
    color_primary_dark: satys_dark_accent
    color_primary_faded: satys_light_accent

# Export as {vars} and merge with vars from polythene-style
module.exports =
    vars: Object.assign {}, vars, custom_vars
