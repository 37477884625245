@use '/src/css/variables' as *
@use 'sass:color'

.tableview :global
    table
        width: 100%

        tr
            display: flex
            gap: 1rem
            padding: 8px
            border-bottom: solid 1px lightgrey
            position: relative
            min-height: 42px

            &:last-child
                border-bottom: none

            &:first-child:after
                opacity: 0

            *
                flex: 1

            td, th
                display: flex
                align-items: center
                position: relative

        tbody tr:hover
            background-color: $satys-accent
            color: white
            cursor: pointer


    .table-active-indicator
        position: relative
        flex-shrink: 0
        flex-grow: 0
        flex-basis: 10px
        height: 10px
        margin: auto
        display: block
        border-radius: 50%
        transform: scale(1)
        cursor: pointer

        &.pulse
            background: $satys-light-green
            box-shadow: 0 0 0 0 $satys-light-green
            animation: pulse-green 2s infinite
            transition: all .2s ease

@keyframes pulse-green
    0%
        transform: scale(0.95)
        box-shadow: 0 0 0 0 rgba($satys-light-green, 0.7)

    70%
        transform: scale(1)
        box-shadow: 0 0 0 10px rgba($satys-light-green, 0)

    100%
        transform: scale(0.95)
        box-shadow: 0 0 0 0 rgba($satys-light-green, 0)
