import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import Table from "./table"
import TableRow from "./row"
import TableCell from "./cell"
import { TableHeader } from "./table"

interface TableData {
    title: string,
    attrs: Record<string, boolean | ((e: Event) => void)>
    [key: string]: string | boolean | Record<string, boolean | ((e: Event) => void)>,
}

interface Attrs {
    headers: TableHeader[],
    data: TableData[],
}

export default class SimpleTable extends MithrilTsx<Attrs> {
    view(vnode: this["Vnode"]) {
        const { headers, data = [] } = vnode.attrs

        return (
            <Table headers={headers}>
                { data.map((datum, index) => (
                    <TableRow key={index} {...datum.attrs}>
                        { Object.entries(datum).map(([key, value]) => {
                            if (key !== "attrs") {
                                return (
                                    <TableCell>
                                        { value }
                                    </TableCell>
                                )
                            }
                        }) }
                    </TableRow>
                )) }
            </Table>
        )
    }
}

export { Table, TableRow, TableCell }
