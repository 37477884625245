import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import { translate as t } from "/src/i18n"

const ns = "typeform_embed"

interface Attrs {
    class: string
    formid: string
    mode?: "popup"
    size?: number
    closeDelay?: number
}

const EMOJIS = [
    "😊",
    "😁",
    "🤯",
    "🤗",
    "👌",
    "🙏",
    "👏",
    "🙌",
    "💪",
    "🚀",
    "💡",
    "💌",
]

const getEmoji = () => EMOJIS[Math.floor(Math.random() * EMOJIS.length)]

export default class Typeform extends MithrilTsx<Attrs> {
    private emoji: string

    oninit() {
        this.emoji = getEmoji()
    }

    oncreate() {
        if (!document.querySelector("#typeform_share")) {
            const script = document.createElement("script")
            script.id = "typeform_share"
            script.src = "https://embed.typeform.com/embed.js"
            document.querySelector("head").appendChild(script)
        }
    }

    view(vnode: this["Vnode"]) {
        const {
            class: className,
            formid,
            mode = "popup",
            size = 80,
            closeDelay = 3,
        } = vnode.attrs
        return (
            <a
                class={`give_feedback typeform-share ${className}`}
                href={`https://satys.typeform.com/to/${formid}?typeform-medium=embed-snippet`}
                data-mode={mode}
                data-size={`${size}`}
                data-submit-close-delay={`${closeDelay}`}
                onmouseenter={() => this.emoji = getEmoji()}
                target="_blank"
            >
                <span class="emoji">{ this.emoji }</span>
                { t("give_feedback", ns) }
            </a>
        )
    }
}
