import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import { PrimaryButton } from "/src/components/polythene"

export interface Attrs {
    text: string;
    cta: {
        text: string
        href: string
    }
}

export default class Overlay extends MithrilTsx<Attrs> {

    view({ attrs }: this["Vnode"]) {

        const { text, cta } = attrs

        if (!text && !cta) {
            return
        }

        const routeTo = (href: string) => {

            if (href.startsWith("/")) {
                return m.route.set(href)
            }

            return window.open(href, "_blank")
        }

        return (
            <div class="card-overlay absolute absolute--fill flex flex-column items-center justify-center bg-white-90 z-1 pa4 tc br2">
                { text && <p>{ text }</p> }
                { cta && (
                    <div class="cta">
                        <PrimaryButton
                            label={cta.text}
                            events={{ onclick: () => routeTo(cta.href) }}
                        />
                    </div>
                ) }
            </div>
        )
    }
}
