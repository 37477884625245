import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import BreadCrumbs from "/src/components/bread_crumbs"
import Typeform from "/src/external_scripts/typeform"

import { State } from "/src/state"
import { Actions } from "/src/actions"

import * as styles from "./index.module.sass"

interface Attrs {
    state: State,
    actions: (state: State) => Actions,
}

export default class Header extends MithrilTsx<Attrs> {
    state: State
    actions: Actions
    responsesDialogShown = false

    oninit(vnode: this["Vnode"]) {
        this.state = vnode.attrs.state
        this.actions = vnode.attrs.actions(vnode.attrs.state)
    }

    oncreate(vnode: this["VnodeDOM"]) {
        document.documentElement.style.setProperty("--header-height", `${vnode.dom.clientHeight}px`)
    }

    onremove() {
        document.documentElement.style.removeProperty("--header-height")
    }

    view(vnode: this["Vnode"]) {
        const { state, actions } = vnode.attrs

        return (
            <div class={styles.wrapper}>
                <div id="header">
                    <div class="measure-broad">
                        <div class="flex justify-between items-center h-100 ph3-ns">
                            <div>
                                <BreadCrumbs actions={actions} state={state} />
                            </div>
                            <Typeform formid="vHn9f8zw" class="noprint" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
