import m from "mithril"
import { AuthStatus } from "/src/auth/types"
import { Route } from "./routes"
import { getAuthStatus, login } from "/src/auth"

/**
 * If not authenticated enough for a route (as defined in the route's auth), navigate to appropriate view
 */
export async function guardRoute(route: Route) {
    const authStatus = await getAuthStatus()

    if (route.auth <= authStatus) {
        // Just continue if your authStatus is sufficient enough
        return
    }
    if (authStatus <= AuthStatus.UNAUTHENTICATED) {
        return await login()
    }
    if (authStatus <= AuthStatus.NOT_VERIFIED) {
        return m.route.set("/verify_email")
    }
    if (authStatus <= AuthStatus.NO_ROLES) {
        return m.route.set("/unorganised")
    }
    if (authStatus <= AuthStatus.HAS_ROLES) {
        return m.route.set("/select_role")
    }
}
