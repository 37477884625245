m = require 'mithril'
{ PrimaryButton, TextField, List, ListTile } = require '/src/components/polythene'
state = require '/src/state' .default
{status} = require '/src/utils'
{translate: t, supportedLanguages}: i18n = require '/src/i18n'
Spinner = require '/src/components/polythene/MaterialDesignSpinner.tsx' .default
lang_icons = require('../assets/icons/languages/*.svg')

ns = 'profile'

require '/src/css/_profile.sass'

module.exports =

    oninit: (vnode) ->
        {actions} = vnode.attrs
        @actions = actions state

        @actions.auth.get_profile do
            success_cb: (profile) ~>
                @profile = profile
                m.redraw!

        @languages = Object.entries(supportedLanguages).map ([lang, langName]) ->
            return
                text: langName
                value: lang
                svg: m.trust lang_icons[lang]

    submit_profile: (event) ->
        event.preventDefault!
        # Store on server
        @actions.auth.set_profile @profile
        # Set the language in current UI
        locale = new Intl.Locale @profile?getLocale!
        i18n.setLocale locale

    view: (vnode) ->
        {profile_status} = state

        if profile_status.get is status.LOADING
            m Spinner
        else [
            m "h1", t 'profile', ns
            m 'form',
                m '.group.cf',
                    m TextField,
                        label: t 'email', ns
                        type: 'email'
                        id: 'email'
                        icon: 'person'
                        readonly: true
                        autocomplete: 'off'
                        defaultValue: state.user?getEmailAddress!
                    m TextField,
                        label: t 'fullname', ns
                        id: 'fullname'
                        icon: 'person'
                        defaultValue: @profile?getName!
                        disabled: [status.LOADING, status.FAILED].includes profile_status.get
                        onChange: ({value}) ~> @profile?setName value
                    m TextField,
                        label: t 'phone', ns
                        id: 'phone'
                        icon: 'phone'
                        defaultValue: @profile?getTelephoneNumber!
                        disabled: [status.LOADING, status.FAILED].includes profile_status.get
                        onChange: ({value}) ~> @profile?setTelephoneNumber value

                m '.group.cf',
                    m 'h4.ma0', t 'change_language', ns
                    m List,
                        tiles: @languages.map (lang) ~>
                            m ListTile,
                                ink: true
                                hoverable: true
                                title: lang.text
                                selected: @profile?getLocale!.startsWith(lang.value)
                                front: lang.svg
                                events:
                                    onclick: ~> @profile?setLocale lang.value
                    m PrimaryButton,
                        label: t 'save', ns
                        className: 'fr'
                        loading: [profile_status.get, profile_status.set].includes status.LOADING
                        events:
                            onclick: @submit_profile.bind @
        ]
