import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"
import TableRow from "./row"

import * as styles from "./index.module.sass"

interface Attrs {
    headers: TableHeader[]
}

export interface TableHeader {
    title: string,
    column: string,
    attrs?: Record<string, unknown>,
}

export default class Table extends MithrilTsx<Attrs> {

    view(vnode: this["Vnode"]) {
        const { headers } = vnode.attrs

        return (
            <div class={styles.tableview}>
                <table>
                    <thead>
                        <TableRow>
                            { headers.map(({ column, title, attrs }) => {
                                return (
                                    <th key={column} {...attrs}>{ title }</th>
                                )},
                            ) }
                        </TableRow>
                    </thead>
                    <tbody>
                        { vnode.children }
                    </tbody>
                </table>
            </div>
        )
    }
}
