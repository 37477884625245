const usertaskmanager_grpc_web_pb = require('./satys/usertaskmanager/usertaskmanager_grpc_web_pb.js');
const usertaskmanager_pb = require('./satys/usertaskmanager/usertaskmanager_pb.js');
const datanalysis_grpc_web_pb = require('./satys/datanalysis/datanalysis_grpc_web_pb.js');
const datanalysis_pb = require('./satys/datanalysis/datanalysis_pb.js');
const domain_pb = require('./satys/domain/domain_pb.js');
const time_of_day_pb = require('./satys/generic/time_of_day_pb.js');
const day_of_week_pb = require('./satys/generic/day_of_week_pb.js');
const processor_grpc_web_pb = require('./satys/processor/processor_grpc_web_pb.js');
const processor_pb = require('./satys/processor/processor_pb.js');

module.exports = {
    usertaskmanager_grpc_web_pb,
    usertaskmanager_pb,
    datanalysis_grpc_web_pb,
    datanalysis_pb,
    domain_pb,
    processor_grpc_web_pb,
    processor_pb
};
