const CONFIG = {
    "dev": {
        grpc_stub_opts: { "withCredentials": true },
        typeform: {
            redirect_uri: "https://devoffice.satys.app/",
            client_id: "CTFjuCY9BCGUUunSYWY3rVwgmjwEAJZdMsv1tfVH7E2t",
        },
        api_url: "https://api.dev.satys.app:443",
        tawkto_id: undefined,
        gtag_tracking_code: undefined,
        hotjar: undefined,
    },
    "development": {
        grpc_stub_opts: { "withCredentials": true },
        typeform: {
            redirect_uri: "http://127.0.0.1.nip.io:1234/",
            client_id: "CTFjuCY9BCGUUunSYWY3rVwgmjwEAJZdMsv1tfVH7E2t",
        },
        api_url: "https://api.127.0.0.1.nip.io:31390",
        tawkto_id: undefined,
        gtag_tracking_code: undefined,
        hotjar: undefined,
    },
    "test": {
        grpc_stub_opts: { "withCredentials": true },
        typeform: {
            redirect_uri: "test",
            client_id: "test",
        },
        api_url: "test",
        datanalysis_url: "test",
        usertaskmanager_url: "test",
        tawkto_id: undefined,
        gtag_tracking_code: undefined,
        hotjar: undefined,
    },
    "preview": {
        grpc_stub_opts: { "withCredentials": true },
        typeform: {
            redirect_uri: "preview",
            client_id: "preview",
        },
        api_url: "https://api.satys.app:443",
        tawkto_id: undefined,
        gtag_tracking_code: undefined,
        hotjar: undefined,
    },
    "production": {
        grpc_stub_opts: { "withCredentials": true },
        typeform: {
            redirect_uri: "https://backoffice.satys.app/",
            client_id: "D5ZkMCMKdDysAL5nuALjBmxcBmsKALpk9MN2sUNSjxLW",
        },
        api_url: "https://api.satys.app:443",
        tawkto_id: "5e25b691daaca76c6fcef08c",
        gtag_tracking_code: "G-SG0QXFEY0D",
        hotjar: {
            id: 1652524,
            version: 6,
        },
    },
}

const getConfig = () => {
    if (process.env.NODE_ENV.includes("development")) {
        return CONFIG["development"]
    }

    if (process.env.NODE_ENV.includes("dev")) {
        return CONFIG["dev"]
    }

    if (process.env.NODE_ENV === "test") {
        return CONFIG["test"]
    }

    if (process.env.NODE_ENV === "preview") {
        return CONFIG["preview"]
    }

    return CONFIG["production"]
}

const config = getConfig()

export default config
