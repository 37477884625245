import m from "mithril"
import TextField from "./TextField"
import {
    Icon,
    List,
    ListTile,
    onChangeTextFieldState,
} from "polythene-mithril"
import { iconDropdownDown } from "polythene-core"
import MithrilTsx from "/src/mithril-tsx"
import { deepClone } from "/src/utils"

export interface onSelectChangeFieldState<ValueType> {
    value: ValueType,
    values: ValueType[],
    added: boolean,
    event: Event | MouseEvent | KeyboardEvent,
    element: HTMLElement,
    invalid: boolean,
    error: string,
}

interface Attrs<ValueType> {
    defaultValue?: ValueType,
    defaultValues?: ValueType[],
    values?: ValueType[],
    options: {
        text: string,
        secondary?: string,
        value: ValueType,
        disabled?: boolean
    }[],
    allowMultiple?: boolean,
    label: string,
    hashfunc?: (value: ValueType) => string,
    equals?: (a: ValueType, b: ValueType) => boolean,
    emptyoption?: boolean,
    disabled?: boolean,
    search?: boolean,
    name?: string,
    icon?: string,
    className?: string,
    after?: JSX.Element | m.Children,
    maxHeight?: string,
    onChange?: (args: onSelectChangeFieldState<ValueType>) => void,
    [key: string]: unknown,
}

class Select<ValueType> extends MithrilTsx<Attrs<ValueType>> {
    values: ValueType[]
    allowMultiple = false
    leftFocus = false
    opened = false
    currentIndex = -1
    matches: Attrs<ValueType>["options"]
    closeEventListener: (event: MouseEvent) => void
    equals: (a: ValueType, b: ValueType) => boolean

    oninit(vnode: this["Vnode"]) {
        const {
            defaultValue = undefined,
            defaultValues = [],
            allowMultiple,
            label,
        } = vnode.attrs

        this.reset(vnode)
        if (allowMultiple !== undefined) {
            this.allowMultiple = allowMultiple
        }
        if (!this.allowMultiple && defaultValues.length) {
            throw Error("Cannot set the plural defaultValues when allowMultiple is disabled, use defaultValue instead. (Multi)select with label: " + label)
        }
        if (this.allowMultiple && defaultValue) {
            throw Error("Cannot set the single defaultValue when allowMultiple is enabled, use defaultValues instead. (Multi)select with label: " + label)
        }

        // Make sure to copy the values over
        if (defaultValues.length) {
            this.values = [...defaultValues]
        } else if (defaultValue !== undefined) {
            this.values = [deepClone(defaultValue)]
        } else {
            this.values = []
        }
    }

    oncreate(vnode: this["VnodeDOM"]) {
        this.closeEventListener = (event: MouseEvent) => {
            if (!vnode.dom.contains(event.target as Node)) {
                this.reset(vnode)
                m.redraw()
            }
        }
        window.addEventListener("click", this.closeEventListener)
    }

    onremove() {
        window.removeEventListener("click", this.closeEventListener)
    }

    reset({ attrs }: this["Vnode"] | this["VnodeDOM"]) {
        this.opened = false
        this.currentIndex = -1
        this.matches = attrs.options
    }

    onChange(vnode: this["Vnode"] | this["VnodeDOM"], selected: boolean, value: ValueType, event: Event) {
        const { onChange = () => undefined } = vnode.attrs
        if (this.allowMultiple) {
            if (selected) {
                this.values = this.values.filter(_value => !this.equals(_value, value))
            } else {
                this.values = [...this.values, value]
            }
        } else {
            this.values = [value]
            this.reset(vnode)
        }
        onChange({
            value: value,
            values: this.values,
            added: !selected,
            event: event,
            element: event.target as HTMLElement,
            invalid: false,
            error: "",
        })

        if (!this.allowMultiple) {
            this.opened = false
        }
    }

    open(vnode: this["Vnode"] | this["VnodeDOM"]) {
        if (vnode.attrs.disabled) {
            return
        }
        this.opened = true
        m.redraw()
        const vnodeDom = (vnode as this["VnodeDOM"])
        if (vnode.attrs.search && vnodeDom.dom) {
            const element = vnodeDom.dom?.querySelector(".search input")
            ;(element as HTMLInputElement)?.focus()
        }
    }

    search(options: Attrs<ValueType>["options"], str: string) {
        this.matches = options.filter((option) => {
            const regex = new RegExp(str, "gi")
            return (
                String(option.text).match(regex)
                || String(option.secondary)?.match(regex)
            )
        })
    }

    onkeydown(vnode: this["VnodeDOM"], event: KeyboardEvent) {
        const options = vnode.attrs.options

        if (["ArrowDown", "Down"].includes(event.key)) {
            event.preventDefault()
            this.currentIndex = Math.min(this.matches.length, this.currentIndex + 1)
        } else if (["ArrowUp", "Up"].includes(event.key)) {
            event.preventDefault()
            this.currentIndex = Math.max(-1, this.currentIndex - 1)
        } else if (" " === event.key) {
            event.preventDefault()
            if (!this.opened) {
                this.open(vnode)
            }
        } else if ("Enter" === event.key) {
            if (!this.opened) {
                this.open(vnode)
            } else {
                event.preventDefault()
                const value = this.matches[this.currentIndex].value
                const selected = options.some((option) => this.equals(option.value, value))
                this.onChange(vnode, selected, value, event)
            }
        } else if (["Escape", "Esc"].includes(event.key)) {
            this.opened = false
        }
    }

    /**
     * This view isn't created with JSX since there were some issues with
     * the polythene elements used here. It might or might not have been resolved.
     * The exact issue is lost and there was not yet an effort to convert this
     * to JSX again.
     */
    view(vnode: this["Vnode"]) {
        let { options = [], className = "" } = vnode.attrs
        const {
            label,
            name,
            emptyoption = false,
            disabled,
            after,
            icon,
            maxHeight = "250px",
            search = false,
            equals,
            hashfunc = (x) => x,
        } = vnode.attrs

        if (this.values.length) {
            className += " pe-textfield--focused"
        }
        this.equals = equals || ((a, b) => hashfunc(a) === hashfunc(b))

        if (emptyoption) {
            options = [{ text: "---", value: undefined }].concat(options)
        }

        const currentOptions = options.filter((option) => {
            for (const value of this.values) {
                if (this.equals(value, option.value)) {
                    return true
                }
            }
        })

        if (this.values.length !== currentOptions.length) {
            throw new Error(`
                Cannot find all values in options or the other way round...
                Maybe 'defaultValues' is given before the content is available?
                Or you might need to set an 'equals' or 'hashfunc' to enable proper comparison.
            `)
        }

        let disabledClass = ""
        if (disabled) {
            disabledClass = ".disabled"
        }

        return m(`.select.multiselect.relative${disabledClass}`, {
            onkeydown: this.onkeydown.bind(this, vnode),
        }, [
            m("span.select-input", {
                onclick: () => {
                    if (!this.opened && !this.leftFocus) {
                        this.open(vnode)
                    } else {
                        this.opened = false
                    }
                },
            }, [
                m(TextField, {
                    value: currentOptions.map((opt) => opt.text).join(", "),
                    label: label,
                    name: name,
                    icon: icon,
                    className: className,
                    disabled: disabled,
                    domAttributes: {
                        role: "button",
                        readonly: true,
                        title: currentOptions.map((opt) => opt.text).join(", "),
                    },
                    after: m(Icon, {
                        className: "dropdown",
                        svg: m.trust(iconDropdownDown as unknown as string),
                    }),
                }),
                after && after,
            ]),
            this.opened && m(".pe-menu__panel.absolute",
                [
                    search && m(TextField, {
                        floatingLabel: false,
                        className: "search",
                        icon: "search",
                        style: { cursor: "pointer" },
                        onChange: ({ value }: onChangeTextFieldState) => {
                            this.search(options, value)
                        },
                    }),
                    m(List, {
                        compact: true,
                        // hoverable: true,
                        style: { maxHeight: maxHeight },
                        tiles: this.matches.map((option, index) => {
                            const selected = currentOptions.some(
                                (opt) => this.equals(opt.value, option.value),
                            ) || this.currentIndex === index

                            return m(ListTile, {
                                title: option.text,
                                highSubtitle: option.secondary,
                                ink: true,
                                hoverable: true,
                                selected: selected,
                                disabled: option.disabled ?? false,
                                events: {
                                    onclick: (event: MouseEvent) => {
                                        event.preventDefault()
                                        this.onChange(vnode, selected, option.value, event)
                                    },
                                },
                            })
                        }),
                    }),
                ],
            ),
        ])
    }
}

export default Select
