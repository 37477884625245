import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"

export default class Spinner extends MithrilTsx<unknown> {
    view() {
        return (
            <div class="loading-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        )
    }
}
