import m from "mithril"
import MithrilTsx from "/src/mithril-tsx"

import { State } from "/src/state"
import { Actions } from "/src/actions"
import { getUserRoles } from "/src/auth"
import { currentRoute } from "/src/router"
import { Organisation, Role } from "@satys/contracts/satys/domain/domain_pb"
import { GetOrganisationParentsRequest, GetOrganisationParentsResponse } from "@satys/contracts/satys/datanalysis/datanalysis_pb"

import * as styles from  "./index.module.sass"

interface Attrs {
    state: State
    actions: (state: State) => Actions
}

export default class BreadCrumbs extends MithrilTsx<Attrs> {
    actions: Actions
    roles: Role[] = []
    organisationTree: Organisation[] = []
    showDropdown = false

    async oninit(vnode: this["Vnode"]) {
        this.actions = vnode.attrs.actions(vnode.attrs.state)
        await Promise.all([this.getUserRoles(), this.getOrganisationParents()])
    }

    async onupdate() {
        await Promise.all([this.getUserRoles(), this.getOrganisationParents()])
    }

    oncreate() {
        window.addEventListener("click", this.closeDropdown.bind(this))
    }

    onremove() {
        window.removeEventListener("click", this.closeDropdown.bind(this))
    }

    closeDropdown(e: Event) {
        const element = e.target as HTMLElement
        const dropdown = document.querySelector(`.${styles.breadcrumbs} .dropdown`)

        if (!dropdown) {
            // the component is not mounted properly (yet)
            return
        }

        if (!this.showDropdown && !dropdown.contains(element)) {
            this.showDropdown = dropdown.parentNode.contains(element)
        } else {
            this.showDropdown = false
        }

        m.redraw()
    }

    async getUserRoles() {
        this.roles = await getUserRoles()
    }

    async getOrganisationParents() {
        const { promise } = this.actions.dashboard.aio_get_organisation_parents<GetOrganisationParentsRequest, GetOrganisationParentsResponse>()
        const organisationParents = await promise
        let organisation = organisationParents.getOrganisation()
        const organisationTree: Organisation[] = []

        while (organisation.getParent()) {
            organisationTree.push(organisation.getParent())
            organisation = organisation.getParent()
        }

        organisationTree.reverse()
        this.organisationTree = organisationTree
    }

    getRole(organisation: Organisation) {
        return this.roles.find(r => {
            return r.getOrganisation().getDomain() === organisation.getDomain()
        })
    }

    getSisterOrganisations(state: State) {
        const satysOrg = new Organisation()
        satysOrg.setDomain("cx_satys")
        const parent = this.organisationTree.at(-1) || state.role.getOrganisation().getParent() || satysOrg

        if (!parent) {return []}

        return this.roles
            .reduce<Organisation[]>((acc, r) => {
                if (parent.getDomain() === r.getOrganisation().getParent()?.getDomain()) {
                    acc.push(r.getOrganisation())
                }
                return acc
            }, [])
            .sort((a, b) => a.getName().localeCompare(b.getName()))
    }

    view(vnode: this["Vnode"]) {
        const currentRole = vnode.attrs.state.role
        const sisterOrganisations = this.getSisterOrganisations(vnode.attrs.state)

        return (
            <div className={styles.breadcrumbs + " breadcrumbs"}>
                <div className="crumbs">
                    <a
                        href={m.route.prefix + m.buildPathname("/select_role", { return_to: currentRoute?.path })}
                        class="crumb-item crumb-item--margin-top"
                    >
                        { m.trust(require("/src/assets/icons/arrow_right_left.svg")) }
                    </a>

                    <i className="material-icons seperator">navigate_next</i>

                    { this.organisationTree.map(o => {
                        const params = { organisation_domain: o.getDomain() }
                        if (m.route.param("start")) {
                            params["start"] = m.route.param("start")
                        }
                        if (m.route.param("end")) {
                            params["end"] = m.route.param("end")
                        }
                        const path = m.route.prefix + m.buildPathname(currentRoute?.path, params)

                        return (
                            <>
                                { this.roles.some(r => r.getOrganisation().getDomain() === o.getDomain()) ? (
                                    <a className="parent crumb-item" title={o.getName()} href={path}>
                                        { o.getName() }
                                    </a>
                                ) : (
                                    <span>
                                        { o.getName() }
                                    </span>
                                ) }

                                <i className="material-icons seperator">navigate_next</i>
                            </>
                        )
                    }) }

                    <div className="relative">
                        <span className="current">
                            <span className="crumb-item">{ currentRole.getOrganisation().getName() }</span>

                            { sisterOrganisations.length ? (
                                <i className="material-icons v-btm f-inherit noprint">expand_more</i>
                            ) : <></> }

                        </span>

                        { sisterOrganisations.length ? (
                            <div className={`dropdown ${this.showDropdown ? "active" : ""}`}>
                                { sisterOrganisations.map((o, i) => {
                                    const isActive = o.getDomain() === currentRole.getOrganisation().getDomain()
                                    const params = { organisation_domain: o.getDomain() }
                                    if (m.route.param("start")) {
                                        params["start"] = m.route.param("start")
                                    }
                                    if (m.route.param("end")) {
                                        params["end"] = m.route.param("end")
                                    }
                                    const path = m.route.prefix + m.buildPathname(currentRoute?.path, params)

                                    return (
                                        <a
                                            key={i}
                                            className={`dropdown-item ${isActive ? "active" : ""}`}
                                            href={path}
                                        >
                                            { o.getName() }
                                        </a>
                                    )
                                }) }
                            </div>
                        ) : <></> }
                    </div>

                </div>
            </div>
        )
    }
}
