state = require '/src/state' .default
{datanalysis_stub} = require '/src/stubs'
{datanalysis_pb} = require 'datanalysis-client-js'
{GetTawktoUserHashRequest} = datanalysis_pb

{first} = require 'prelude-ls'

tawkto =
    initialized: false

    init: (id) ->
        window.Tawk_API = window.Tawk_API or {}
        window.Tawk_API.visitor = window.Tawk_API.visitor or {}

        window.Tawk_LoadStart = new Date!
        script = document.createElement 'script'
        script_ = first document.getElementsByTagName 'script'

        script.async = true
        script.src = "https://embed.tawk.to/#{id}/default"
        script.charset = 'UTF-8'
        script.setAttribute 'crossorigin', '*'
        script_.parentNode.insertBefore script, script_

        tawkto.initialized = true

    set_user: (user) ->
        if not tawkto.initialized is true then return
        if window.Tawk_API.visitor.email is user.getEmailAddress!
            return

        tawkto_hash_req = new GetTawktoUserHashRequest
        datanalysis_stub.get_tawkto_user_hash tawkto_hash_req, {},
            (err, resp) ~>
                if err
                    console.warn '[tawkto] Could not get hash!'
                else
                    visitor = window.Tawk_API.visitor
                    visitor.name = user.getEmailAddress!
                    visitor.email = user.getEmailAddress!
                    visitor.hash = resp.getHash!
                    # Set visitor independently of tawk.to being loaded
                    window.Tawk_API.visitor = visitor
                    try
                        window.Tawk_API.setAttributes visitor, void

    set_role: (role) ->
        if not tawkto.initialized is true then return
        visitor = window.Tawk_API.visitor
        role_str = "#{role.getName!} @ #{role.getOrganisation!getName!}"
        if visitor.role is role_str
            return

        # Set role independently of tawk.to being loaded
        visitor.role = role_str
        window.Tawk_API.visitor = visitor
        try
            window.Tawk_API.setAttributes visitor, void

module.exports = tawkto
