/**
 * @fileoverview gRPC-Web generated client stub for satys.datanalysis
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var satys_domain_domain_pb = require('../../satys/domain/domain_pb.js')

var satys_generic_day_of_week_pb = require('../../satys/generic/day_of_week_pb.js')

var satys_generic_time_of_day_pb = require('../../satys/generic/time_of_day_pb.js')
const proto = {};
proto.satys = {};
proto.satys.datanalysis = require('./datanalysis_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.satys.datanalysis.DatanalysisAPIClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.RegisterUserRequest,
 *   !proto.satys.datanalysis.RegisterUserResponse>}
 */
const methodDescriptor_DatanalysisAPI_register_user = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/register_user',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.RegisterUserRequest,
  proto.satys.datanalysis.RegisterUserResponse,
  /**
   * @param {!proto.satys.datanalysis.RegisterUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.RegisterUserResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.RegisterUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.RegisterUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.RegisterUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.register_user =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/register_user',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_register_user,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.RegisterUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.RegisterUserResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.register_user =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/register_user',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_register_user);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetPasswordRequest,
 *   !proto.satys.datanalysis.SetPasswordResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_password = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_password',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetPasswordRequest,
  proto.satys.datanalysis.SetPasswordResponse,
  /**
   * @param {!proto.satys.datanalysis.SetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_password =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_password',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_password,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_password =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_password',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_password);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.LoginRequest,
 *   !proto.satys.datanalysis.LoginResponse>}
 */
const methodDescriptor_DatanalysisAPI_login = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/login',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.LoginRequest,
  proto.satys.datanalysis.LoginResponse,
  /**
   * @param {!proto.satys.datanalysis.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/login',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_login,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/login',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.LogoutRequest,
 *   !proto.satys.datanalysis.LogoutResponse>}
 */
const methodDescriptor_DatanalysisAPI_logout = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/logout',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.LogoutRequest,
  proto.satys.datanalysis.LogoutResponse,
  /**
   * @param {!proto.satys.datanalysis.LogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.LogoutResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/logout',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_logout,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/logout',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetUsersRequest,
 *   !proto.satys.datanalysis.GetUsersResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_users = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_users',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetUsersRequest,
  proto.satys.datanalysis.GetUsersResponse,
  /**
   * @param {!proto.satys.datanalysis.GetUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetUsersResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_users =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_users',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_users,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetUsersResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_users =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_users',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_users);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetUsersByRoleRequest,
 *   !proto.satys.datanalysis.GetUsersByRoleResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_users_by_role = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_users_by_role',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetUsersByRoleRequest,
  proto.satys.datanalysis.GetUsersByRoleResponse,
  /**
   * @param {!proto.satys.datanalysis.GetUsersByRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetUsersByRoleResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetUsersByRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetUsersByRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetUsersByRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_users_by_role =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_users_by_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_users_by_role,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetUsersByRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetUsersByRoleResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_users_by_role =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_users_by_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_users_by_role);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetUserRoleRequest,
 *   !proto.satys.datanalysis.SetUserRoleResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_user_role = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_user_role',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetUserRoleRequest,
  proto.satys.datanalysis.SetUserRoleResponse,
  /**
   * @param {!proto.satys.datanalysis.SetUserRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetUserRoleResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetUserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetUserRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetUserRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_user_role =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_user_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_user_role,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetUserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetUserRoleResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_user_role =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_user_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_user_role);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UnsetUserRoleRequest,
 *   !proto.satys.datanalysis.UnsetUserRoleResponse>}
 */
const methodDescriptor_DatanalysisAPI_unset_user_role = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/unset_user_role',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UnsetUserRoleRequest,
  proto.satys.datanalysis.UnsetUserRoleResponse,
  /**
   * @param {!proto.satys.datanalysis.UnsetUserRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UnsetUserRoleResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UnsetUserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UnsetUserRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UnsetUserRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.unset_user_role =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/unset_user_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_unset_user_role,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UnsetUserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UnsetUserRoleResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.unset_user_role =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/unset_user_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_unset_user_role);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetActiveRoleRequest,
 *   !proto.satys.datanalysis.SetActiveRoleResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_active_role = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_active_role',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetActiveRoleRequest,
  proto.satys.datanalysis.SetActiveRoleResponse,
  /**
   * @param {!proto.satys.datanalysis.SetActiveRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetActiveRoleResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetActiveRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetActiveRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetActiveRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_active_role =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_active_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_active_role,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetActiveRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetActiveRoleResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_active_role =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_active_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_active_role);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetCurrentRoleRequest,
 *   !proto.satys.datanalysis.GetCurrentRoleResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_current_role = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_current_role',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetCurrentRoleRequest,
  proto.satys.datanalysis.GetCurrentRoleResponse,
  /**
   * @param {!proto.satys.datanalysis.GetCurrentRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetCurrentRoleResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetCurrentRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetCurrentRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetCurrentRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_current_role =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_current_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_current_role,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetCurrentRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetCurrentRoleResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_current_role =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_current_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_current_role);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetTawktoUserHashRequest,
 *   !proto.satys.datanalysis.GetTawktoUserHashResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_tawkto_user_hash = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_tawkto_user_hash',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetTawktoUserHashRequest,
  proto.satys.datanalysis.GetTawktoUserHashResponse,
  /**
   * @param {!proto.satys.datanalysis.GetTawktoUserHashRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetTawktoUserHashResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetTawktoUserHashRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetTawktoUserHashResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetTawktoUserHashResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_tawkto_user_hash =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_tawkto_user_hash',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_tawkto_user_hash,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetTawktoUserHashRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetTawktoUserHashResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_tawkto_user_hash =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_tawkto_user_hash',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_tawkto_user_hash);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetUsersWithRolesRequest,
 *   !proto.satys.datanalysis.GetUsersWithRolesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_users_with_roles = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_users_with_roles',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetUsersWithRolesRequest,
  proto.satys.datanalysis.GetUsersWithRolesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetUsersWithRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetUsersWithRolesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetUsersWithRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetUsersWithRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetUsersWithRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_users_with_roles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_users_with_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_users_with_roles,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetUsersWithRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetUsersWithRolesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_users_with_roles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_users_with_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_users_with_roles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest,
 *   !proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_organisation_and_sub_organisations_roles = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_organisation_and_sub_organisations_roles',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest,
  proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_organisation_and_sub_organisations_roles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_and_sub_organisations_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_and_sub_organisations_roles,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetOrganisationAndSubOrganisationsRolesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_organisation_and_sub_organisations_roles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_and_sub_organisations_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_and_sub_organisations_roles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetProfileRequest,
 *   !proto.satys.datanalysis.SetProfileResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_profile = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_profile',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetProfileRequest,
  proto.satys.datanalysis.SetProfileResponse,
  /**
   * @param {!proto.satys.datanalysis.SetProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetProfileResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_profile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_profile',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_profile,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetProfileResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_profile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_profile',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_profile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetProfileRequest,
 *   !proto.satys.datanalysis.GetProfileResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_profile = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_profile',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetProfileRequest,
  proto.satys.datanalysis.GetProfileResponse,
  /**
   * @param {!proto.satys.datanalysis.GetProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetProfileResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_profile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_profile',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_profile,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetProfileResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_profile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_profile',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_profile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetMyDashboardsRequest,
 *   !proto.satys.datanalysis.GetMyDashboardsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_my_dashboards = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_my_dashboards',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetMyDashboardsRequest,
  proto.satys.datanalysis.GetMyDashboardsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetMyDashboardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetMyDashboardsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetMyDashboardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetMyDashboardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetMyDashboardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_my_dashboards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_my_dashboards',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_my_dashboards,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetMyDashboardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetMyDashboardsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_my_dashboards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_my_dashboards',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_my_dashboards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetDashboardsRequest,
 *   !proto.satys.datanalysis.GetDashboardsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_dashboards = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_dashboards',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetDashboardsRequest,
  proto.satys.datanalysis.GetDashboardsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetDashboardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetDashboardsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetDashboardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetDashboardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetDashboardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_dashboards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_dashboards',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_dashboards,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetDashboardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetDashboardsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_dashboards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_dashboards',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_dashboards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateDashboardRequest,
 *   !proto.satys.datanalysis.CreateDashboardResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_dashboard = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_dashboard',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateDashboardRequest,
  proto.satys.datanalysis.CreateDashboardResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateDashboardResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateDashboardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateDashboardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_dashboard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_dashboard,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateDashboardResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_dashboard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_dashboard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UpdateDashboardRequest,
 *   !proto.satys.datanalysis.UpdateDashboardResponse>}
 */
const methodDescriptor_DatanalysisAPI_update_dashboard = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/update_dashboard',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UpdateDashboardRequest,
  proto.satys.datanalysis.UpdateDashboardResponse,
  /**
   * @param {!proto.satys.datanalysis.UpdateDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UpdateDashboardResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UpdateDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UpdateDashboardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UpdateDashboardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.update_dashboard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_dashboard,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UpdateDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UpdateDashboardResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.update_dashboard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_dashboard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetNotificationSettingsRequest,
 *   !proto.satys.datanalysis.GetNotificationSettingsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_notification_settings = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_notification_settings',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetNotificationSettingsRequest,
  proto.satys.datanalysis.GetNotificationSettingsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetNotificationSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetNotificationSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetNotificationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetNotificationSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetNotificationSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_notification_settings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_notification_settings',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_notification_settings,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetNotificationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetNotificationSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_notification_settings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_notification_settings',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_notification_settings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UpdateNotificationSettingsRequest,
 *   !proto.satys.datanalysis.UpdateNotificationSettingsResponse>}
 */
const methodDescriptor_DatanalysisAPI_update_notification_settings = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/update_notification_settings',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UpdateNotificationSettingsRequest,
  proto.satys.datanalysis.UpdateNotificationSettingsResponse,
  /**
   * @param {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UpdateNotificationSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UpdateNotificationSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UpdateNotificationSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.update_notification_settings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_notification_settings',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_notification_settings,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UpdateNotificationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UpdateNotificationSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.update_notification_settings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_notification_settings',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_notification_settings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateRoleRequest,
 *   !proto.satys.datanalysis.CreateRoleResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_role = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_role',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateRoleRequest,
  proto.satys.datanalysis.CreateRoleResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateRoleResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_role =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_role,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateRoleResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_role =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_role',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_role);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetRoleDashboardRequest,
 *   !proto.satys.datanalysis.SetRoleDashboardResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_role_dashboard = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_role_dashboard',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetRoleDashboardRequest,
  proto.satys.datanalysis.SetRoleDashboardResponse,
  /**
   * @param {!proto.satys.datanalysis.SetRoleDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetRoleDashboardResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetRoleDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetRoleDashboardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetRoleDashboardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_role_dashboard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_role_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_role_dashboard,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetRoleDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetRoleDashboardResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_role_dashboard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_role_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_role_dashboard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UnsetRoleDashboardRequest,
 *   !proto.satys.datanalysis.UnsetRoleDashboardResponse>}
 */
const methodDescriptor_DatanalysisAPI_unset_role_dashboard = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/unset_role_dashboard',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UnsetRoleDashboardRequest,
  proto.satys.datanalysis.UnsetRoleDashboardResponse,
  /**
   * @param {!proto.satys.datanalysis.UnsetRoleDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UnsetRoleDashboardResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UnsetRoleDashboardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UnsetRoleDashboardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.unset_role_dashboard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/unset_role_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_unset_role_dashboard,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UnsetRoleDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UnsetRoleDashboardResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.unset_role_dashboard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/unset_role_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_unset_role_dashboard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetRolesDashboardRequest,
 *   !proto.satys.datanalysis.GetRolesDashboardResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_roles_dashboard = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_roles_dashboard',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetRolesDashboardRequest,
  proto.satys.datanalysis.GetRolesDashboardResponse,
  /**
   * @param {!proto.satys.datanalysis.GetRolesDashboardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetRolesDashboardResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetRolesDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetRolesDashboardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetRolesDashboardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_roles_dashboard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_roles_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_roles_dashboard,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetRolesDashboardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetRolesDashboardResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_roles_dashboard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_roles_dashboard',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_roles_dashboard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetUserRolesRequest,
 *   !proto.satys.datanalysis.GetUserRolesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_user_roles = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_user_roles',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetUserRolesRequest,
  proto.satys.datanalysis.GetUserRolesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetUserRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetUserRolesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetUserRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetUserRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_user_roles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_user_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_user_roles,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetUserRolesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_user_roles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_user_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_user_roles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetRoleTasksRequest,
 *   !proto.satys.datanalysis.GetRoleTasksResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_role_tasks = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_role_tasks',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetRoleTasksRequest,
  proto.satys.datanalysis.GetRoleTasksResponse,
  /**
   * @param {!proto.satys.datanalysis.GetRoleTasksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetRoleTasksResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetRoleTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetRoleTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetRoleTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_role_tasks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_role_tasks',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_role_tasks,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetRoleTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetRoleTasksResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_role_tasks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_role_tasks',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_role_tasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateOrganisationRequest,
 *   !proto.satys.datanalysis.CreateOrganisationResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_organisation = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_organisation',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateOrganisationRequest,
  proto.satys.datanalysis.CreateOrganisationResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_organisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_organisation',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_organisation,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_organisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_organisation',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_organisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetOrganisationRequest,
 *   !proto.satys.datanalysis.GetOrganisationResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_organisation = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_organisation',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetOrganisationRequest,
  proto.satys.datanalysis.GetOrganisationResponse,
  /**
   * @param {!proto.satys.datanalysis.GetOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_organisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_organisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UpdateOrganisationRequest,
 *   !proto.satys.datanalysis.UpdateOrganisationResponse>}
 */
const methodDescriptor_DatanalysisAPI_update_organisation = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/update_organisation',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UpdateOrganisationRequest,
  proto.satys.datanalysis.UpdateOrganisationResponse,
  /**
   * @param {!proto.satys.datanalysis.UpdateOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UpdateOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UpdateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UpdateOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UpdateOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.update_organisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_organisation',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_organisation,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UpdateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UpdateOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.update_organisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_organisation',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_organisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetPerformanceIndicatorsRequest,
 *   !proto.satys.datanalysis.SetPerformanceIndicatorsResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_performance_indicators = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_performance_indicators',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetPerformanceIndicatorsRequest,
  proto.satys.datanalysis.SetPerformanceIndicatorsResponse,
  /**
   * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetPerformanceIndicatorsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetPerformanceIndicatorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetPerformanceIndicatorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_performance_indicators =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_performance_indicators',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_performance_indicators,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetPerformanceIndicatorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetPerformanceIndicatorsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_performance_indicators =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_performance_indicators',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_performance_indicators);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetOrganisationsRequest,
 *   !proto.satys.datanalysis.GetOrganisationsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_organisations = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_organisations',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetOrganisationsRequest,
  proto.satys.datanalysis.GetOrganisationsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetOrganisationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetOrganisationsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetOrganisationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetOrganisationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_organisations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisations',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisations,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetOrganisationsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_organisations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisations',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetOrganisationParentsRequest,
 *   !proto.satys.datanalysis.GetOrganisationParentsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_organisation_parents = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_organisation_parents',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetOrganisationParentsRequest,
  proto.satys.datanalysis.GetOrganisationParentsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetOrganisationParentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetOrganisationParentsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetOrganisationParentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetOrganisationParentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetOrganisationParentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_organisation_parents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_parents',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_parents,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetOrganisationParentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetOrganisationParentsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_organisation_parents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_parents',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_parents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetOrganisationRolesRequest,
 *   !proto.satys.datanalysis.GetOrganisationRolesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_organisation_roles = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_organisation_roles',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetOrganisationRolesRequest,
  proto.satys.datanalysis.GetOrganisationRolesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetOrganisationRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetOrganisationRolesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetOrganisationRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetOrganisationRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetOrganisationRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_organisation_roles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_roles,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetOrganisationRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetOrganisationRolesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_organisation_roles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_roles',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_roles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetQueryableOrganisationsRequest,
 *   !proto.satys.datanalysis.GetQueryableOrganisationsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_queryable_organisations = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_queryable_organisations',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetQueryableOrganisationsRequest,
  proto.satys.datanalysis.GetQueryableOrganisationsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetQueryableOrganisationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetQueryableOrganisationsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetQueryableOrganisationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetQueryableOrganisationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_queryable_organisations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_queryable_organisations',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_queryable_organisations,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetQueryableOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetQueryableOrganisationsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_queryable_organisations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_queryable_organisations',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_queryable_organisations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetOrganisationWithDescendantsRequest,
 *   !proto.satys.datanalysis.GetOrganisationWithDescendantsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_organisation_with_descendants = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_organisation_with_descendants',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetOrganisationWithDescendantsRequest,
  proto.satys.datanalysis.GetOrganisationWithDescendantsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetOrganisationWithDescendantsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetOrganisationWithDescendantsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_organisation_with_descendants =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_with_descendants',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_with_descendants,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetOrganisationWithDescendantsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetOrganisationWithDescendantsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_organisation_with_descendants =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_organisation_with_descendants',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_organisation_with_descendants);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetProductsRequest,
 *   !proto.satys.datanalysis.GetProductsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_products = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_products',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetProductsRequest,
  proto.satys.datanalysis.GetProductsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetProductsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetProductsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetProductsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetProductsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_products =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_products',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_products,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetProductsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_products =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_products',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_products);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateProductRequest,
 *   !proto.satys.datanalysis.CreateProductResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_product = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_product',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateProductRequest,
  proto.satys.datanalysis.CreateProductResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateProductResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_product =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_product',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_product,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateProductResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_product =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_product',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_product);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetProcessesRequest,
 *   !proto.satys.datanalysis.GetProcessesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_processes = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_processes',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetProcessesRequest,
  proto.satys.datanalysis.GetProcessesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetProcessesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetProcessesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetProcessesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetProcessesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetProcessesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_processes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_processes',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_processes,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetProcessesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetProcessesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_processes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_processes',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_processes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateProcessFromBpmnRequest,
 *   !proto.satys.datanalysis.CreateProcessFromBpmnResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_process_from_bpmn = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_process_from_bpmn',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateProcessFromBpmnRequest,
  proto.satys.datanalysis.CreateProcessFromBpmnResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateProcessFromBpmnResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateProcessFromBpmnResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateProcessFromBpmnResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_process_from_bpmn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_process_from_bpmn',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_process_from_bpmn,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateProcessFromBpmnRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateProcessFromBpmnResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_process_from_bpmn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_process_from_bpmn',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_process_from_bpmn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetTasksRequest,
 *   !proto.satys.datanalysis.GetTasksResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_tasks = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_tasks',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetTasksRequest,
  proto.satys.datanalysis.GetTasksResponse,
  /**
   * @param {!proto.satys.datanalysis.GetTasksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetTasksResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_tasks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_tasks',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_tasks,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetTasksResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_tasks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_tasks',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_tasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetTasksWithActionsRequest,
 *   !proto.satys.datanalysis.GetTasksWithActionsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_tasks_with_actions = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_tasks_with_actions',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetTasksWithActionsRequest,
  proto.satys.datanalysis.GetTasksWithActionsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetTasksWithActionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetTasksWithActionsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetTasksWithActionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetTasksWithActionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetTasksWithActionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_tasks_with_actions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_tasks_with_actions',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_tasks_with_actions,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetTasksWithActionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetTasksWithActionsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_tasks_with_actions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_tasks_with_actions',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_tasks_with_actions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateActionRequest,
 *   !proto.satys.datanalysis.CreateActionResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_action = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_action',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateActionRequest,
  proto.satys.datanalysis.CreateActionResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateActionResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateActionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateActionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_action =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_action',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_action,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateActionResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_action =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_action',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_action);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.DeleteActionRequest,
 *   !proto.satys.datanalysis.DeleteActionResponse>}
 */
const methodDescriptor_DatanalysisAPI_delete_action = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/delete_action',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.DeleteActionRequest,
  proto.satys.datanalysis.DeleteActionResponse,
  /**
   * @param {!proto.satys.datanalysis.DeleteActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.DeleteActionResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.DeleteActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.DeleteActionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.DeleteActionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.delete_action =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/delete_action',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_delete_action,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.DeleteActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.DeleteActionResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.delete_action =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/delete_action',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_delete_action);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetProbacTreeRequest,
 *   !proto.satys.datanalysis.GetProbacTreeResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_probac_tree = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_probac_tree',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetProbacTreeRequest,
  proto.satys.datanalysis.GetProbacTreeResponse,
  /**
   * @param {!proto.satys.datanalysis.GetProbacTreeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetProbacTreeResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetProbacTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetProbacTreeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetProbacTreeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_probac_tree =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_probac_tree',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_probac_tree,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetProbacTreeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetProbacTreeResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_probac_tree =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_probac_tree',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_probac_tree);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetMeasurementsRequest,
 *   !proto.satys.datanalysis.GetMeasurementsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_measurements = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_measurements',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetMeasurementsRequest,
  proto.satys.datanalysis.GetMeasurementsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetMeasurementsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetMeasurementsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetMeasurementsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetMeasurementsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetMeasurementsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_measurements =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_measurements',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_measurements,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetMeasurementsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetMeasurementsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_measurements =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_measurements',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_measurements);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateMeasurementRequest,
 *   !proto.satys.datanalysis.CreateMeasurementResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_measurement = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_measurement',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateMeasurementRequest,
  proto.satys.datanalysis.CreateMeasurementResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateMeasurementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateMeasurementResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateMeasurementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateMeasurementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateMeasurementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_measurement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_measurement',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_measurement,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateMeasurementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateMeasurementResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_measurement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_measurement',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_measurement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetAnalysesRequest,
 *   !proto.satys.datanalysis.GetAnalysesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_analyses = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_analyses',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetAnalysesRequest,
  proto.satys.datanalysis.GetAnalysesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetAnalysesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetAnalysesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetAnalysesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetAnalysesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetAnalysesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_analyses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_analyses',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_analyses,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetAnalysesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetAnalysesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_analyses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_analyses',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_analyses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateAnalysisRequest,
 *   !proto.satys.datanalysis.CreateAnalysisResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_analysis = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_analysis',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateAnalysisRequest,
  proto.satys.datanalysis.CreateAnalysisResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateAnalysisRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateAnalysisResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateAnalysisResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateAnalysisResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_analysis =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_analysis',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_analysis,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateAnalysisRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateAnalysisResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_analysis =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_analysis',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_analysis);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetQuestionnaireRequest,
 *   !proto.satys.datanalysis.GetQuestionnaireResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_questionnaire = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_questionnaire',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetQuestionnaireRequest,
  proto.satys.datanalysis.GetQuestionnaireResponse,
  /**
   * @param {!proto.satys.datanalysis.GetQuestionnaireRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetQuestionnaireResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetQuestionnaireResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetQuestionnaireResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_questionnaire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questionnaire',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questionnaire,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetQuestionnaireResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_questionnaire =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questionnaire',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questionnaire);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetQuestionnaireUrlsRequest,
 *   !proto.satys.datanalysis.GetQuestionnaireUrlsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_questionnaire_urls = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_questionnaire_urls',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetQuestionnaireUrlsRequest,
  proto.satys.datanalysis.GetQuestionnaireUrlsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetQuestionnaireUrlsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetQuestionnaireUrlsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetQuestionnaireUrlsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_questionnaire_urls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questionnaire_urls',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questionnaire_urls,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetQuestionnaireUrlsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetQuestionnaireUrlsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_questionnaire_urls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questionnaire_urls',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questionnaire_urls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetQuestionnairesRequest,
 *   !proto.satys.datanalysis.GetQuestionnairesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_questionnaires = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_questionnaires',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetQuestionnairesRequest,
  proto.satys.datanalysis.GetQuestionnairesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetQuestionnairesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetQuestionnairesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetQuestionnairesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetQuestionnairesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetQuestionnairesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_questionnaires =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questionnaires',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questionnaires,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetQuestionnairesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetQuestionnairesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_questionnaires =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questionnaires',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questionnaires);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateQuestionnaireRequest,
 *   !proto.satys.datanalysis.CreateQuestionnaireResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_questionnaire = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_questionnaire',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateQuestionnaireRequest,
  proto.satys.datanalysis.CreateQuestionnaireResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateQuestionnaireRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateQuestionnaireResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateQuestionnaireResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateQuestionnaireResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_questionnaire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_questionnaire',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_questionnaire,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateQuestionnaireResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_questionnaire =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_questionnaire',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_questionnaire);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UpdateQuestionnaireRequest,
 *   !proto.satys.datanalysis.UpdateQuestionnaireResponse>}
 */
const methodDescriptor_DatanalysisAPI_update_questionnaire = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/update_questionnaire',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UpdateQuestionnaireRequest,
  proto.satys.datanalysis.UpdateQuestionnaireResponse,
  /**
   * @param {!proto.satys.datanalysis.UpdateQuestionnaireRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UpdateQuestionnaireResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UpdateQuestionnaireResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UpdateQuestionnaireResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.update_questionnaire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_questionnaire',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_questionnaire,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UpdateQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UpdateQuestionnaireResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.update_questionnaire =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_questionnaire',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_questionnaire);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetQuestionRequest,
 *   !proto.satys.datanalysis.GetQuestionResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_question = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_question',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetQuestionRequest,
  proto.satys.datanalysis.GetQuestionResponse,
  /**
   * @param {!proto.satys.datanalysis.GetQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_question =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_question',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_question,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_question =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_question',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_question);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetQuestionsRequest,
 *   !proto.satys.datanalysis.GetQuestionsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_questions = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_questions',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetQuestionsRequest,
  proto.satys.datanalysis.GetQuestionsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetQuestionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetQuestionsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetQuestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetQuestionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetQuestionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_questions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questions',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questions,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetQuestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetQuestionsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_questions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_questions',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_questions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateQuestionRequest,
 *   !proto.satys.datanalysis.CreateQuestionResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_question = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_question',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateQuestionRequest,
  proto.satys.datanalysis.CreateQuestionResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_question =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_question',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_question,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_question =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_question',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_question);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UpdateQuestionRequest,
 *   !proto.satys.datanalysis.UpdateQuestionResponse>}
 */
const methodDescriptor_DatanalysisAPI_update_question = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/update_question',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UpdateQuestionRequest,
  proto.satys.datanalysis.UpdateQuestionResponse,
  /**
   * @param {!proto.satys.datanalysis.UpdateQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UpdateQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UpdateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UpdateQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UpdateQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.update_question =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_question',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_question,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UpdateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UpdateQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.update_question =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/update_question',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_update_question);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest,
 *   !proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse>}
 */
const methodDescriptor_DatanalysisAPI_upload_channel_planning_spreadsheet_unary = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/upload_channel_planning_spreadsheet_unary',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest,
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse,
  /**
   * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.upload_channel_planning_spreadsheet_unary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/upload_channel_planning_spreadsheet_unary',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_upload_channel_planning_spreadsheet_unary,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UploadChannelPlanningSpreadsheetUnaryResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.upload_channel_planning_spreadsheet_unary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/upload_channel_planning_spreadsheet_unary',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_upload_channel_planning_spreadsheet_unary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetBpmnRequest,
 *   !proto.satys.datanalysis.GetBpmnResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_bpmn = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_bpmn',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetBpmnRequest,
  proto.satys.datanalysis.GetBpmnResponse,
  /**
   * @param {!proto.satys.datanalysis.GetBpmnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetBpmnResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetBpmnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetBpmnResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetBpmnResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_bpmn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_bpmn',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_bpmn,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetBpmnRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetBpmnResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_bpmn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_bpmn',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_bpmn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetThirdPartyOauthRequest,
 *   !proto.satys.datanalysis.SetThirdPartyOauthResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_third_party_oauth = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_third_party_oauth',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetThirdPartyOauthRequest,
  proto.satys.datanalysis.SetThirdPartyOauthResponse,
  /**
   * @param {!proto.satys.datanalysis.SetThirdPartyOauthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetThirdPartyOauthResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetThirdPartyOauthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetThirdPartyOauthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_third_party_oauth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_third_party_oauth',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_third_party_oauth,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetThirdPartyOauthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetThirdPartyOauthResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_third_party_oauth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_third_party_oauth',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_third_party_oauth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetThirdPartyOauthRequest,
 *   !proto.satys.datanalysis.GetThirdPartyOauthResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_third_party_oauth = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_third_party_oauth',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetThirdPartyOauthRequest,
  proto.satys.datanalysis.GetThirdPartyOauthResponse,
  /**
   * @param {!proto.satys.datanalysis.GetThirdPartyOauthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetThirdPartyOauthResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetThirdPartyOauthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetThirdPartyOauthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_third_party_oauth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_third_party_oauth',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_third_party_oauth,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetThirdPartyOauthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetThirdPartyOauthResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_third_party_oauth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_third_party_oauth',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_third_party_oauth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetTypeformFormsRequest,
 *   !proto.satys.datanalysis.GetTypeformFormsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_typeform_forms = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_typeform_forms',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetTypeformFormsRequest,
  proto.satys.datanalysis.GetTypeformFormsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetTypeformFormsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetTypeformFormsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetTypeformFormsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetTypeformFormsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetTypeformFormsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_typeform_forms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_typeform_forms',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_typeform_forms,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetTypeformFormsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetTypeformFormsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_typeform_forms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_typeform_forms',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_typeform_forms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetResponsesRangeRequest,
 *   !proto.satys.datanalysis.GetResponsesRangeResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_responses_range = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_responses_range',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetResponsesRangeRequest,
  proto.satys.datanalysis.GetResponsesRangeResponse,
  /**
   * @param {!proto.satys.datanalysis.GetResponsesRangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetResponsesRangeResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetResponsesRangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetResponsesRangeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetResponsesRangeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_responses_range =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_responses_range',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_responses_range,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetResponsesRangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetResponsesRangeResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_responses_range =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_responses_range',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_responses_range);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetResponsesCountRequest,
 *   !proto.satys.datanalysis.GetResponsesCountResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_responses_count = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_responses_count',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetResponsesCountRequest,
  proto.satys.datanalysis.GetResponsesCountResponse,
  /**
   * @param {!proto.satys.datanalysis.GetResponsesCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetResponsesCountResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetResponsesCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetResponsesCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetResponsesCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_responses_count =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_responses_count',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_responses_count,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetResponsesCountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetResponsesCountResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_responses_count =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_responses_count',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_responses_count);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetAnswersCountRequest,
 *   !proto.satys.datanalysis.GetAnswersCountResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_answers_count = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_answers_count',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetAnswersCountRequest,
  proto.satys.datanalysis.GetAnswersCountResponse,
  /**
   * @param {!proto.satys.datanalysis.GetAnswersCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetAnswersCountResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetAnswersCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetAnswersCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetAnswersCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_answers_count =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_answers_count',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_answers_count,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetAnswersCountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetAnswersCountResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_answers_count =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_answers_count',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_answers_count);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetAnswersRequest,
 *   !proto.satys.datanalysis.GetAnswersResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_answers = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_answers',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetAnswersRequest,
  proto.satys.datanalysis.GetAnswersResponse,
  /**
   * @param {!proto.satys.datanalysis.GetAnswersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetAnswersResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetAnswersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetAnswersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetAnswersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_answers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_answers',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_answers,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetAnswersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetAnswersResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_answers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_answers',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_answers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetAnswersWithSentimentRequest,
 *   !proto.satys.datanalysis.GetAnswersWithSentimentResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_answers_with_sentiment = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_answers_with_sentiment',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetAnswersWithSentimentRequest,
  proto.satys.datanalysis.GetAnswersWithSentimentResponse,
  /**
   * @param {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetAnswersWithSentimentResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetAnswersWithSentimentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetAnswersWithSentimentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_answers_with_sentiment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_answers_with_sentiment',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_answers_with_sentiment,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetAnswersWithSentimentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetAnswersWithSentimentResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_answers_with_sentiment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_answers_with_sentiment',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_answers_with_sentiment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetSuborganisationRankingsRequest,
 *   !proto.satys.datanalysis.GetSuborganisationRankingsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_suborganisation_rankings = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_suborganisation_rankings',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetSuborganisationRankingsRequest,
  proto.satys.datanalysis.GetSuborganisationRankingsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetSuborganisationRankingsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetSuborganisationRankingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetSuborganisationRankingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_suborganisation_rankings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_suborganisation_rankings',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_suborganisation_rankings,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetSuborganisationRankingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetSuborganisationRankingsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_suborganisation_rankings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_suborganisation_rankings',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_suborganisation_rankings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetLabelRankingRequest,
 *   !proto.satys.datanalysis.GetLabelRankingResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_label_ranking = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_label_ranking',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.satys.datanalysis.GetLabelRankingRequest,
  proto.satys.datanalysis.GetLabelRankingResponse,
  /**
   * @param {!proto.satys.datanalysis.GetLabelRankingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetLabelRankingResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetLabelRankingRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetLabelRankingResponse>}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_label_ranking =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_label_ranking',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_label_ranking);
};


/**
 * @param {!proto.satys.datanalysis.GetLabelRankingRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetLabelRankingResponse>}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_label_ranking =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_label_ranking',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_label_ranking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetChannelRankingRequest,
 *   !proto.satys.datanalysis.GetChannelRankingResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_channel_ranking = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_channel_ranking',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.satys.datanalysis.GetChannelRankingRequest,
  proto.satys.datanalysis.GetChannelRankingResponse,
  /**
   * @param {!proto.satys.datanalysis.GetChannelRankingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetChannelRankingResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetChannelRankingRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetChannelRankingResponse>}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_channel_ranking =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_channel_ranking',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_channel_ranking);
};


/**
 * @param {!proto.satys.datanalysis.GetChannelRankingRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetChannelRankingResponse>}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_channel_ranking =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_channel_ranking',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_channel_ranking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetRatingStatsRequest,
 *   !proto.satys.datanalysis.GetRatingStatsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_rating_stats = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_rating_stats',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetRatingStatsRequest,
  proto.satys.datanalysis.GetRatingStatsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetRatingStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetRatingStatsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetRatingStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetRatingStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetRatingStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_rating_stats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_rating_stats',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_rating_stats,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetRatingStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetRatingStatsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_rating_stats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_rating_stats',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_rating_stats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetRatingStatsTimeseriesRequest,
 *   !proto.satys.datanalysis.GetRatingStatsTimeseriesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_rating_stats_timeseries = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_rating_stats_timeseries',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetRatingStatsTimeseriesRequest,
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetRatingStatsTimeseriesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetRatingStatsTimeseriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_rating_stats_timeseries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_rating_stats_timeseries',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_rating_stats_timeseries,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetRatingStatsTimeseriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetRatingStatsTimeseriesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_rating_stats_timeseries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_rating_stats_timeseries',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_rating_stats_timeseries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest,
 *   !proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_parent_rating_stats_timeseries = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_parent_rating_stats_timeseries',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest,
  proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse,
  /**
   * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_parent_rating_stats_timeseries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_parent_rating_stats_timeseries',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_parent_rating_stats_timeseries,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetParentRatingStatsTimeseriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetParentRatingStatsTimeseriesResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_parent_rating_stats_timeseries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_parent_rating_stats_timeseries',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_parent_rating_stats_timeseries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetPriorityStatsRequest,
 *   !proto.satys.datanalysis.GetPriorityStatsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_priority_stats = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_priority_stats',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetPriorityStatsRequest,
  proto.satys.datanalysis.GetPriorityStatsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetPriorityStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetPriorityStatsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetPriorityStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetPriorityStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetPriorityStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_priority_stats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_priority_stats',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_priority_stats,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetPriorityStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetPriorityStatsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_priority_stats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_priority_stats',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_priority_stats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetCohortStatsRequest,
 *   !proto.satys.datanalysis.GetCohortStatsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_cohort_stats = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_cohort_stats',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetCohortStatsRequest,
  proto.satys.datanalysis.GetCohortStatsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetCohortStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetCohortStatsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetCohortStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetCohortStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetCohortStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_cohort_stats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_cohort_stats',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_cohort_stats,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetCohortStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetCohortStatsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_cohort_stats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_cohort_stats',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_cohort_stats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.CreateLabelRequest,
 *   !proto.satys.datanalysis.CreateLabelResponse>}
 */
const methodDescriptor_DatanalysisAPI_create_label = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/create_label',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.CreateLabelRequest,
  proto.satys.datanalysis.CreateLabelResponse,
  /**
   * @param {!proto.satys.datanalysis.CreateLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.CreateLabelResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.CreateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.CreateLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.CreateLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.create_label =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_label',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_label,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.CreateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.CreateLabelResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.create_label =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/create_label',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_create_label);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.AssignLabelRequest,
 *   !proto.satys.datanalysis.AssignLabelResponse>}
 */
const methodDescriptor_DatanalysisAPI_assign_label = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/assign_label',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.AssignLabelRequest,
  proto.satys.datanalysis.AssignLabelResponse,
  /**
   * @param {!proto.satys.datanalysis.AssignLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.AssignLabelResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.AssignLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.AssignLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.AssignLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.assign_label =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/assign_label',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_assign_label,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.AssignLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.AssignLabelResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.assign_label =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/assign_label',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_assign_label);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.UnassignLabelRequest,
 *   !proto.satys.datanalysis.UnassignLabelResponse>}
 */
const methodDescriptor_DatanalysisAPI_unassign_label = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/unassign_label',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.UnassignLabelRequest,
  proto.satys.datanalysis.UnassignLabelResponse,
  /**
   * @param {!proto.satys.datanalysis.UnassignLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.UnassignLabelResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.UnassignLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.UnassignLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.UnassignLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.unassign_label =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/unassign_label',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_unassign_label,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.UnassignLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.UnassignLabelResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.unassign_label =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/unassign_label',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_unassign_label);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetLabelsRequest,
 *   !proto.satys.datanalysis.GetLabelsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_labels = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_labels',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetLabelsRequest,
  proto.satys.datanalysis.GetLabelsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetLabelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetLabelsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetLabelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetLabelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_labels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_labels',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_labels,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetLabelsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_labels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_labels',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_labels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.SetMyPerformanceIndicatorRequest,
 *   !proto.satys.datanalysis.SetMyPerformanceIndicatorResponse>}
 */
const methodDescriptor_DatanalysisAPI_set_my_performance_indicator = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/set_my_performance_indicator',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.SetMyPerformanceIndicatorRequest,
  proto.satys.datanalysis.SetMyPerformanceIndicatorResponse,
  /**
   * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.SetMyPerformanceIndicatorResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.SetMyPerformanceIndicatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.set_my_performance_indicator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_my_performance_indicator',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_my_performance_indicator,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.SetMyPerformanceIndicatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.SetMyPerformanceIndicatorResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.set_my_performance_indicator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/set_my_performance_indicator',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_set_my_performance_indicator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest,
 *   !proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_my_performance_indicators = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_my_performance_indicators',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest,
  proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_my_performance_indicators =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_my_performance_indicators',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_my_performance_indicators,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetMyPerformanceIndicatorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetMyPerformanceIndicatorsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_my_performance_indicators =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_my_performance_indicators',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_my_performance_indicators);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetPercentileRankRequest,
 *   !proto.satys.datanalysis.GetPercentileRankResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_percentile_rank = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_percentile_rank',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetPercentileRankRequest,
  proto.satys.datanalysis.GetPercentileRankResponse,
  /**
   * @param {!proto.satys.datanalysis.GetPercentileRankRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetPercentileRankResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetPercentileRankRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetPercentileRankResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetPercentileRankResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_percentile_rank =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_percentile_rank',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_percentile_rank,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetPercentileRankRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetPercentileRankResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_percentile_rank =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_percentile_rank',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_percentile_rank);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetHourlyPatternRequest,
 *   !proto.satys.datanalysis.GetHourlyPatternResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_hourly_pattern = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_hourly_pattern',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetHourlyPatternRequest,
  proto.satys.datanalysis.GetHourlyPatternResponse,
  /**
   * @param {!proto.satys.datanalysis.GetHourlyPatternRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetHourlyPatternResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetHourlyPatternResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetHourlyPatternResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_hourly_pattern =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_hourly_pattern',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_hourly_pattern,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetHourlyPatternRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetHourlyPatternResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_hourly_pattern =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_hourly_pattern',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_hourly_pattern);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetActiveChannelsRequest,
 *   !proto.satys.datanalysis.GetActiveChannelsResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_active_channels = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_active_channels',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetActiveChannelsRequest,
  proto.satys.datanalysis.GetActiveChannelsResponse,
  /**
   * @param {!proto.satys.datanalysis.GetActiveChannelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetActiveChannelsResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetActiveChannelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetActiveChannelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetActiveChannelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_active_channels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_active_channels',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_active_channels,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetActiveChannelsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetActiveChannelsResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_active_channels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_active_channels',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_active_channels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest,
 *   !proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse>}
 */
const methodDescriptor_DatanalysisAPI_get_multiple_organisation_question_comparison = new grpc.web.MethodDescriptor(
  '/satys.datanalysis.DatanalysisAPI/get_multiple_organisation_question_comparison',
  grpc.web.MethodType.UNARY,
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest,
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse,
  /**
   * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse.deserializeBinary
);


/**
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.satys.datanalysis.DatanalysisAPIClient.prototype.get_multiple_organisation_question_comparison =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_multiple_organisation_question_comparison',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_multiple_organisation_question_comparison,
      callback);
};


/**
 * @param {!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.satys.datanalysis.GetMultipleOrganisationQuestionComparisonResponse>}
 *     Promise that resolves to the response
 */
proto.satys.datanalysis.DatanalysisAPIPromiseClient.prototype.get_multiple_organisation_question_comparison =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/satys.datanalysis.DatanalysisAPI/get_multiple_organisation_question_comparison',
      request,
      metadata || {},
      methodDescriptor_DatanalysisAPI_get_multiple_organisation_question_comparison);
};


module.exports = proto.satys.datanalysis;

