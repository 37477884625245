import { Label } from "@satys/contracts/satys/domain/domain_pb"
import {
    GetAnalysesRequest,
    GetAnalysesResponse,
    GetAnswersCountRequest,
    GetAnswersCountResponse,
    GetAnswersRequest,
    GetAnswersResponse,
    GetAnswersWithSentimentRequest,
    GetAnswersWithSentimentResponse,
    GetLabelsRequest,
    GetLabelsResponse,
    GetMeasurementsRequest,
    GetMeasurementsResponse,
    GetOrganisationParentsRequest,
    GetOrganisationParentsResponse,
    GetParentRatingStatsTimeseriesRequest,
    GetParentRatingStatsTimeseriesResponse,
    GetPriorityStatsRequest,
    GetPriorityStatsResponse,
    GetQuestionnaireRequest,
    GetQuestionnaireResponse,
    GetQuestionnairesRequest,
    GetQuestionnairesResponse,
    GetQuestionnaireUrlsRequest,
    GetQuestionnaireUrlsResponse,
    GetQuestionsRequest,
    GetQuestionsResponse,
    GetRatingStatsRequest,
    GetRatingStatsResponse,
    GetRatingStatsTimeseriesRequest,
    GetRatingStatsTimeseriesResponse,
    GetResponsesCountRequest,
    GetResponsesCountResponse,
    GetResponsesRangeResponse,
    GetResponsesRangeRequest,
    GetSuborganisationRankingsRequest,
    GetSuborganisationRankingsResponse,
    GetLabelRankingRequest,
    GetLabelRankingResponse,
    GetChannelRankingRequest,
    GetChannelRankingResponse,
    GetUserRolesRequest,
    GetUserRolesResponse,
    GetPercentileRankRequest,
    GetPercentileRankResponse,
    GetHourlyPatternRequest,
    GetHourlyPatternResponse,
    GetActiveChannelsRequest,
    GetActiveChannelsResponse,
    GetRoleTasksResponse,
    GetRoleTasksRequest,
    GetMyPerformanceIndicatorsRequest,
    GetMyPerformanceIndicatorsResponse,
    GetMyDashboardsRequest,
    GetMyDashboardsResponse,
    GetNotificationSettingsResponse,
    GetNotificationSettingsRequest,
    UpdateNotificationSettingsResponse,
    UpdateNotificationSettingsRequest,
    GetMultipleOrganisationQuestionComparisonResponse,
    GetMultipleOrganisationQuestionComparisonRequest,
    GetCohortStatsRequest,
    GetCohortStatsResponse,
    GetOrganisationWithDescendantsRequest,
    GetOrganisationWithDescendantsResponse,
    AssignLabelRequest,
    AssignLabelResponse,
    UnassignLabelResponse,
    UnassignLabelRequest,
} from "@satys/contracts/satys/datanalysis/datanalysis_pb"
import Status from "/src/utils/status"
import type { RequestState } from "/src/actions"

export interface DateRange {
    start: Date
    end: Date
}

class Dashboard {
    daterange: DateRange = {
        start: undefined,
        end: undefined,
    }
    filter_labels: Label[] = []
    dashboards: GetMyDashboardsResponse
    dashboards_status: Status = Status.IDLE
    responses_count: Record<string, GetResponsesCountResponse> = {}
    responses_count_requests: Record<string, RequestState<GetResponsesCountRequest, GetResponsesCountResponse>> = {}
    responses_range: Record<string, GetResponsesRangeResponse> = {}
    responses_range_requests: Record<string, RequestState<GetResponsesRangeRequest, GetResponsesRangeResponse>> = {}
    rating_stats: Record<string, GetRatingStatsResponse> = {}
    rating_stats_requests: Record<string, RequestState<GetRatingStatsRequest, GetRatingStatsResponse>> = {}
    rating_stats_timeseries: Record<string, GetRatingStatsTimeseriesResponse> = {}
    rating_stats_timeseries_requests: Record<string, RequestState<GetRatingStatsTimeseriesRequest, GetRatingStatsTimeseriesResponse>> = {}
    parent_rating_stats_timeseries: Record<string, GetParentRatingStatsTimeseriesResponse> = {}
    parent_rating_stats_timeseries_requests: Record<string, RequestState<GetParentRatingStatsTimeseriesRequest, GetParentRatingStatsTimeseriesResponse>> = {}
    priority_stats: Record<string, GetPriorityStatsResponse> = {}
    priority_stats_requests: Record<string, RequestState<GetPriorityStatsRequest, GetPriorityStatsResponse>> = {}
    answers_count: Record<string, GetAnswersCountResponse> = {}
    answers_count_requests: Record<string, RequestState<GetAnswersCountRequest, GetAnswersCountResponse>> = {}
    answers: Record<string, GetAnswersResponse> = {}
    answers_requests: Record<string, RequestState<GetAnswersRequest, GetAnswersResponse>> = {}
    user_roles: Record<string, GetUserRolesResponse> = {}
    user_roles_requests: Record<string, RequestState<GetUserRolesRequest, GetUserRolesResponse>> = {}
    role_tasks: Record<string, GetRoleTasksResponse> = {}
    role_tasks_requests: Record<string, RequestState<GetRoleTasksRequest, GetRoleTasksResponse>> = {}
    answers_with_sentiment: Record<string, GetAnswersWithSentimentResponse> = {}
    answers_with_sentiment_requests: Record<string, RequestState<GetAnswersWithSentimentRequest, GetAnswersWithSentimentResponse>> = {}
    questionnaire: Record<string, GetQuestionnaireResponse> = {}
    questionnaire_requests: Record<string, RequestState<GetQuestionnaireRequest, GetQuestionnaireResponse>> = {}
    questionnaires: Record<string, GetQuestionnairesResponse> = {}
    questionnaires_requests: Record<string, RequestState<GetQuestionnairesRequest, GetQuestionnairesResponse>> = {}
    questionnaire_urls: Record<string, GetQuestionnaireUrlsResponse> = {}
    questionnaire_urls_requests: Record<string, RequestState<GetQuestionnaireUrlsRequest, GetQuestionnaireUrlsResponse>> = {}
    organisation_parents: Record<string, GetOrganisationParentsResponse> = {}
    organisation_parents_requests: Record<string, RequestState<GetOrganisationParentsRequest, GetOrganisationParentsResponse>> = {}
    suborganisation_rankings: Record<string, GetSuborganisationRankingsResponse> = {}
    suborganisation_rankings_requests: Record<string, RequestState<GetSuborganisationRankingsRequest, GetSuborganisationRankingsResponse>> = {}
    label_ranking: Record<string, GetLabelRankingResponse[]> = {}
    label_ranking_requests: Record<string, RequestState<GetLabelRankingRequest, GetLabelRankingResponse>> = {}
    channel_ranking: Record<string, GetChannelRankingResponse[]> = {}
    channel_ranking_requests: Record<string, RequestState<GetChannelRankingRequest, GetChannelRankingResponse>> = {}
    measurements: Record<string, GetMeasurementsResponse> = {}
    measurements_requests: Record<string, RequestState<GetMeasurementsRequest, GetMeasurementsResponse>> = {}
    questions: Record<string, GetQuestionsResponse> = {}
    questions_requests: Record<string, RequestState<GetQuestionsRequest, GetQuestionsResponse>> = {}
    analyses: Record<string, GetAnalysesResponse> = {}
    analyses_requests: Record<string, RequestState<GetAnalysesRequest, GetAnalysesResponse>> = {}
    labels: Record<string, GetLabelsResponse> = {}
    labels_requests: Record<string, RequestState<GetLabelsRequest, GetLabelsResponse>> = {}
    percentile_rank: Record<string, GetPercentileRankResponse> = {}
    percentile_rank_requests: Record<string, RequestState<GetPercentileRankRequest, GetPercentileRankResponse>> = {}
    hourly_pattern: Record<string, GetHourlyPatternResponse> = {}
    hourly_pattern_requests: Record<string, RequestState<GetHourlyPatternRequest, GetHourlyPatternResponse>> = {}
    active_channels: Record<string, GetActiveChannelsResponse> = {}
    active_channels_requests: Record<string, RequestState<GetActiveChannelsRequest, GetActiveChannelsResponse>> = {}
    my_performance_indicators: Record<string, GetMyPerformanceIndicatorsResponse> = {}
    my_performance_indicators_requests: Record<string, RequestState<GetMyPerformanceIndicatorsRequest, GetMyPerformanceIndicatorsResponse>> = {}
    my_dashboards: Record<string, GetMyDashboardsResponse> = {}
    my_dashboards_requests: Record<string, RequestState<GetMyDashboardsRequest, GetMyDashboardsResponse>> = {}
    notification_settings: Record<string, GetNotificationSettingsResponse> = {}
    notification_settings_requests: Record<string, RequestState<GetNotificationSettingsRequest, GetNotificationSettingsResponse>> = {}
    update_notification_settings: Record<string, UpdateNotificationSettingsResponse> = {}
    update_notification_settings_requests: Record<string, RequestState<UpdateNotificationSettingsRequest, UpdateNotificationSettingsResponse>> = {}
    multiple_organisation_question_comparison: Record<string, GetMultipleOrganisationQuestionComparisonResponse> = {}
    multiple_organisation_question_comparison_requests: Record<string, RequestState<GetMultipleOrganisationQuestionComparisonRequest, GetMultipleOrganisationQuestionComparisonResponse>> = {}
    cohort_stats: Record<string, GetCohortStatsResponse> = {}
    cohort_stats_requests: Record<string, RequestState<GetCohortStatsRequest, GetCohortStatsResponse>> = {}
    organisation_with_descendants: Record<string, GetOrganisationWithDescendantsResponse> = {}
    organisation_with_descendants_requests: Record<string, RequestState<GetOrganisationWithDescendantsRequest, GetOrganisationWithDescendantsResponse>> = {}
    assign_label: Record<string, AssignLabelResponse> = {}
    assign_label_requests: Record<string, RequestState<AssignLabelRequest, AssignLabelResponse>> = {}
    unassign_label: Record<string, UnassignLabelResponse> = {}
    unassign_label_requests: Record<string, RequestState<UnassignLabelRequest, UnassignLabelResponse>> = {}
    is_demo = false
}

export default Dashboard
