import * as SentryBrowser from "@sentry/browser"

export default class Sentry {
    private options: SentryBrowser.BrowserOptions = {}
    private initialized = false

    constructor(options: SentryBrowser.BrowserOptions) {
        this.options = { ...this.getDefaultOptions, ...options }
    }

    public init() {
        if (!this.initialized) {
            SentryBrowser.init(this.options)
            this.initialized = true
        }
    }

    private getSentryEnvironment() {
        return {
            ["devoffice.satys.app"]: "development",
            ["nextoffice.satys.app"]: "staging",
            ["backoffice.satys.app"]: "production",
        }[window.location.hostname]
    }

    private get getDefaultOptions() {
        return {
            integrations: [
                new SentryBrowser.BrowserTracing({
                    tracingOrigins: ["backoffice.satys.app"],
                }),
                new SentryBrowser.Replay(),
            ],
            tracesSampleRate: this.getSentryEnvironment() === "production" ? 0.1 : 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0, // If we're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            environment: this.getSentryEnvironment() || "development",
        }
    }
}
