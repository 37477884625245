m = require "mithril"
Table = require "/src/components/table" .default
Card = require "/src/components/card/index.tsx" .default
Spinner = require "/src/components/polythene/MaterialDesignSpinner" .default

{ getRole } = require "/src/auth"
{capitalize} = require "prelude-ls"
{get_namespace, status} = require "/src/utils"
{translate: t, getLocale} = require "/src/i18n"

state = require "/src/state" .default

ns = get_namespace __filename

module.exports =

    oninit: (vnode) ->
        @actions = vnode.attrs.actions state
        @organisation_domain = vnode.attrs.organisation_domain

        @headers =
          * column: 'measurement'
            title: t 'measurement', ns |> capitalize
          * column: 'start_date'
            title: t 'start_date', ns |> capitalize
          * column: 'end_date'
            title: t 'end_date', ns |> capitalize

    oncreate: ->
        @status = status.LOADING
        @get_measurements!

    get_measurements: ->>
        intl = new Intl.DateTimeFormat getLocale().toString(),
            year: 'numeric'
            month: 'short'

        request = @actions.dashboard.aio_get_measurements!
        measurements = (await request.promise)?getMeasurementsList! or []
        @measurements = measurements.map (measurement) ~>
            params =
                name: measurement.getName!
                domain: measurement.getOrganisation!getDomain!
            [start, end] = [measurement.getStart!?toDate!, measurement.getEnd!?toDate!]

            return
                title: measurement.getName!
                start_date: intl.format start
                end_date: if end?getTime! > start?getTime! then intl.format end else '-'
                attrs:
                    onclick: (e) ->
                        e.preventDefault!
                        m.route.set "/#{@organisation_domain}/startblock/manage/measurements/questionnaires", params

        @status = status.SUCCEEDED
        m.redraw!

    view: ->
        m '#measurement.pb5.cf',
            m ".pt5.cf.measure-broad",
                # Back button
                m '.db.pt3',
                    m m.route.Link, {
                        selector: 'a.satys-accent'
                        href: "/#{@organisation_domain}/startblock/dashboarding/view_dashboard"
                    },
                        m 'i.material-icons.v-mid', 'chevron_left'
                        m 'span.v-mid', t 'dashboard', ns
                m 'h1', t 'title', ns
                m 'span', t 'desc', ns
                # Filters
                m ".flex.form.pb3.pt3"
                    # Wow such empty
                # Main layout
                m ".grid",
                    m Card,
                        colspan: 12

                        m ".col-center-12",
                            if @status is status.LOADING
                                m ".h4",
                                    m Spinner
                            else
                                m ".table-view",
                                    m Table,
                                        headers: @headers
                                        data: @measurements
