import m from "mithril"
import Select from "./Select"

export default class MultiSelect<ValueType> extends Select<ValueType> {
    oninit(vnode: this["Vnode"]) {
        vnode.attrs = Object.assign(vnode.attrs, { allowMultiple: true })
    }

    view(vnode: this["Vnode"]) {
        return (
            <Select {...vnode.attrs}>
                { vnode.children }
            </Select>
        )
    }
}
