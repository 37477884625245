import state from "./state"
import { snakeToCamel } from "/src//utils"

class InvalidTranslationFallbackError extends Error { }
class TranslationNotFoundError extends Error { }

export const supportedLanguages = {
    en: "English",
    nl: "Nederlands",
} as const

export type ShortLanguage = "nl" | "en"
export type Language = ShortLanguage | "nl-NL" | "en-GB"

/**
 * Get a full Intl.Locale object for the user settings.
 * When locale isn't found in LocalStorage, it creates one from lang.
 */
export function getLocale() {
    let localeString = localStorage.getItem("locale")
    // Backwards compatibility
    if (!localeString) {
        const lang = localStorage.getItem("lang")?.replace("_", "-")
        localeString = lang || navigator.language || "en"
    }
    return new Intl.Locale(localeString)
}

/**
 * Set a full Intl.Locale object for the user settings.
 * The Intl.Locale.maximize is used to fill defaults.
 */
export function setLocale(locale: Intl.Locale) {
    locale = locale.maximize()
    localStorage.setItem("locale", locale.toString())
    if (locale && !state.lang || state.curr_lang !== locale.language) {
        loadLang(locale)
    }
}

/**
 * Load the language JSON into memory.
 */
export async function loadLang(locale: Language | Intl.Locale) {
    const languages = await import("./assets/languages/*.json")
    if (!locale) { return }

    // For backwards compatibility
    if (!(locale instanceof Intl.Locale)) {
        locale = new Intl.Locale(locale)
    }

    const langWithRegion = `${locale.language}-${locale.region}`

    let language = "en"
    if (locale.baseName in languages) {
        language = locale.baseName
    } else if (locale.region && langWithRegion in languages) {
        language = langWithRegion
    } else if (locale.language in languages) {
        language = locale.language
    }

    state.lang = await languages[language]()
}

// Backward compatibiilty
export const load_lang = loadLang

/**
 * Returns the translation for a given key in a given namespace.
 */
export function translate(key: string, namespace?: string, fallback?: string): string

/**
 * Returns the translation for a given key in a given namespace, with replacements.
 *
 * An object of replacements can be given to replace {key} to value in the translation string.
 */
export function translate(key: string, namespace?: string, replacements?: Record<string, string>): string
export function translate(key: string, namespace = "", fallbackOrReplacements?: string | Record<string, string>): string {

    key = snakeToCamel(key)
    if (!state.lang) {
        return key.replace(/([A-Z])/g, " $1").toLocaleLowerCase(getLocale().toString())
    }
    namespace = namespace ? namespace.split(".").map(snakeToCamel).join(".") : namespace

    try {
        const translation = findTranslation(key, namespace)

        if (translation === undefined && typeof fallbackOrReplacements === "string") {
            return fallbackOrReplacements
        }
        if (translation && typeof fallbackOrReplacements === "object") {
            return replaceReplacements(translation, fallbackOrReplacements)
        }
        if (translation === undefined) {
            throw new TranslationNotFoundError()
        }

        return translation
    } catch (error) {

        if (process.env.NODE_ENV !== "production") {
            logError(error, key, namespace)
        }

        return key.replace(/([A-Z])/g, " $1").toLocaleLowerCase(getLocale().toString())
    }
}

/**
 * Find a translation by it's dot-delimited namespace and key in the translation object.
 */
function findTranslation(key: string, namespace: string): string | undefined {
    try {
        const translations = namespace.split(".").reduce((acc, namespaceKey) => acc[namespaceKey], state.lang || {}) || {}
        if (key.includes(".")) {
            return key.split(".").reduce((acc, key) => acc[key], translations)
        }
        return translations[key] ?? state.lang.miscellaneous[key]
    } catch (_) {
        throw new TranslationNotFoundError()
    }
}

/**
 * Replace certain values in a string.
 *
 * In the string, the keys from replacements are replcaed with their respective values.
 * In the string, the keys must be surrounded by curly braces ({}).
 * E.g. replaceReplacements("Hello {name}", {"name": "World"}) returns "Hello World".
 */
function replaceReplacements(string: string, replacements: Record<string, string>): string {
    return Object.entries(replacements).reduce((acc, [key, value]) => acc.replace(`{${key}}`, value), string)
}

const MISSING_TRANSLATIONS: string[] = []
function logError(error: Error, key?: string, namespace?: string): void {
    if (error instanceof InvalidTranslationFallbackError) {
        console.warn(error)
    } else if (error instanceof TranslationNotFoundError) {
        if (MISSING_TRANSLATIONS.includes(`${namespace}.${key}`)) {
            // Prevent missing translations from being logged multiple times
            return
        }
        console.warn(`Could not find translation for "${key}" in namespace: "${namespace}". Make sure the translation has been added.`)
        MISSING_TRANSLATIONS.push(`${namespace}.${key}`)
    } else {
        console.warn(error)
    }
}
